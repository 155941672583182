import { Directive, TemplateRef, ViewContainerRef, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAddTable]'
})
export class AddTableDirective {

  constructor(private el:ElementRef, private renderer:Renderer2) { }

  @Input() set appAddTable(styleObject: any){
    styleObject.cordy.toString() == null ? this.renderer.removeStyle(this.el.nativeElement, 'top') : this.renderer.setStyle(this.el.nativeElement, 'top', styleObject.cordy.toString())
    styleObject.cordx.toString() == null ? this.renderer.removeStyle(this.el.nativeElement, 'left') : this.renderer.setStyle(this.el.nativeElement, 'left', styleObject.cordx.toString())
    styleObject.width.toString() == null ? this.renderer.removeStyle(this.el.nativeElement, 'width') : this.renderer.setStyle(this.el.nativeElement, 'width', styleObject.width.toString())
    styleObject.height.toString() == null ? this.renderer.removeStyle(this.el.nativeElement, 'height') : this.renderer.setStyle(this.el.nativeElement, 'height', styleObject.height.toString())
  }


}
