import { HeadquartersWS } from './headquarters.ws';

export class BuildingWs {
  id: number;
  name: string;
  headquarters: HeadquartersWS;
  enabled: boolean;

  constructor(id: number, name: string, headquarters?: HeadquartersWS, enabled?: boolean) {
    this.id = id;
    this.name = name;

    if (headquarters) {
      this.headquarters = headquarters;
    }
  }
}
