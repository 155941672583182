<div class="dinning-occupation">
  <div class="map">
    <div class="scroll">
      <div>
        <div>
          <div class="chart">
            <canvas
              baseChart
              width="500"
              height="500"
              [data]="data"
              [labels]="labels"
              [chartType]="chartType"
              [options]="chartOptions"
              [colors]="colors"
              [legend]="chartLegend"
            >
            </canvas>
          </div>
        </div>
      </div>
      <div class="table">
        <!-- <a [attr.href]="urlMenu">
          <button type="button" class="check-availability">
            <i class="vdp-vista-imagen"></i
            >{{ 'assistance_text_check_daily_menu' | messagesPipe }}
          </button>
        </a> -->
        <span
          >{{ occupationTextLastUpdated }}
          {{ updatedTime }}</span
        >
      </div>
    </div>
  </div>
</div>
