<div class="new-reserve">
  <!-- Spinner -->
  <app-spinner></app-spinner>

  <form
    class="row mui-form"
    [formGroup]="reservationResourceForm"
    (ngSubmit)="dataEdit ? checkHourReserveEdit() : checkHourReserve()"
    novalidate
  >
    <div class="select-by form-group select-group col-sm-12">
      <div class="mui-select">
        <label for="selectResource">{{
          'resource_reserve_type' | messagesPipe | uppercase
        }}</label>
        <select
          #selectResource
          id="selectResource"
          name="selectResource"
          class="form-control"
          formControlName="idResourceType"
          (change)="changeResourceType()"
        >
          <option [value]="-1" selected>
            {{ 'resource_reserve_type_select' | messagesPipe }}
          </option>
          <option
            *ngFor="let resource of listResourceType"
            [value]="resource.id"
          >
            {{ resource.name }}
          </option>
        </select>
      </div>
    </div>

    <div
      class="form-group col-sm-12 mui-textfield"
      *ngIf="inputLicenseActive()"
    >
      <label for="license">{{
        'resource_reserve_license' | messagesPipe | uppercase
      }}</label>
      <input
        type="text"
        placeholder="{{
          'profile_vehicleEdit_license_placeholder' | messagesPipe
        }}"
        class="form-control"
        formControlName="license"
      />
      <span
        class="required"
        [hidden]="reservationResourceForm.controls.license.valid"
        >{{ 'error_license_required' | messagesPipe }}</span
      >
    </div>

    <!-- DatePicker -->
    <div class="form-group select-group col-sm-12 mui-textfield">
      <label for="fecha">{{ 'resource_reserve_date' | messagesPipe }}</label>
      <datepicker
        class="custom-datepicker"
        #datePickerElement
        id="fecha"
        [options]="datepickerOptions"
        [locale]="locale"
        (dateChanged)="onDateChanged($event)"
      ></datepicker>
    </div>

    <div class="placed-button">
      <button
        type="submit"
        class="check-availability button-by"
        [disabled]="reservationResourceForm.invalid"
      >
        {{
          dataEdit
            ? ('resource_reserve_edit_submit' | messagesPipe)
            : ('resource_reserve_submit' | messagesPipe)
        }}
      </button>
    </div>
  </form>
</div>
<app-modal-reservation-generic
  (submitReserve)="addReservationResource($event)"
  (submitEdit)="editReservationResource($event)"
></app-modal-reservation-generic>
<app-modal-error-mobile></app-modal-error-mobile>
