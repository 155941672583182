import { Injectable } from '@angular/core';
import { OccupationList } from '../../../core/models/occupation.list';

@Injectable({
  providedIn: 'root',
})
export class ModalReservationGenericService {
  constructor() {}

  openModal: (
    date: number,
    openingTime: number,
    endingTime: number,
    timeInterval: number,
    occupationList: OccupationList[],
    fullPage?: boolean,
    idEdit?: number,
    edit?: boolean
  ) => void;
  setLanguage: (language: string, titleKey: string) => void;
  closeModal: () => void;
}
