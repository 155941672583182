<div class="modal fade reservation-dialog-modal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-info clearfix">
            <button type="button" class="vdp-icon-cancelar" (click)="cancelSave()" [disabled]="disableButtons"></button>
            <label class="title" for="roomModalName">{{'modal_edit_reservation' | messagesPipe}}</label>
            <span class="roomName" id="roomModalName">{{getEditModalRoomName()}}</span>
            <div class="form-group select-group col-sm-6">
                <div class="mui-select">
                    <label for="hourFrom">{{'from' | messagesPipe}}</label>
                    <select #selectHourFrom class="form-control" id="hourFrom" name="hourFrom" (change)="onChangeHourFrom($event)">
                        <option *ngFor="let hourFrom of hoursFrom" [value]="hourFrom"  >{{hourFrom}}</option>
                    </select>
                </div>
                <span>:</span>
                <div class="mui-select">
                    <select #selectMinuteFrom class="form-control" id="minuteFrom" name="minuteFrom" (change)="onChangeMinuteFrom($event)">
                        <option *ngFor="let minuteFrom of minutesFrom" [value]="minuteFrom" >{{minuteFrom}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group select-group col-sm-6">
                <div class="mui-select">
                    <label for="hourTo">{{'since' | messagesPipe}}</label>
                    <select #selectHourTo class="form-control" id="hourTo" name="hourTo" (change)="onChangeHourTo($event)">
                        <option *ngFor="let hourTo of hoursTo" [value]="hourTo">{{hourTo}}</option>
                    </select>
                </div>
                <span>:</span>
                <div class="mui-select">
                    <select #selectMinuteTo class="form-control" id="minuteTo" name="minuteTo" (change)="onChangeMinuteTo($event)">
                        <option *ngFor="let minuteTo of minutesTo" [value]="minuteTo">{{minuteTo}}</option>
                    </select>
                </div>
            </div>
            <span class="required" [hidden]="errorDatesInterval.length <= 0">{{errorDatesInterval}}</span>
            <div class="last-textfield form-group col-sm-12 mui-textfield">
                <label for="reason">{{'meeting_reason' | messagesPipe}}</label>
                <textarea placeholder="{{'reservation_reason_placeholder' | messagesPipe}}"  id="reason" name="reason" class="form-control" rows="3" [(ngModel)]="reason"></textarea>
                <span class="required" [hidden]="reason.trim().length > 0">{{'error_required' | messagesPipe}}</span>
            </div>
            <button class="reservation-button" type="button" (click)="saveReservation()" [disabled]="reason.trim().length <= 0 || disableButtons">{{'modal_save_btn' | messagesPipe}}</button>
          </div>
        </div>
    </div>
</div>




<!-- <div>
    <label for="roomModalName">{{'modal_edit_reservation' | messagesPipe}}</label>
    <span id="roomModalName">{{getEditModalRoomName()}}</span>
    <div class="form-group select-group col-sm-6">
        <div class="mui-select">
            <label for="hourFrom">{{'from' | messagesPipe}}</label>
            <select #selectHourFrom class="form-control" id="hourFrom" name="hourFrom" (change)="onChangeHourFrom($event)">
                <option *ngFor="let hourFrom of hoursFrom" [value]="hourFrom"  >{{hourFrom}}</option>
            </select>
        </div>
        <span>:</span>
        <div class="mui-select">
            <select #selectMinuteFrom class="form-control" id="minuteFrom" name="minuteFrom" (change)="onChangeMinuteFrom($event)">
                <option *ngFor="let minuteFrom of minutesFrom" [value]="minuteFrom" >{{minuteFrom}}</option>
            </select>
        </div>
    </div>
    <div class="form-group select-group col-sm-6">
        <div class="mui-select">
            <label for="hourTo">{{'since' | messagesPipe}}</label>
            <select #selectHourTo class="form-control" id="hourTo" name="hourTo" (change)="onChangeHourTo($event)">
                <option *ngFor="let hourTo of hoursTo" [value]="hourTo">{{hourTo}}</option>
            </select>
        </div>
        <span>:</span>
        <div class="mui-select">
            <select #selectMinuteTo class="form-control" id="minuteTo" name="minuteTo" (change)="onChangeMinuteTo($event)">
                <option *ngFor="let minuteTo of minutesTo" [value]="minuteTo">{{minuteTo}}</option>
            </select>
        </div>
    </div>
    <span
     [hidden]="errorDatesInterval.length <= 0">{{errorDatesInterval}}</span>
    <div class="last-textfield form-group col-sm-12 mui-textfield">
        <label for="reason">{{'meeting_reason' | messagesPipe}}</label>
        <textarea placeholder="{{'reservation_reason_placeholder' | messagesPipe}}"  id="reason" name="reason" class="form-control" rows="3" [(ngModel)]="reason"></textarea>
        <span class="required" [hidden]="reason.trim().length > 0">{{'error_required' | messagesPipe}}</span>
    </div>
    <div>
        <button type="button" (click)="saveReservation()" [disabled]="reason.trim().length <= 0 || disableButtons">{{'modal_save_btn' | messagesPipe}}</button>
        <button type="button" (click)="cancelSave()" [disabled]="disableButtons" >X</button>
    </div>
</div> -->
