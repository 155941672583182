import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner-smal',
  templateUrl: './spinner-smal.component.html',
  styleUrls: ['./spinner-smal.component.scss']
})
export class SpinnerSmalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
