import { FormControl } from '@angular/forms';
import { Injectable } from "@angular/core";

interface ProfileHeadquarterValidationResult {
  [key: string]: boolean;
}

export const HEADQUARTERS_DEFAULT_EMPTY_ID_VALUE = '0';

@Injectable()
export class ProfileHeadquarterValidator {
  static validationHeadquarters(
    control: FormControl
  ): ProfileHeadquarterValidationResult {
    const input = control.value;
    if (
      input !== undefined &&
      input != null &&
      input !== HEADQUARTERS_DEFAULT_EMPTY_ID_VALUE
    ) {
      return null;
    } else {
      return { validationHeadquarters: true };
    }
  }
}
