import { MessageLocale } from '../message.locale';
import {
  MESSAGES_PT,
  MESSAGES_PT_LANG,
  MESSAGES_PT_PT_LANG,
} from '../messages/messages_pt';
import {
  MESSAGES_EN,
  MESSAGES_EN_LANG,
  MESSAGES_EN_NAV,
  MESSAGES_EN_US_LANG,
} from './messages/messages_en';
import {
  MESSAGES_ES,
  MESSAGES_es_ES_explore,
  MESSAGES_es_ES_LANG,
} from './messages/messages_es';

export const DICTIONARY = [
  new MessageLocale(MESSAGES_EN_NAV, MESSAGES_EN),
  new MessageLocale(MESSAGES_EN_LANG, MESSAGES_EN),
  new MessageLocale(MESSAGES_EN_US_LANG, MESSAGES_EN),
  new MessageLocale(MESSAGES_es_ES_LANG, MESSAGES_ES),
  new MessageLocale(MESSAGES_es_ES_LANG, MESSAGES_ES),
  new MessageLocale(MESSAGES_es_ES_explore, MESSAGES_ES),
  new MessageLocale(MESSAGES_PT_LANG, MESSAGES_PT),
  new MessageLocale(MESSAGES_PT_PT_LANG, MESSAGES_PT),
];
