import { HeadquarterModuleModel } from './headquarter.module.model';

export class HeadquartersWS {
  id: number;
  name: string;
  allowParking: boolean;
  allowReservation: boolean;
  allowCarwash: boolean;
  dorletParking: boolean;
  disableReservation: boolean;
  allowExpress: boolean;
  allowSpeed: boolean;
  allowLoupe: boolean;
  parkingType: number;
  allowInvitation: boolean;
  allowIncidence: boolean;
  allowRoomSearch: boolean;
  allowDeskFilterAttribute: boolean;
  showParkingSpotInfo: boolean;
  allowHotDesktop: boolean;
  maxDesktopCheckin: number;
  maxHotdeskAdvanceDays: number;
  maxHotdeskPeriodDays: number;
  maxDeskMultipleReservation: number;
  maxCapacityAssistance: number;
  hotdeskPeriod: number;
  allowLdap: boolean;
  allowResourceForHour: boolean;
  allowTree: boolean;
  resourceForHourReservationConfigureTimeInterval: number;
  carwashReservationConfigureTimeInterval: number;
  mandatoryDpto: boolean;
  deskConfigureTimeInterval: number;
  allowDinnerDesktop: boolean;
  allowAssistance: boolean;
  maxDaysAssistance: number;
  occupationPercentageAssistance: number;
  allowCantine: boolean;
  attendanceIntegrationParking: boolean;
  attendanceIntegrationDinnerDesk: boolean;
  modules: HeadquarterModuleModel[];

  constructor(
    id: number,
    name: string,
    allowParking?: boolean,
    allowReservation?: boolean,
    allowCarwash?: boolean,
    dorletParking?: boolean,
    disableReservation?: boolean,
    allowExpress?: boolean,
    allowSpeed?: boolean,
    allowLoupe?: boolean,
    parkingType?: number,
    allowInvitation?: boolean,
    allowIncidence?: boolean,
    allowRoomSearch?: boolean,
    allowDeskFilterAttribute?: boolean,
    showParkingSpotInfo?: boolean,
    allowHotDesktop?: boolean,
    maxDesktopCheckin?: number,
    maxHotdeskAdvanceDays?: number,
    maxHotdeskPeriodDays?: number,
    maxDeskMultipleReservation?: number,
    maxCapacityAssistance?: number,
    hotdeskPeriod?: number,
    allowLdap?: boolean,
    allowResourceForHour?: boolean,
    allowTree?: boolean,
    resourceForHourReservationConfigureTimeInterval?: number,
    carwashReservationConfigureTimeInterval?: number,
    mandatoryDpto?: boolean,
    deskConfigureTimeInterval?: number,
    allowDinnerDesktop?: boolean,
    allowAssistance?: boolean,
    maxDaysAssistance?: number,
    occupationPercentageAssistance?: number,
    allowCantine?: boolean,
    attendanceIntegrationParking?: boolean,
    attendanceIntegrationDinnerDesk?: boolean,
    modules?: HeadquarterModuleModel[]
  ) {
    this.id = id;
    this.name = name;
    this.allowParking = allowParking;
    this.allowReservation = allowReservation;
    this.allowCarwash = allowCarwash;
    this.dorletParking = dorletParking;
    this.disableReservation = disableReservation;
    this.allowExpress = allowExpress;
    this.allowSpeed = allowSpeed;
    this.allowLoupe = allowLoupe;
    this.parkingType = parkingType;
    this.allowInvitation = allowInvitation;
    this.allowIncidence = allowIncidence;
    this.allowRoomSearch = allowRoomSearch;
    this.allowDeskFilterAttribute = allowDeskFilterAttribute;
    this.showParkingSpotInfo = showParkingSpotInfo;
    this.allowHotDesktop = allowHotDesktop;
    this.maxDesktopCheckin = maxDesktopCheckin;
    this.maxHotdeskAdvanceDays = maxHotdeskAdvanceDays;
    this.maxHotdeskPeriodDays = maxHotdeskPeriodDays;
    this.hotdeskPeriod = hotdeskPeriod;
    this.allowLdap = allowLdap;
    this.allowResourceForHour = allowResourceForHour;
    this.allowTree = allowTree;
    this.resourceForHourReservationConfigureTimeInterval =
      resourceForHourReservationConfigureTimeInterval;
    this.carwashReservationConfigureTimeInterval =
      carwashReservationConfigureTimeInterval;
    this.mandatoryDpto = mandatoryDpto;
    this.deskConfigureTimeInterval = deskConfigureTimeInterval;
    this.allowDinnerDesktop = allowDinnerDesktop;
    this.maxDeskMultipleReservation = maxDeskMultipleReservation;
    this.allowAssistance = allowAssistance;
    this.maxCapacityAssistance = maxCapacityAssistance;
    this.maxDaysAssistance = maxDaysAssistance;
    this.occupationPercentageAssistance = occupationPercentageAssistance;
    this.allowCantine = allowCantine;
    this.attendanceIntegrationParking = attendanceIntegrationParking;
    this.attendanceIntegrationDinnerDesk = attendanceIntegrationDinnerDesk;
    this.modules = modules;
  }
}
