import { Building } from './building';

export class Floor {
  id: number;
  name: string;
  building: Building;

  constructor(id: number, name: string, building?: Building) {
    this.id = id;
    this.name = name;

    if (building) {
      this.building = building;
    }
  }
}
