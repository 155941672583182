<div class="main-container">
    <div class="fadeInLayer" *ngIf="!permissions?.disableReservation">
      <div
        *ngIf="parkingData?.Parking_plaza_Tipo"
        class="parking-wrapper {{ parkingData?.Parking_plaza_Tipo }}"
      >
        <div class="parking">
          <div class="parking__img">
            <div *ngIf="isParkingTipoSinAsignar()"
              class="parking-raffle">
              <span class="parking-raffle--info" *ngIf="!parkingData?.Parking_plazaReservada_Codigo && parkingData?.Vehiculos.length > 0">{{ "parking_raffle_info" | messagesPipe }}</span>
              <span class="parking-raffle--info" *ngIf="!parkingData?.Parking_plazaReservada_Codigo && parkingData?.Vehiculos.length <= 0">{{ "parking_raffle_info_not_available" | messagesPipe }}</span>
              <span *ngIf="fechaSorteo" class="parking-raffle--date">{{ "parking_next_raffle" | messagesPipe }} {{ fechaSorteo }}</span>
            </div>
            <div *ngIf="isParkingTipoRotacion() || isParkingTipoSinAsignar()"  class="parking-raffle">
              <span *ngIf="parkingData?.Vehiculos.length > 0 && fechaSorteo" class="parking-raffle--date">{{ "parking_next_raffle" | messagesPipe }} {{ fechaSorteo }}</span>
              <span *ngIf="parkingData?.Vehiculos.length <= 0" class="parking-raffle--notVehicle" > {{'parking_notVehicle' | messagesPipe}}</span>
            </div>
            <div *ngIf=" (isParkingTipoFijaOrTemp() || isParkingTipoRotacion()) && permissions.showParkingSpotInfo && ( isParkingTipoFijaOrTemp() || parkingData?.Plaza_Cedida == 'false')"
              class="parking-code">
              <span>{{ parkingData?.Parking_plaza_Codigo }}</span>
              <span class="parking-floor">
                {{parkingData?.Parking_plaza_planta}}
              </span>
            </div>
            <div *ngIf=" isParkingTipoSinAsignar() && parkingData?.Parking_plazaReservada_Codigo && parkingData?.Plaza_Cedida == 'false'"
              class="parking-code">
              <span>{{ parkingData?.Parking_plazaReservada_Codigo }}</span>
            </div>
            <div class="parking__img--requestPlace" *ngIf="isParkingTipoSinAsignar() && !parkingData?.Parking_plazaReservada_Codigo && permissions.allowLoupe && parkingData.Vehiculos.length > 0">
              <span class="vdp-icon-buscar-sala" (click)="requestPlace()"></span>
            </div>
          </div>
          <div class="parking__text">
            <div *ngIf="isParkingTipoSinAsignar() || isParkingTipoRotacion()" class="parking__text--raffle">
              <div class="parking__text--raffle--ceder">
                <span *ngIf="parkingPlazaFechaHasta || parkingReservaPlazaFechaHasta">{{yieldParkingSpotDateMessage}}</span>
                <button (click)="freeParking()" [hidden]="hideCederHoy">{{ "parking_yield_message" | messagesPipe }}</button>
              </div>
              <div class="parking__text--raffle--item-switch">
                <span> {{ "parking_enter_raffle" | messagesPipe }}</span>
                <input
                  [ngClass]="{ disabled: checkedRaffleStatus }"
                  [checked]="checkedRaffle"
                  (click)="actionRaffle()"
                  type="checkbox"
                  id="switchRaffle"
                />
                <label for="switchRaffle">Toggle</label>
              </div>
              <div class="parking__text--raffle--description">
                <div *ngIf="checkedRaffle">
                  <h4>{{ "parkingOne_checkedRaffle_title" | messagesPipe }}</h4>
                  <span>{{ "parkingOne_checkedRaffle_description" | messagesPipe }}</span>
                </div>
                <div *ngIf="!checkedRaffle">
                  <h4>{{ "parkingOne_no_checkedRaffle_title" | messagesPipe }}</h4>
                  <span>{{ "parkingOne_checkedRaffle_description" | messagesPipe }}</span>
                </div>
              </div>
            </div>
            <div *ngIf="isParkingTipoFijaOrTemp()" class="parking__text--fija">
              <span>{{ yieldParkingSpotDateMessage }}</span>
              <span class="manage-user-message" *ngIf="hideCederHoy && liberarAhora"> {{ "parking_user_message" | messagesPipe }}</span>
              <span class="manage-user-message" *ngIf="hideCederHoy && !liberarAhora"> {{ "parking_temporal_message" | messagesPipe }}</span>
              <button (click)="toggleSidebar()" *ngIf="!isParkingTipoTemp()">
                {{ requestParkingSpotMessage }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="manage-parking-layer"
        (click)="toggleSidebar()"
        [ngClass]="{ active: sidebarStatus }"
        *ngIf="isParkingTipoFijaOrTemp()"
      ></div>
      <div
        class="manage-parking"
        [ngClass]="{
          active: sidebarStatus
        }"
        *ngIf="isParkingTipoFijaOrTemp()"
      >
        <div class="manage-parking__header">
          <i (click)="toggleSidebar()" class="vdp-icon-cancelar"></i>
          <div class="manage-parking__header--box">
            <div class="manage-parking__header--box-green" *ngIf="permissions?.showParkingSpotInfo">
              <span>{{ yieldParkingSpotMessage }}</span>
              <span class="parking-floor">{{ yieldParkingFloor }}</span>
            </div>
            <div class="manage-parking__header--box-white">
              <span>{{ yieldOrRequestMessage }}</span>
            </div>
          </div>
        </div>
        <div class="manage-parking__content">
          <div class="manage-parking__content--items">
            <button
              *ngIf="
                isParkingTipoFijaOrTemp() ||
                parkingData?.parkingTipoTitle != 'Sin plaza'
              "
              (click)="freeParking()"
              [hidden]="hideCederHoy"
            >
              {{ "parking_yieldToday_message" | messagesPipe }}
            </button>
            <span class="manage-user-message" *ngIf="hideCederHoy && liberarAhora"> {{ "parking_user_message" | messagesPipe }}</span>
            <span class="manage-user-message" *ngIf="hideCederHoy && !liberarAhora"> {{ "parking_temporal_message" | messagesPipe }}</span>
            <span class="next-draw">{{ yieldParkingSpotTemporaryMessage }}</span>
            <div class="manage-parking__content--item" >
              <div class="manage-parking__content--item--disabled" *ngIf="editableDatePicker == false"></div>
              <div class="manage-parking__content--item-from">
                <label>{{ "parking_from_date" | messagesPipe }}</label>
                <datepicker
                  #from1
                  class="custom-datepicker parking"
                  id="from"
                  [options]="datepickerOptions"
                  (dateChanged)="onDateChanged($event, 1)"
                  [locale] ='language'
                ></datepicker>
              </div>
              <div class="manage-parking__content--item-to">
                <label>{{ "parking_to_date" | messagesPipe }}</label>
                <datepicker
                  #to1
                  class="custom-datepicker parking"
                  id="to"
                  [options]="datepickerOptions"
                  (dateChanged)="onDateChanged($event, 2)"
                  [locale]= 'language'
                ></datepicker>
              </div>
              <div class="manage-parking__content--item-switch">
                <input
                  [ngClass]="{ disabled: !date1Status }"
                  [checked]="checked1"
                  (click)="actionDate(from1, to1)"
                  type="checkbox"
                  id="switch1"
                />
                <label for="switch1">Toggle</label>
              </div>
            </div>
          </div>
          <div *ngIf="isParkingTipoFijaOrTemp()" class="manage-parking__content--text">
            <p>{{ "parkingOne_yieldTemporary_message" | messagesPipe }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="disableReservation" *ngIf="permissions?.disableReservation">
      <label> {{'parking_disabled_reservation_title' | messagesPipe}}</label>
      <span> {{'parking_disabled_reservation_description' | messagesPipe}}</span>
    </div>
  </div>