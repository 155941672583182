<app-spinner></app-spinner>
<div class="main-container">
  <div class="fadeInLayer">
    <div *ngIf="true">
      <iframe
        #frameUrl
        if="frameUrl"
        width="100%"
        height="100%"
        frameBorder="0"
      >
      </iframe>
    </div>
  </div>
</div>
