<div class="title">
  <h1 [innerHTML]="lopdTextTitle"></h1>
</div>
<div class="subtitle">
  <p [innerHTML]="lopdTextSubtitle"></p>
</div>
<ol>
  <li class="format">{{ lopdTextOurCommitmentTitle }}</li>
  <p [innerHTML]="lopdTextOurCommitmentParraf1"></p>
  <p [innerHTML]="lopdTextOurCommitmentParraf2"></p>
  <p [innerHTML]="lopdTextOurCommitmentParraf3"></p>
  <p [innerHTML]="lopdTextOurCommitmentParraf4"></p>
  <p [innerHTML]="lopdTextOurCommitmentParraf5"></p>
  <p [innerHTML]="lopdTextOurCommitmentParraf6"></p>
  <li class="format">{{ lopdTextApplicableLegislationTitle }}</li>
  <p [innerHTML]="lopdTextApplicableLegislationParraf1"></p>
  <li class="format">{{ lopdTextPersonalDataTitle }}</li>
  <p [innerHTML]="lopdTextPersonalDataParraf1"></p>
  <p [innerHTML]="lopdTextPersonalDataParraf2"></p>
  <p [innerHTML]="lopdTextPersonalDataParraf3"></p>
  <p [innerHTML]="lopdTextPersonalDataParraf4"></p>
  <li class="format">{{ lopdTextNoPersonalDataTitle }}</li>
  <p [innerHTML]="lopdTextNoPersonalDataParraf1"></p>
  <p [innerHTML]="lopdTextNoPersonalDataParraf2"></p>
  <li class="format">{{ lopdTextDisclosurePersonalDataTitle }}</li>
  <p [innerHTML]="lopdTextDisclosurePersonalDataParraf1"></p>
  <ul>
    <li>{{ lopdTextDisclosurePersonalDataParraf2 }}</li>
    <li>{{ lopdTextDisclosurePersonalDataParraf3 }}</li>
  </ul>
  <p [innerHTML]="lopdTextDisclosurePersonalDataParraf4"></p>
  <p [innerHTML]="lopdTextDisclosurePersonalDataParraf5"></p>
  <p [innerHTML]="lopdTextDisclosurePersonalDataParraf6"></p>
  <li class="format">{{ lopdTextSecurityMeasuresTitle }}</li>
  <p [innerHTML]="lopdTextSecurityMeasuresParraf1"></p>
  <p [innerHTML]="lopdTextSecurityMeasuresParraf2"></p>
  <p [innerHTML]="lopdTextSecurityMeasuresParraf3"></p>
  <li class="format">{{ lopdTextDataTransferTitle }}</li>
  <p [innerHTML]="lopdTextDataTransferParraf1"></p>
  <p [innerHTML]="lopdTextDataTransferParraf2"></p>
  <li class="format">{{ lopdTextRightPersonalDataTitle }}</li>
  <p [innerHTML]="lopdTextRightPersonalDataParraf1"></p>
  <p [innerHTML]="lopdTextRightPersonalDataParraf2"></p>
  <ul>
    <li class="format">{{ lopdTextWithdrawConsentTitle }}</li>
  </ul>
  <p [innerHTML]="lopdTextWithdrawConsentParraf1"></p>
  <ul>
    <li class="format">{{ lopdAccessPersonalDataTitle }}</li>
  </ul>
  <p [innerHTML]="lopdAccessPersonalDataParraf1"></p>
  <p [innerHTML]="lopdAccessPersonalDataParraf2"></p>
  <ul>
    <li class="format">{{ lopdTextRightRectifyDataTitle }}</li>
  </ul>
  <p [innerHTML]="lopdTextRightRectifyDataParraf1"></p>
  <ul>
    <li class="format">{{ lopdTextRightToErasureTitle }}</li>
  </ul>
  <p [innerHTML]="lopdTextRightToErasureParraf1"></p>
  <ul>
    <li class="format">{{ lopdTextRightRestrictionTitle }}</li>
  </ul>
  <p [innerHTML]="lopdTextRightRestrictionParraf1"></p>
  <ul>
    <li class="format">{{ lopdTextDataPortabilityRightTitle }}</li>
  </ul>
  <p [innerHTML]="lopdTextDataPortabilityRightParraf1"></p>
  <ul>
    <li class="format">{{ lopdTextRightOfOppositionTitle }}</li>
  </ul>
  <p [innerHTML]="lopdTextRightOfOppositionParraf1"></p>
  <ul>
    <li class="format">{{ lopdTextRightOfComplaintTitle }}</li>
  </ul>
  <p [innerHTML]="lopdTextRightOfComplaintParraf1"></p>
  <li class="format">{{ lopdTextOptOutTitle }}</li>
  <p [innerHTML]="lopdTextOptOutParraf1"></p>
  <p [innerHTML]="lopdTextOptOutParraf2"></p>
  <li class="format">{{ lopdTextContactsTitle }}</li>
  <p [innerHTML]="lopdTextContactsParraf1"></p>
  <li class="format">{{ lopdTextPrivacyPolicyTitle }}</li>
  <p [innerHTML]="lopdTextPrivacyPolicyParraf1"></p>
  <p [innerHTML]="lopdTextPrivacyPolicyParraf2"></p>
  <br />
  <p [innerHTML]="lopdTextFoot"></p>
</ol>
