import { RoomWs } from './room.ws';

export class RoomDetailEmitter {
  room: RoomWs;
  dateFrom: Date;
  dateTo: Date;
  duration: number;

  constructor(room: RoomWs, dateFrom?: Date, dateTo?: Date, duration?: number) {
    this.room = room;

    if (dateFrom) {
      this.dateFrom = dateFrom;
    }

    if (dateTo) {
      this.dateTo = dateTo;
    }

    if (duration) {
      this.duration = duration;
    }
  }
}
