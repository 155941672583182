<div *ngIf="!existAvailableRooms" class="modal fade" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-info">
            <span class="title">{{'no_available_rooms_msg' | messagesPipe}}</span>
            <span class="message">{{'no_available_rooms_equipment_msg' | messagesPipe}}</span>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" (click)="repeatWitouthEquipment()" *ngIf="roomList.equipment.length > 0">{{'rooms_without_equip' | messagesPipe}}</button>
            <button type="button" class="btn btn-default" (click)="addToWaitingList()">{{'waiting_list' | messagesPipe}}</button>
            <span class="vdp-icon-cancelar" (click)="backToBookings()"></span>
          </div>
        </div>
    </div>
</div>
<div *ngIf="availableRooms.length > 0">
  <div class="main-container-tab">
    <div class="fadeInLayer">
      <ul class="tabBuildings">
        <li *ngFor="let roomListRes of availableRooms" (click)="changeBuilding(roomListRes)" [ngClass]="{'selected':roomListRes == roomListResponseSelect}"><span>{{roomListRes.buildingName}}</span></li>
      </ul>
      <div class="listRooms row">
        <div class="col-xs-12 col-sm-6 col-md-4" *ngFor="let room of roomListResponseSelect.rooms">
          <roomCard [room]="room" [reservationBtnDisabled]="reservationBtnDisabled" (roomReserveEmitter)="reserveRoom($event)" (roomDetailEmitter)="showRoomDetail($event)"></roomCard>
        </div>
      </div>
    </div>
    <div >
      <roomDetail [reservationBtnDisabled]="reservationBtnDisabled" [roomDetail]="roomDetail" [buildingName]="roomListResponseSelect.buildingName" [dateFromInMillis]="roomList.dateFromInMillis" [dateToInMillis]="roomList.dateToInMillis" (closeRoomDetailEmitter)="closeRoomDetail()" (roomReserveEmitter)="reserveRoom($event)"></roomDetail>
    </div>
  </div>
</div>

<!-- Lunch time confirmation -->
<div *ngIf="showServerLunchMsg" class="modal fade" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-info">
            <span class="message">{{reservationResponse.message}}</span>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-default" (click)="backToReservationList()">{{'ok' | messagesPipe}}</button>
          </div>
        </div>
    </div>
</div>
