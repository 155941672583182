import { UserLdapWs } from './user.ldap.ws';

export class MenuPermissions {
  public allowParking = false;
  public allowReservation = false;
  public allowCarwash = false;
  public dorletParking = false;
  public disableReservation = false;
  public allowExpress = false;
  public allowSpeed = false;
  public allowLoupe = false;
  public allowInvitation = false;
  public allowRoomSearch = false;
  public allowEditProfile = false;
  public allowEditVehicles = false;
  public allowIncidence = false;
  public showParkingSpotInfo = false;
  public parkingType = 0;
  public allowSecurity = false;
  public allowHotDesktop = false;
  public allowWebCheckin = false;
  public allowHeadquarters = false;
  public allowMultipleHotdeskReservation = false;
  public allowLdap = false;
  public allowResourceForHour = false;
  public allowTree = false;
  public mandatoryDpto = false;
  public allowDinnerDesktop = false;
  public allowAssistance = false;
  public maxCapacityAssistance = 0;
  public maxDaysAssistance = 0;
  occupationPercentageAssistance = 0;
  public allowCantine = false;
  public allowUserParking = false;

  constructor(user: UserLdapWs) {
    if (user && user.headquarters) {
      this.allowParking = user.headquarters.allowParking;
      this.allowReservation = user.headquarters.allowReservation;
      this.allowCarwash = user.headquarters.allowCarwash;
      this.dorletParking = user.headquarters.dorletParking;
      this.disableReservation = user.headquarters.disableReservation
        ? user.headquarters.disableReservation
        : false;
      this.allowExpress = user.headquarters.allowExpress;
      this.allowSpeed = user.headquarters.allowSpeed;
      this.allowLoupe = user.headquarters.allowLoupe;
      this.allowInvitation = user.headquarters.allowInvitation;
      this.allowIncidence = user.headquarters.allowIncidence;
      this.allowRoomSearch = user.headquarters.allowRoomSearch;
      this.parkingType = user.headquarters.parkingType;
      this.showParkingSpotInfo = user.headquarters.showParkingSpotInfo;
      this.allowHotDesktop = user.headquarters.allowHotDesktop;
      this.allowCantine = user.headquarters.allowCantine;
      this.allowLdap = user.headquarters.allowLdap;
      this.allowResourceForHour = user.headquarters.allowResourceForHour;
      this.allowTree = user.headquarters.allowTree;
      this.mandatoryDpto = user.headquarters.mandatoryDpto;
      this.allowDinnerDesktop = user.headquarters.allowDinnerDesktop;
      this.allowAssistance = user.headquarters.allowAssistance;
      this.maxCapacityAssistance = user.headquarters.maxCapacityAssistance;
      this.maxDaysAssistance = user.headquarters.maxDaysAssistance;
      this.occupationPercentageAssistance =
        user.headquarters.occupationPercentageAssistance;
      this.allowEditProfile = this.userHasAutorization(
        user,
        'allowEditProfile'
      );
      this.allowEditVehicles = this.userHasAutorization(
        user,
        'allowEditVehicles'
      );
      this.allowSecurity = this.userHasAutorization(user, 'allowSecurity');
      this.allowWebCheckin = this.userHasAutorization(user, 'allowWebCheckin');
      this.allowHeadquarters = this.userHasAutorization(
        user,
        'allowHeadquarters'
      );
      this.allowMultipleHotdeskReservation = this.userHasAutorization(
        user,
        'allowMultipleHotdeskReservation'
      );
      this.allowUserParking = this.userHasAutorization(
        user,
        'allowUserParking'
      );
    }
  }

  userHasAutorization(user: UserLdapWs, property: string): boolean {
    let propertyValue = false;

    if (user.authorizations != null) {
      user.authorizations.forEach((obj) => {
        if (Object.keys(obj).length !== 0 && obj.tag === property) {
          propertyValue = true;
        }
      });
      return propertyValue;
    }
  }
}
