import { Pipe, PipeTransform } from '@angular/core';
import { Http } from '@angular/http';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';

@Pipe({ name: 'imageExist' })
export class ImageExistPipe implements PipeTransform {
  defaultImage: string = '';

  constructor(private http: Http) {}

  transform(url: string): any {
    return this.http
      .get(url)
      .map((res) => {
        return true;
      })
      .catch((error) => {
        return Observable.of(() => {
          return false;
        });
      });
  }
}
