<!-- Modal structure -->
<div [hidden]="!visible" class="share-modal">
  <div class="modal fade editmodal" role="dialog">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-info clearfix">
          <!-- Close button -->
          <button
            type="button"
            class="vdp-icon-cancelar"
            (click)="closeComponent()"
          ></button>

          <!-- Modal title -->
          <label class="title" for="roomModalName">{{
            'reservation_share' | messagesPipe
          }}</label>

          <!-- departments -->
          <div class="row-sel mui-select">
            <label for="department">{{'profile_department' | messagesPipe}}</label>
            <select id="department" name="department" (change)="onChangeDept($event.target.value, $event)">
              <option [value]="0" [selected]="true">{{'all_dept' | messagesPipe}}</option>
              <option [value]="department.id" *ngFor="let department of departments">{{department.name}}</option>
            </select>
          </div>

          <!-- Search People -->
          <app-search-people
            [serviceType]="searchType"
            (onSelectedUsersChange)="getSelectedPeople($event)"
            [departmentId]="departmentSelected"
            [departmentDesc]="descDepartmentSelected"
          ></app-search-people>

          <!-- My position  -->
          <div class="my-position-info">
            <div>
              <p class="position-detail">
                {{ myCurrentPosition.desk?.area.name }} -
                {{ myCurrentPosition.desk?.code }}
              </p>
              <p class="position-date">
                {{ myCurrentPosition?.dateFrom | date }}
                {{ 'time_str_from' | messagesPipe }}
                {{ myCurrentPosition?.hourFrom }}:00
                {{ 'time_str_to' | messagesPipe }}
                {{ myCurrentPosition?.hourTo }}:59
              </p>
            </div>
            <div>
              <button
                [disabled]="selectedPeople.length == 0"
                class="reservation-button"
                type="button"
                (click)="share()"
              >
                {{ 'reservation_share' | messagesPipe }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
