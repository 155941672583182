import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import { environment } from '../../../environments/environment';
import { STATUS_CONFLICT, STATUS_FORBIDDEN } from '../../core/constants/const';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
  EXPRESS_LONG,
  EXPRESS_SHORT,
  LOGOUT_TIMEOUT,
  RESERV_REFRESH_TIME,
} from '../../core/constants/consts';
import { BuildingWs } from '../../core/models/building.ws';
import { EquipementWs } from '../../core/models/equipement.ws';
import { HeadquartersWS } from '../../core/models/headquarters.ws';
import { ReservationResponseWs } from '../../core/models/reservation.response.ws';
import { ReservationTypesWS } from '../../core/models/reservation.types.ws';
import { ReservationWs } from '../../core/models/reservation.ws';
import { UserLdapWs } from '../../core/models/user.ldap.ws';
import { WaitingListWs } from '../../core/models/waiting.list.ws';
import { MyDatePicker } from '../../shared/components/date_picker/datepicker.component';
import { DateStringPipe } from '../../shared/pipes/date.string.pipe';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { BaseService } from '../../shared/services/base.service';
import { BuildingService } from '../../shared/services/building.service';
import { ConfirmService } from '../../shared/services/confirm.service';
import { EquipementService } from '../../shared/services/equipement.service';
import { FlashMessagesService } from '../../shared/services/flash-messages.service';
import { NavigatorRefreshService } from '../../shared/services/navigator.refresh.service';
import { ReservationService } from '../../shared/services/reservation.service';
import { RoomService } from '../../shared/services/room.service';
import { WaitingService } from '../../shared/services/waiting.service';
import { slideButtons } from '../../shared/utils/reservation.list.animations';
import { Utils } from '../../shared/utils/utils';
import { SideModalService } from '../side-modal/side-modal.service';
import { ReservationRoutes } from './reservation-router';

@Component({
  templateUrl: 'reservation.list.component.html',
  styleUrls: ['reservation-list.scss'],
  animations: [slideButtons()],
})
export class ReservationListComponent implements OnInit, OnDestroy {
  reservations: ReservationWs[];
  waitingList: WaitingListWs[];
  reservationDetail: ReservationWs;
  reservationDetailSpeed: ReservationWs;
  showReservationButtons: boolean;
  interval: any;
  expressLoading: boolean;
  equipment: EquipementWs[];
  messagesPipe = new MessagesPipe();
  showReservationMsg: boolean;
  reservationResponse: ReservationResponseWs;
  reservationTypes: ReservationTypesWS[];
  builidingList: BuildingWs[];
  viewCard: boolean;
  datepickerOptions: any;
  dayfiltering: Date;
  dateFilterText: string;
  btnHQController: boolean;
  invitationModalVisible: boolean;
  currentReservation: ReservationWs;
  permissions: MenuPermissions;
  showSearchByRoomAndPeriodForm = false;
  ie: boolean = false;

  @ViewChild('dateFilterElement', null) dateFilterElement: MyDatePicker;

  user: UserLdapWs;

  constructor(
    private authenticationService: AuthenticationService,
    private reservationService: ReservationService,
    private roomService: RoomService,
    private equipementService: EquipementService,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private confirmService: ConfirmService,
    private waitingService: WaitingService,
    private buildingService: BuildingService,
    private baseService: BaseService,
    private utils: Utils,
    private navigatorRefreshService: NavigatorRefreshService,
    private sideModalService: SideModalService,
    private route: ActivatedRoute
  ) {
    this.reservations = [];
    this.waitingList = [];
    this.showReservationButtons = false;
    this.expressLoading = false;
    this.reservationDetail = new ReservationWs();
    this.reservationDetail.room.reservations = [];
    this.reservationDetail.room.equipment = [];
    this.reservationDetailSpeed = new ReservationWs();
    this.reservationDetailSpeed.room.reservations = [];
    this.equipment = [];
    this.reservationTypes = [];
    this.showReservationMsg = false;
    this.reservationResponse = new ReservationResponseWs();
    this.viewCard = true;

    this.invitationModalVisible = false;
  }

  ngOnInit() {
    var userAgent, ieReg;
    userAgent = window.navigator.userAgent;
    ieReg = /msie|Trident.*rv[ :]*11\./gi;
    this.closeSideModal();
    this.ie = ieReg.test(userAgent);
    this.user = this.authenticationService.getCurrentUser();
    this.permissions =
      this.authenticationService.getPermissionsForCurrentUser();

    if (
      !this.authenticationService.getPermissionsForCurrentUser()
        .allowReservation
    ) {
      this.router.navigate(['/menu/home']);
    }

    if (this.user && this.user.headquarters) {
      this.datepickerOptions = this.utils.getDatepickerOptions(
        this.authenticationService.getMaxReservationDate()
      );
      this.dateFilterText = '';
      this.getReservationList();

      this.navigatorRefreshService.emitMenuRefreshLinkEvent(
        this.messagesPipe.transform('menu_room')
      );
    } else {
      this.router.navigate(['menu/profile']);
    }
  }

  showInvitationModal(reservation: ReservationWs): void {
    this.currentReservation = reservation;
    this.invitationModalVisible = true;
  }

  hideInvitationModal(): void {
    this.invitationModalVisible = false;
  }

  hqButtonShow(): boolean {
    return this.authenticationService.hqButtonShow(
      this.authenticationService.getCurrentUser()
    );
  }
  getReservationList(): void {
    this.reservationService
      .reservationList(this.authenticationService.getToken())
      .subscribe(
        (reservationsWaiting) => {
          this.reservations = reservationsWaiting.reservations;
          this.waitingList = reservationsWaiting.waitingList;
          this.loadAllEquipement();
        },
        (error) => {
          this.reservations = [];
          this.waitingList = [];

          if (error.code === STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.getReservationList();
              },
              () => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.getReservationList();
                  },
                  () => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(
              this.messagesPipe.transform('rooms_waiting_get_error'),
              { cssClass: 'alert-danger', timeout: 3000 }
            );
            this.router.navigate(['/menu/home']);
          }
        }
      );
  }

  loadAllEquipement(): void {
    this.equipementService
      .getAllEquipement(this.authenticationService.getToken())
      .subscribe(
        (equipement) => {
          this.equipment = equipement;
          this.loadReservationTypes();
        },
        (error) => {
          if (error.code === STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.loadAllEquipement();
              },
              () => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.loadAllEquipement();
                  },
                  () => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('error_getAllEquipement')
            );
            this.router.navigate(['/menu/home']);
          }
        }
      );
  }

  loadReservationTypes(): void {
    this.reservationService
      .getPeriodicityTypes(this.authenticationService.getToken())
      .subscribe(
        (types) => {
          this.reservationTypes = types;
          this.loadBuildings();
        },
        (error) => {
          if (error.code === STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.loadReservationTypes();
              },
              () => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.loadReservationTypes();
                  },
                  () => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('periodicity_types_load_error')
            );
            this.router.navigate(['/menu/home']);
          }
        }
      );
  }

  loadBuildings(): void {
    const currentUser: UserLdapWs = this.authenticationService.getCurrentUser();

    this.buildingService
      .getBuildingListByHeadquarters(
        this.authenticationService.getToken(),
        currentUser.headquarters.id
      )
      .subscribe(
        (buildings) => {
          this.builidingList = buildings;
          this.enableInterval();
        },
        (error) => {
          if (error.code === STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.loadBuildings();
              },
              () => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.loadBuildings();
                  },
                  () => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('building_list_load_errror')
            );
            this.router.navigate(['/menu/home']);
          }
        }
      );
  }

  ngOnDestroy() {
    this.cancelReservationsRefresh();
  }

  enableInterval() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setInterval(() => {
      this.loadReservations(false);
    }, RESERV_REFRESH_TIME);
  }

  loadReservations(enableIntervalOnFinish: boolean): void {
    if (this.authenticationService.existLogedUser()) {
      this.reservationService
        .reservationList(
          this.authenticationService.getToken(),
          this.dayfiltering
        )
        .subscribe(
          (reservationsWaiting) => {
            this.reservations = reservationsWaiting.reservations;
            this.waitingList = reservationsWaiting.waitingList;

            if (enableIntervalOnFinish) {
              this.enableInterval();
            }
          },
          (error) => {
            this.reservations = [];
            this.waitingList = [];

            if (error.code === STATUS_FORBIDDEN) {
              this.authenticationService.refreshToken().subscribe(
                (response) => {
                  this.loadReservations(enableIntervalOnFinish);
                },
                () => {
                  this.authenticationService.validateSessionId().subscribe(
                    (response) => {
                      this.loadReservations(enableIntervalOnFinish);
                    },
                    () => {
                      this.flashMessagesService.grayOut(false);
                      this.flashMessagesService.show(
                        this.messagesPipe.transform('error_forbidden'),
                        { cssClass: 'alert-danger', timeout: 3000 }
                      );

                      setTimeout(() => {
                        this.authenticationService.logout();
                      }, LOGOUT_TIMEOUT);
                    }
                  );
                }
              );
            } else {
              this.baseService.showErrorDialog(
                this.flashMessagesService,
                error,
                this.messagesPipe.transform('rooms_waiting_get_error')
              );
              this.router.navigate(['/menu/home']);
            }
          }
        );
    } else {
      this.cancelReservationsRefresh();
    }
  }

  cancelReservationsRefresh(): void {
    clearInterval(this.interval);
    this.interval = null;
  }

  showDetail($event): void {
    this.cancelReservationsRefresh();
    this.getRoomDetail($event);
  }

  changeView(): void {
    this.viewCard = !this.viewCard;
  }

  getRoomDetail(reservation: ReservationWs) {
    this.roomService
      .getRoomDetailWithHeadquarters(
        reservation,
        this.authenticationService.getToken()
      )
      .subscribe(
        (room) => {
          const headquartersAux = new HeadquartersWS(
            room.floor.building.headquarters.id,
            room.floor.building.headquarters.name
          );
          reservation.room = room;
          reservation.room.floor.building.headquarters = headquartersAux;

          if (typeof reservation.dateFrom === 'number') {
            reservation.dateFrom = new Date(reservation.dateFrom as number);
          }

          if (typeof reservation.dateTo === 'number') {
            reservation.dateTo = new Date(reservation.dateTo as number);
          }

          if (typeof reservation.room.serverDate === 'number') {
            reservation.room.serverDate = new Date(
              reservation.room.serverDate as number
            );
          }

          if (!reservation.room.reservations) {
            reservation.room.reservations = [];
          }

          if (!reservation.room.equipment) {
            reservation.room.equipment = [];
          }

          if (reservation.room.type.id === 1) {
            this.reservationDetail = reservation;
            this.reservationDetailSpeed = new ReservationWs();
          } else {
            this.reservationDetailSpeed = reservation;
            this.reservationDetail = new ReservationWs();
          }
        },
        (error) => {
          if (error.code === STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.getRoomDetail(reservation);
              },
              () => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.getRoomDetail(reservation);
                  },
                  () => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('error_getRoomDetail')
            );
            this.router.navigate(['/menu/home']);
          }
        }
      );
  }

  closeDetail(): void {
    if (this.reservationDetailSpeed.id) {
      this.reservationDetailSpeed = new ReservationWs();
    } else if (this.reservationDetail.id) {
      this.reservationDetail = new ReservationWs();
    }

    this.enableInterval();
  }

  editReservation($event): void {
    this.reservationService
      .editReservationService($event, this.authenticationService.getToken())
      .subscribe(
        (reservation) => {
          // Obtain element from list, change dates and insert in his position
          const reservationEdited = this.reservationService.getReservationById(
            this.reservations,
            reservation.id
          );
          const indexRes = this.reservations.indexOf(reservationEdited);

          reservationEdited.dateFrom = reservation.dateFrom;
          reservationEdited.dateTo = reservation.dateTo;
          reservationEdited.reason = reservation.reason;

          this.reservations.splice(indexRes, 1);
          this.reservationService.spliceReservation(
            this.reservations,
            reservationEdited
          );

          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform('edit_reservation_msg'),
            { cssClass: 'alert-success', timeout: 3000 }
          );

          this.closeDetail();
        },
        (error) => {
          if (error.code === STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.editReservation($event);
              },
              () => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.editReservation($event);
                  },
                  () => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('edit_reservation_error')
            );
            this.closeDetail();
          }
        }
      );
  }

  cancelReservation($event): void {
    this.cancelReservationsRefresh();

    this.confirmService
      .activate(
        this.messagesPipe.transform('reservation_delete_msg'),
        this.messagesPipe.transform('reservation_delete_msg_title')
      )
      .then((result) => {
        if (result) {
          this.cancelReservationService($event);
        } else {
          this.enableInterval();
        }
      })
      .catch((error) => {
        this.flashMessagesService.grayOut(false);
        this.flashMessagesService.show(
          this.messagesPipe.transform('confirm_dialog_err'),
          { cssClass: 'alert-danger', timeout: 3000 }
        );
        this.enableInterval();
      });
  }

  cancelReservationService(reservation: ReservationWs) {
    this.reservationService
      .cancelReservation(reservation, this.authenticationService.getToken())
      .subscribe(
        (response) => {
          this.reservations = this.reservationService.deleteReservationFromList(
            this.reservations,
            reservation
          );
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform('reservation_cancelled'),
            { cssClass: 'alert-success', timeout: 3000 }
          );

          this.closeDetail();
        },
        (error) => {
          if (error.code === STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.cancelReservationService(reservation);
              },
              () => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.cancelReservationService(reservation);
                  },
                  () => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('reservation_cancelled_error')
            );

            if (error.code === STATUS_CONFLICT) {
              this.closeDetail();
            } else {
              this.router.navigate(['/menu/home']);
            }
          }
        }
      );
  }

  cancelWaiting($event): void {
    this.cancelReservationsRefresh();

    this.confirmService
      .activate(
        this.messagesPipe.transform('cancel_waiting_msg'),
        this.messagesPipe.transform('cancel_waiting_msg_title')
      )
      .then((result) => {
        if (result) {
          this.cancelWaitingService($event);
        } else {
          this.enableInterval();
        }
      })
      .catch((error) => {
        this.flashMessagesService.grayOut(false);
        this.flashMessagesService.show(
          this.messagesPipe.transform('cancel_waiting_dialog_error'),
          { cssClass: 'alert-danger', timeout: 3000 }
        );
        this.enableInterval();
      });
  }

  cancelWaitingService(waiting: WaitingListWs) {
    this.waitingService
      .cancel(this.authenticationService.getToken(), waiting)
      .subscribe(
        (response) => {
          this.waitingList = this.waitingService.deleteWaitingFromList(
            this.waitingList,
            waiting
          );
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform('cancel_waiting_successfully'),
            { cssClass: 'alert-success', timeout: 3000 }
          );

          this.enableInterval();
        },
        (error) => {
          if (error.code === STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.cancelWaitingService(waiting);
              },
              () => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.cancelWaitingService(waiting);
                  },
                  () => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('cancel_waiting_error')
            );

            if (error.code === STATUS_CONFLICT) {
              this.enableInterval();
            } else {
              this.router.navigate(['/menu/home']);
            }
          }
        }
      );
  }

  newReservation() {
    this.cancelReservationsRefresh();
    this.openSideModal(
      ReservationRoutes.reservationNewDetail.routerName,
      { equimenent: this.equipment, reservationTypes: this.reservationTypes },
      this.messagesPipe.transform(ReservationRoutes.reservationNewDetail.title)
    );
  }

  newReservationByRoom() {
    this.cancelReservationsRefresh();
    this.openSideModal(
      ReservationRoutes.reservationNewDetailByRoom.routerName,
      { buildings: this.builidingList },
      this.messagesPipe.transform(
        ReservationRoutes.reservationNewDetailByRoom.title
      )
    );
  }

  openSearchByRoomAndPeriodForm() {
    this.cancelReservationsRefresh();
    this.openSideModal(
      ReservationRoutes.reservationSearchByRoomAndPeriod.routerName,
      { buildings: this.builidingList },
      this.messagesPipe.transform(
        ReservationRoutes.reservationSearchByRoomAndPeriod.title
      )
    );
  }

  newReservationbyHQ() {
    this.cancelReservationsRefresh();
    this.openSideModal(
      ReservationRoutes.reservationNewFormByHQ.routerName,
      { equimenent: this.equipment, reservationTypes: this.reservationTypes },
      this.messagesPipe.transform(
        ReservationRoutes.reservationNewFormByHQ.title
      )
    );
  }
  speedReservation() {
    this.cancelReservationsRefresh();
    this.openSideModal(
      ReservationRoutes.reservationNewSpeed.routerName,
      { reservationTypes: this.reservationTypes },
      this.messagesPipe.transform(ReservationRoutes.reservationNewSpeed.title)
    );
  }

  expressReservationShort() {
    this.cancelReservationsRefresh();
    this.expressLoading = true;
    this.expressReservationService(EXPRESS_SHORT);
  }

  expressReservationLong() {
    this.cancelReservationsRefresh();
    this.expressLoading = true;
    this.expressReservationService(EXPRESS_LONG);
  }

  getShortTimeDesc(): string {
    return `${EXPRESS_SHORT} min`;
  }

  getLongTimeDesc(): string {
    return `${EXPRESS_LONG} min`;
  }

  expressReservationService(time: number) {
    const dateStringPipe = new DateStringPipe();
    let dateReservation;

    this.reservationService
      .expressReservation(time, this.authenticationService.getToken())
      .subscribe(
        (response) => {
          // Actualizar el listado filtrando por el dia de la reserva hecha
          dateReservation = new Date();
          dateReservation.setTime(response.reservation.dateFrom);

          if (response.message != null && response.message.length > 0) {
            this.reservationResponse = response;
            this.showReservationMsg = true;
            this.dayfiltering = dateReservation;
          } else {
            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(
              this.messagesPipe.transform('express_resevation_success', [
                response.reservation.room.name,
                dateStringPipe.transform(
                  response.reservation.dateFrom.toString(),
                  'DD/MM/YYYY'
                ),
                dateStringPipe.transform(
                  response.reservation.dateFrom.toString(),
                  'HH:mm'
                ),
                dateStringPipe.transform(
                  response.reservation.dateTo.toString(),
                  'HH:mm'
                ),
              ]),
              { cssClass: 'alert-sucess', timeout: 3000 }
            );

            this.dateFilterElement.selectDate({
              day: dateReservation.getDate(),
              month: dateReservation.getMonth() + 1,
              year: dateReservation.getFullYear(),
            });
            this.showReservationButtons = false;
            this.expressLoading = false;
          }
        },
        (error) => {
          if (error.code === STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.expressReservationService(time);
              },
              () => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.expressReservationService(time);
                  },
                  () => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('express_create_error')
            );

            if ((error = STATUS_CONFLICT)) {
              this.closeDetail();
              this.showReservationButtons = false;
              this.expressLoading = false;
            } else {
              this.router.navigate(['/menu/home']);
            }
          }
        }
      );
  }

  closeReservationMessage() {
    this.dateFilterElement.selectDate({
      day: this.dayfiltering.getDate(),
      month: this.dayfiltering.getMonth() + 1,
      year: this.dayfiltering.getFullYear(),
    });
    this.showReservationButtons = false;
    this.expressLoading = false;
    this.reservationResponse = new ReservationResponseWs();
    this.showReservationMsg = false;
  }

  showResButtons() {
    this.showReservationButtons = !this.showReservationButtons;
  }

  getSlideButtonsClass(): string {
    if (this.ie) {
      const el = document.getElementById('add-meeting-room-detail');
      if (this.showReservationButtons) {
        el.style.display = 'block';
        el.style.opacity = '1';
      } else {
        el.style.display = 'none';
        el.style.opacity = '0';
      }
    } else {
      if (this.showReservationButtons) {
        return ANIMATE_CLASS_ACTIVE;
      } else {
        return ANIMATE_CLASS_INACTIVE;
      }
    }
  }

  onDateChanged($event) {
    const dateStringPipe = new DateStringPipe();
    const messagesPipe = new MessagesPipe();

    if ($event.formatted.length > 0) {
      this.dayfiltering = new Date();
      this.dayfiltering.setFullYear(
        $event.date.year,
        $event.date.month - 1,
        $event.date.day
      );
      this.dateFilterText = messagesPipe.transform(
        'reservation_list_filter_msg',
        [dateStringPipe.transform(this.dayfiltering.toString(), 'DD-MM-YYYY')]
      );
    } else {
      this.dayfiltering = null;
      this.dateFilterText = '';
    }

    this.cancelReservationsRefresh();
    this.loadReservations(true);
  }

  get hiddenReservationOtherHeadquarters(): boolean {
    return environment['hiddenReservationOtherHeadquarters'];
  }

  get hiddenInviteReservationRoom(): boolean {
    return environment['hiddenInviteReservationRoom'];
  }

  openSideModal(nameComponent: string, params: any, title: string) {
    this.sideModalService.openSideModal(
      nameComponent,
      params,
      title,
      this.route
    );
  }

  closeSideModal() {
    this.sideModalService.subjectSideModal(this.route).subscribe((res) => {
      if (res.close) {
        switch (res.nameComponent) {
          case ReservationRoutes.reservationNewDetail.routerName:
          case ReservationRoutes.reservationNewDetailByRoom.routerName:
          case ReservationRoutes.reservationNewFormByHQ.routerName:
          case ReservationRoutes.reservationNewSpeed.routerName:
            this.enableInterval();
            break;

          case ReservationRoutes.reservationSearchByRoomAndPeriod.routerName:
            this.loadReservations(true);
            this.showReservationButtons = false;
            break;
          default:
            break;
        }
      }
    });
  }
}
