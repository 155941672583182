import { ReservationWs } from './reservation.ws';

export class ReservationEdit {
  reservation: ReservationWs;
  dateFromEdit: Date;
  dateToEdit: Date;
  reasonEdit: string;
  duration: number;

  constructor(
    reservation: ReservationWs,
    dateFromEdit: Date,
    dateToEdit: Date,
    reasonEdit: string,
    duration?: number
  ) {
    this.reservation = reservation;
    this.dateFromEdit = dateFromEdit;
    this.dateToEdit = dateToEdit;
    this.reasonEdit = reasonEdit;

    if (duration) {
      this.duration = duration;
    }
  }
}
