import { Headquarters } from './headquarters';

export class Department {
  id: number;
  name: string;
  tag: string;
  headquarters: Headquarters;

  constructor(
    id: number,
    name: string,
    tag: string,
    headquarters?: Headquarters
  ) {
    this.id = id;
    this.name = name;
    this.tag = tag;

    if (headquarters) {
      this.headquarters = headquarters;
    }
  }
}
