<div
  [ngClass]="[
    getClassSelected(day),
    day.festive
      ? 'dayCalendarFestive'
      : day.disabled
      ? 'dayCalendarDisabled'
      : 'dayCalendar'
  ]"
  [style.background-color]="day.color"
  [style.border-width]="day.currentDay ? 'thick' : ''"
  [style.border-style]="day.currentDay ? 'solid' : ''"
  [style.border-color]="day.currentDay ? day.borderColor : ''"
>
  <div class="contentIcon">
    <span [style.color]="day.textColor">{{ day?.value }}</span>
    <div class="iconsStyle" *ngIf="day.assignments?.id">
      <div class="contentIcon__row">
        <i
          *ngIf="
            day.isParking || day?.assignments?.parking?.userInParkingWaitingList
          "
          [ngClass]="getIconVehicule(day)"
        ></i>
        <i
          *ngIf="day.isDinner"
          class="vdp-comedor_big"
          [ngClass]="day.isParking && day.isDinner ? 'float-right' : ''"
        ></i>
      </div>
    </div>
  </div>
</div>
