import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_ACTIVE_OPACITY,
  ANIMATE_CLASS_INACTIVE,
  ANIMATE_CLASS_INACTIVE_OPACITY,
  AVAILABLE_HOURS,
  AVAILABLE_MINUTES,
  RESERVATION_STATE_CHECKED,
  RESERVATION_STATUS,
} from '../../../core/constants/consts';
import { ReservationEdit } from '../../../core/models/reservation.edit';
import { ReservationWs } from '../../../core/models/reservation.ws';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';
import { CustomDatePipe } from '../../../shared/pipes/custom.date.pipe';
import { CustomTimePipe } from '../../../shared/pipes/custom.time.pipe';
import { DateStringPipe } from '../../../shared/pipes/date.string.pipe';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { slideDetailsBooking } from '../../../shared/utils/reservation.list.animations';
import { Utils } from '../../../shared/utils/utils';

@Component({
  selector: 'app-reservation-detail',
  templateUrl: 'reservation.detail.component.html',
  styleUrls: ['reservation-detail.scss'],
  animations: [slideDetailsBooking()],
})
export class ReservationDetailComponent implements OnInit, OnChanges {
  @Input() reservationWs: ReservationWs;
  @Output() eventCloseDetail = new EventEmitter<any>();
  @Output() eventCancel = new EventEmitter<ReservationWs>();
  @Output() eventEdit = new EventEmitter<ReservationEdit>();
  @ViewChild('selectHourFrom', null) selectHourFrom: any;
  @ViewChild('selectMinuteFrom', null) selectMinuteFrom: any;
  @ViewChild('selectHourTo', null) selectHourTo: any;
  @ViewChild('selectMinuteTo', null) selectMinuteTo: any;
  showEditModal: boolean;
  errorDatesInterval: boolean;
  disableSaveEditBtn: boolean;
  hours: string[];
  dateFrom: Date;
  dateTo: Date;
  hoursFrom: string[];
  hoursTo: string[];
  hourFrom: string;
  hourTo: string;
  reason: string;
  minutesFrom = AVAILABLE_MINUTES;
  minutesTo = AVAILABLE_MINUTES;
  dateStringPipe: DateStringPipe;
  editReservationDateError: string;
  currentUser: UserLdapWs;

  constructor(
    private utils: Utils,
    private authenticationService: AuthenticationService
  ) {
    let minHourIndex;
    let minDate;
    this.showEditModal = false;
    this.dateStringPipe = new DateStringPipe();
    this.reason = '';

    minDate = this.authenticationService.getMinReservationTime();

    if (minDate != null) {
      minHourIndex = AVAILABLE_HOURS.indexOf(
        this.dateStringPipe.transform(minDate, 'HH')
      );

      this.hoursFrom = AVAILABLE_HOURS.slice(
        minHourIndex,
        AVAILABLE_HOURS.length
      );
      this.hoursTo = AVAILABLE_HOURS.slice(
        minHourIndex,
        AVAILABLE_HOURS.length
      );
    } else {
      this.hoursFrom = AVAILABLE_HOURS;
      this.hoursTo = AVAILABLE_HOURS;
    }
  }

  ngOnInit() {
    this.hours = this.utils.getReservationsHours();
    this.currentUser = this.authenticationService.getCurrentUser();
  }

  ngOnChanges() {
    if (this.reservationWs.id) {
      this.reservationWs.room.reservations =
        this.reservationWs.room.reservations.filter((reservation) => {
          return reservation.id !== this.reservationWs.id;
        });
      this.dateFrom = new Date(this.reservationWs.dateFrom.getTime());
      this.dateTo = new Date(this.reservationWs.dateTo.getTime());
    } else {
      this.dateFrom = new Date();
      this.dateTo = new Date();
    }

    this.showEditModal = false;
    this.editReservationDateError = '';
  }

  getEditModalRoomName(): string {
    const dateStringPipe = new DateStringPipe();
    const customDatePipe = new CustomDatePipe();

    if (this.reservationWs && this.reservationWs.room) {
      return `${this.reservationWs.room.name} - ${dateStringPipe.transform(
        this.dateFrom.toString(),
        'DD'
      )} ${customDatePipe.transform(this.dateFrom.getTime())}`;
    } else {
      return '';
    }
  }

  getAssistants(): string {
    return `${this.reservationWs.assistants}/${this.reservationWs.room.capacityMax}`;
  }

  getTime(): string {
    const messagesPipe = new MessagesPipe();

    return messagesPipe.transform('time_str', [
      this.dateStringPipe.transform(
        this.reservationWs.dateFrom.toString(),
        'HH:mm'
      ),
      this.dateStringPipe.transform(
        this.reservationWs.dateTo.toString(),
        'HH:mm'
      ),
    ]);
  }

  closeDetail(): void {
    this.eventCloseDetail.emit();
  }

  cancelReservation(): void {
    this.eventCancel.emit(this.reservationWs);
  }

  cancelEdit(): void {
    this.showEditModal = false;
  }

  editReservation(): void {
    if (this.dateFrom.getHours().toString().length > 1) {
      this.hourFrom = this.dateFrom.getHours().toString();
    } else {
      this.hourFrom = `0${this.dateFrom.getHours()}`;
    }

    this.selectHourFrom.nativeElement.selectedIndex = this.hoursFrom.indexOf(
      this.hourFrom
    );

    if (this.dateFrom.getMinutes().toString().length > 1) {
      this.hourFrom = `${this.hourFrom}:${this.dateFrom.getMinutes()}`;
      this.selectMinuteFrom.nativeElement.selectedIndex =
        this.minutesFrom.indexOf(this.dateFrom.getMinutes().toString());
    } else {
      this.hourFrom = `${this.hourFrom}:0${this.dateFrom.getMinutes()}`;
      this.selectMinuteFrom.nativeElement.selectedIndex =
        this.minutesFrom.indexOf(`0${this.dateFrom.getMinutes()}`);
    }

    if (this.dateTo.getHours().toString().length > 1) {
      this.hourTo = this.dateTo.getHours().toString();
    } else {
      this.hourTo = `0${this.dateTo.getHours()}`;
    }

    this.selectHourTo.nativeElement.selectedIndex = this.hoursTo.indexOf(
      this.hourTo
    );

    if (this.dateTo.getMinutes().toString().length > 1) {
      this.hourTo = `${this.hourTo}:${this.dateTo.getMinutes()}`;
      this.selectMinuteTo.nativeElement.selectedIndex = this.minutesTo.indexOf(
        this.dateTo.getMinutes().toString()
      );
    } else {
      this.hourTo = `${this.hourTo}:0${this.dateTo.getMinutes()}`;
      this.selectMinuteTo.nativeElement.selectedIndex = this.minutesTo.indexOf(
        `0${this.dateTo.getMinutes()}`
      );
    }

    this.reason = this.reservationWs.reason;
    this.showEditModal = true;
    this.errorDatesInterval = false;
    this.disableSaveEditBtn = false;
  }

  saveEditReservation(): void {
    this.disableSaveEditBtn = true;
    const timePipe = new CustomTimePipe();
    const editDateFrom = timePipe.transform(this.dateFrom, this.hourFrom);
    const editDateTo = timePipe.transform(this.dateTo, this.hourTo);
    const actualDate = new Date();
    const messagePipe = new MessagesPipe();

    if (this.utils.compareDates(editDateFrom, actualDate) < 0) {
      this.errorDatesInterval = true;
      this.disableSaveEditBtn = false;
      this.editReservationDateError = messagePipe.transform(
        'date_from_greater_actual'
      );
    } else if (this.utils.compareDates(editDateFrom, editDateTo) >= 0) {
      this.errorDatesInterval = true;
      this.disableSaveEditBtn = false;
      this.editReservationDateError = messagePipe.transform('date_to_greater');
    } else {
      const reservationEdit = new ReservationEdit(
        this.reservationWs,
        editDateFrom,
        editDateTo,
        this.reason
      );
      this.eventEdit.emit(reservationEdit);
    }
  }

  getDetailReservationAnimate(): string {
    if (this.reservationWs.id) {
      return ANIMATE_CLASS_ACTIVE;
    } else {
      return ANIMATE_CLASS_INACTIVE;
    }
  }

  getDetailReservationAnimateOpacity(): string {
    if (this.reservationWs.id) {
      return ANIMATE_CLASS_ACTIVE_OPACITY;
    } else {
      return ANIMATE_CLASS_INACTIVE_OPACITY;
    }
  }

  getClassScheduleDiv(dateIni: any, dateEnd: any) {
    return this.utils.getClassScheduleDiv(
      this.hours,
      dateIni,
      dateEnd,
      '',
      null,
      null,
      false
    );
  }

  getClassLaunchTime(): string {
    const lunchDateIni = new Date(this.reservationWs.room.lunchStartingTime);
    const lunchDateEnd = new Date(this.reservationWs.room.lunchEndingTime);

    return `lunch start-${this.utils.getPosHour(
      this.hours,
      lunchDateIni
    )} end-${this.utils.getPosHour(this.hours, lunchDateEnd)}`;
  }

  addMinimunHour(date: Date) {
    this.utils.addMinimunHour(date);
  }

  addMaximunHour(date: Date) {
    this.utils.addMaximunHour(date);
  }

  reservationPendingConfirmation(): boolean {
    return (
      this.reservationWs.status.id === RESERVATION_STATUS.PENDING_CONFIRMATION
    );
  }

  getIsChecked(): boolean {
    if (
      this.reservationWs.status != null &&
      this.reservationWs.status.id != null
    ) {
      if (RESERVATION_STATE_CHECKED === this.reservationWs.status.id) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  onChangeHourFrom($event) {
    const minute = this.hourFrom.split(':')[1];

    this.hourFrom = `${$event.target.value}:${minute}`;
  }

  onChangeMinuteFrom($event) {
    const hour = this.hourFrom.split(':')[0];

    this.hourFrom = `${hour}:${$event.target.value}`;
  }

  onChangeHourTo($event) {
    const minute = this.hourTo.split(':')[1];

    this.hourTo = `${$event.target.value}:${minute}`;
  }

  onChangeMinuteTo($event) {
    const hour = this.hourTo.split(':')[0];

    this.hourTo = `${hour}:${$event.target.value}`;
  }
}
