<!-- There are available positions -->
<div *ngIf="existAvailablePositions">
  <div class="main-container-tab">
    <div class="fadeInLayer">
      <ul class="tabs">
        <li
          *ngFor="let building of buildings"
          (click)="changeTab(building)"
          [ngClass]="{ selected: building == currentBuilding }"
        >
          <span>{{ building.name }}</span>
        </li>
      </ul>

      <!-- Button -->
      <div class="add-multiple-reservation" *ngIf="currentSelection">
        <button
          type="button"
          class="add-reservation"
          (click)="doReservation(currentReservation)"
        >
          <i class="vdp-icon-mas"></i
          >{{ 'room_available_btn' | messagesPipe }}
        </button>
      </div>

      <div class="positionsList row">
        <!-- Card -->
        <div
          class="available-position-card"
          #availablePositionCard
          *ngFor="let reservation of currentBuilding.reservations; let i = index"
          (click)="confirmReservation($event, reservation, currentBuilding.id, i)" id="{{i}}"
        >
          <div>
            <div class="position">
              <span>
                {{
                  filterPosition(
                    reservation.deskMultiReservations[0].mapaHotDesktop
                  )
                }}
              </span>
            </div>
            <div class="floor">
              {{ reservation?.deskMultiReservations[0].nameFloor }},
              {{ reservation?.deskMultiReservations[0].nameArea }}
            </div>
            <div class="date">
              {{ reservation?.dateFrom | date }}
              {{ 'time_str_from' | messagesPipe }}
              {{ reservation?.hourFrom }}:{{ reservation?.minuteFrom }}
              {{ 'time_str_to' | messagesPipe }}
              {{ reservation?.hourTo }}:{{ reservation?.minuteTo }}
            </div>
          </div>
          <div class="icon" (click)="showMapView($event, reservation)">
            <i class="vdp-map"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Without available positions -->
<div *ngIf="!existAvailablePositions" class="without-positions">
  {{ 'reservation_no_available_positions' | messagesPipe }}
</div>
