import { Component, OnInit, Input } from '@angular/core';
import { MessagesPipe } from '../../../../shared/pipes/messages.pipe';

@Component({
  selector: 'app-lopd-puig',
  templateUrl: './lopd-puig.component.html',
  styleUrls: ['./lopd-puig.component.scss'],
})
export class LopdPuigComponent implements OnInit {
  @Input() language: string;
  messagesPipe = new MessagesPipe();
  lopdTextTitle: string;
  lopdTextSubtitle: string;
  lopdTextOurCommitmentTitle: string;
  lopdTextOurCommitmentParraf1: string;
  lopdTextOurCommitmentParraf2: string;
  lopdTextOurCommitmentParraf3: string;
  lopdTextOurCommitmentParraf4: string;
  lopdTextOurCommitmentParraf5: string;
  lopdTextOurCommitmentParraf6: string;
  lopdTextApplicableLegislationTitle: string;
  lopdTextApplicableLegislationParraf1: string;
  lopdTextPersonalDataTitle: string;
  lopdTextPersonalDataParraf1: string;
  lopdTextPersonalDataParraf2: string;
  lopdTextPersonalDataParraf3: string;
  lopdTextPersonalDataParraf4: string;
  lopdTextNoPersonalDataTitle: string;
  lopdTextNoPersonalDataParraf1: string;
  lopdTextNoPersonalDataParraf2: string;
  lopdTextDisclosurePersonalDataTitle: string;
  lopdTextDisclosurePersonalDataParraf1: string;
  lopdTextDisclosurePersonalDataParraf2: string;
  lopdTextDisclosurePersonalDataParraf3: string;
  lopdTextDisclosurePersonalDataParraf4: string;
  lopdTextDisclosurePersonalDataParraf5: string;
  lopdTextDisclosurePersonalDataParraf6: string;
  lopdTextSecurityMeasuresTitle: string;
  lopdTextSecurityMeasuresParraf1: string;
  lopdTextSecurityMeasuresParraf2: string;
  lopdTextSecurityMeasuresParraf3: string;
  lopdTextDataTransferTitle: string;
  lopdTextDataTransferParraf1: string;
  lopdTextDataTransferParraf2: string;
  lopdTextRightPersonalDataTitle: string;
  lopdTextRightPersonalDataParraf1: string;
  lopdTextRightPersonalDataParraf2: string;
  lopdTextWithdrawConsentTitle: string;
  lopdTextWithdrawConsentParraf1: string;
  lopdAccessPersonalDataTitle: string;
  lopdAccessPersonalDataParraf1: string;
  lopdAccessPersonalDataParraf2: string;
  lopdTextRightRectifyDataTitle: string;
  lopdTextRightRectifyDataParraf1: string;
  lopdTextRightToErasureTitle: string;
  lopdTextRightToErasureParraf1: string;
  lopdTextRightRestrictionTitle: string;
  lopdTextRightRestrictionParraf1: string;
  lopdTextDataPortabilityRightTitle: string;
  lopdTextDataPortabilityRightParraf1: string;
  lopdTextRightOfOppositionTitle: string;
  lopdTextRightOfOppositionParraf1: string;
  lopdTextRightOfComplaintTitle: string;
  lopdTextRightOfComplaintParraf1: string;
  lopdTextOptOutTitle: string;
  lopdTextOptOutParraf1: string;
  lopdTextOptOutParraf2: string;
  lopdTextContactsTitle: string;
  lopdTextContactsParraf1: string;
  lopdTextPrivacyPolicyTitle: string;
  lopdTextPrivacyPolicyParraf1: string;
  lopdTextPrivacyPolicyParraf2: string;
  lopdTextFoot: string;

  constructor() {}

  ngOnInit() {
    this.messagesPipe.setLanguage(this.language);
    this.lopdTextTitle = this.messagesPipe.transform('lopd_text_title');
    this.lopdTextSubtitle = this.messagesPipe.transform('lopd_text_subtitle');
    this.lopdTextOurCommitmentTitle = this.messagesPipe.transform('lopd_text_our_commitment_title');
    this.lopdTextOurCommitmentParraf1 = this.messagesPipe.transform('lopd_text_our_commitment_parraf_1');
    this.lopdTextOurCommitmentParraf2 = this.messagesPipe.transform('lopd_text_our_commitment_parraf_2');
    this.lopdTextOurCommitmentParraf3 = this.messagesPipe.transform('lopd_text_our_commitment_parraf_3');
    this.lopdTextOurCommitmentParraf4 = this.messagesPipe.transform('lopd_text_our_commitment_parraf_4');
    this.lopdTextOurCommitmentParraf5 = this.messagesPipe.transform('lopd_text_our_commitment_parraf_5');
    this.lopdTextOurCommitmentParraf6 = this.messagesPipe.transform('lopd_text_our_commitment_parraf_6');
    this.lopdTextApplicableLegislationTitle = this.messagesPipe.transform('lopd_text_applicable_legislation_title');
    this.lopdTextApplicableLegislationParraf1 = this.messagesPipe.transform('lopd_text_applicable_legislation_parraf1');
    this.lopdTextPersonalDataTitle = this.messagesPipe.transform('lopd_text_personal_data_title');
    this.lopdTextPersonalDataParraf1 = this.messagesPipe.transform('lopd_text_personal_data_parraf1');
    this.lopdTextPersonalDataParraf2 = this.messagesPipe.transform('lopd_text_personal_data_parraf2');
    this.lopdTextPersonalDataParraf3 = this.messagesPipe.transform('lopd_text_personal_data_parraf3');
    this.lopdTextPersonalDataParraf4 = this.messagesPipe.transform('lopd_text_personal_data_parraf4');
    this.lopdTextNoPersonalDataTitle = this.messagesPipe.transform('lopd_text_no_personal_data_title');
    this.lopdTextNoPersonalDataParraf1 = this.messagesPipe.transform('lopd_text_no_personal_data_parraf1');
    this.lopdTextNoPersonalDataParraf2 = this.messagesPipe.transform('lopd_text_no_personal_data_parraf2');
    this.lopdTextDisclosurePersonalDataTitle = this.messagesPipe.transform('lopd_text_disclosure_personal_data_title');
    this.lopdTextDisclosurePersonalDataParraf1 = this.messagesPipe.transform('lopd_text_disclosure_personal_data_parraf1');
    this.lopdTextDisclosurePersonalDataParraf2 = this.messagesPipe.transform('lopd_text_disclosure_personal_data_parraf2');
    this.lopdTextDisclosurePersonalDataParraf3 = this.messagesPipe.transform('lopd_text_disclosure_personal_data_parraf3');
    this.lopdTextDisclosurePersonalDataParraf4 = this.messagesPipe.transform('lopd_text_disclosure_personal_data_parraf4');
    this.lopdTextDisclosurePersonalDataParraf5 = this.messagesPipe.transform('lopd_text_disclosure_personal_data_parraf5');
    this.lopdTextDisclosurePersonalDataParraf6 = this.messagesPipe.transform('lopd_text_disclosure_personal_data_parraf6');
    this.lopdTextSecurityMeasuresTitle = this.messagesPipe.transform('lopd_text_security_measures_title');
    this.lopdTextSecurityMeasuresParraf1 = this.messagesPipe.transform('lopd_text_security_measures_parraf1');
    this.lopdTextSecurityMeasuresParraf2 = this.messagesPipe.transform('lopd_text_security_measures_parraf2');
    this.lopdTextSecurityMeasuresParraf3 = this.messagesPipe.transform('lopd_text_security_measures_parraf3');
    this.lopdTextDataTransferTitle = this.messagesPipe.transform('lopd_text_data_transfer_title');
    this.lopdTextDataTransferParraf1 = this.messagesPipe.transform('lopd_text_data_transfer_parraf1');
    this.lopdTextDataTransferParraf2 = this.messagesPipe.transform('lopd_text_data_transfer_parraf2');
    this.lopdTextRightPersonalDataTitle = this.messagesPipe.transform('lopd_text_right_personal_data_title');
    this.lopdTextRightPersonalDataParraf1 = this.messagesPipe.transform('lopd_text_right_personal_data_parraf1');
    this.lopdTextRightPersonalDataParraf2 = this.messagesPipe.transform('lopd_text_right_personal_data_parraf2');
    this.lopdTextWithdrawConsentTitle = this.messagesPipe.transform('lopd_text_withdraw_consent_title');
    this.lopdTextWithdrawConsentParraf1 = this.messagesPipe.transform('lopd_text_withdraw_consent_parraf1');
    this.lopdAccessPersonalDataTitle = this.messagesPipe.transform('lopd_access_personal_data_title');
    this.lopdAccessPersonalDataParraf1 = this.messagesPipe.transform('lopd_access_personal_data_parraf1');
    this.lopdAccessPersonalDataParraf2 = this.messagesPipe.transform('lopd_access_personal_data_parraf2');
    this.lopdTextRightRectifyDataTitle = this.messagesPipe.transform('lopd_text_right_rectify_data_title');
    this.lopdTextRightRectifyDataParraf1 = this.messagesPipe.transform('lopd_text_right_rectify_data_parraf1');
    this.lopdTextRightToErasureTitle = this.messagesPipe.transform('lopd_text_right_to_erasure_title');
    this.lopdTextRightToErasureParraf1 = this.messagesPipe.transform('lopd_text_right_to_erasure_parraf1');
    this.lopdTextRightRestrictionTitle = this.messagesPipe.transform('lopd_text_right_restriction_title');
    this.lopdTextRightRestrictionParraf1 = this.messagesPipe.transform('lopd_text_right_restriction_parraf1');
    this.lopdTextDataPortabilityRightTitle = this.messagesPipe.transform('lopd_text_data_portability_right_title');
    this.lopdTextDataPortabilityRightParraf1 = this.messagesPipe.transform('lopd_text_data_portability_right_parraf1');
    this.lopdTextRightOfOppositionTitle = this.messagesPipe.transform('lopd_text_right_of_opposition_title');
    this.lopdTextRightOfOppositionParraf1 = this.messagesPipe.transform('lopd_text_right_of_opposition_parraf1');
    this.lopdTextRightOfComplaintTitle = this.messagesPipe.transform('lopd_text_right_of_complaint_title');
    this.lopdTextRightOfComplaintParraf1 = this.messagesPipe.transform('lopd_text_right_of_complaint_parraf1');
    this.lopdTextOptOutTitle = this.messagesPipe.transform('lopd_text_opt-out_title');
    this.lopdTextOptOutParraf1 = this.messagesPipe.transform('lopd_text_opt-out_parraf1');
    this.lopdTextOptOutParraf2 = this.messagesPipe.transform('lopd_text_opt-out_parraf2');
    this.lopdTextContactsTitle = this.messagesPipe.transform('lopd_text_contacts_title');
    this.lopdTextContactsParraf1 = this.messagesPipe.transform('lopd_text_contacts_parraf1');
    this.lopdTextPrivacyPolicyTitle = this.messagesPipe.transform('lopd_text_privacy_policy_title');
    this.lopdTextPrivacyPolicyParraf1 = this.messagesPipe.transform('lopd_text_privacy_policy_parraf1');
    this.lopdTextPrivacyPolicyParraf2 = this.messagesPipe.transform('lopd_text_privacy_policy_parraf2');
    this.lopdTextFoot = this.messagesPipe.transform('lopd_text_foot');
  }
}
