
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'customTimePipe'})
export class CustomTimePipe {

    transform(date:Date,value:string) :Date{
        let dateAux = new Date()
        let fields = value.split(':');

        dateAux.setTime(date.getTime());
        //dateAux.setMilliseconds(date.getMilliseconds());
        dateAux.setHours(parseInt(fields[0]), parseInt(fields[1]), 0);

        return dateAux;
    }
}
