import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  DEFAULT_CLOSE_RESERVE,
  DEFAULT_OPEN_MINUTE_RESERVE,
  DEFAULT_OPEN_RESERVE,
  DEFAULT_TIME_INTERVAL,
} from 'src/app/core/constants/consts';
import { Utils } from 'src/app/shared/utils/utils';
import { OccupationList } from '../../../core/models/occupation.list';
import { MessagesPipe } from '../../pipes/messages.pipe';
import { FlashMessagesService } from '../../services/flash-messages.service';
import { ModalErrorMobileService } from '../modal-error-mobile/modal-error-mobile.service';
import { ModalReservationGenericService } from './modal-reservation-generic.service';

@Component({
  selector: 'app-modal-reservation-generic',
  templateUrl: './modal-reservation-generic.component.html',
  styleUrls: ['./modal-reservation-generic.component.scss'],
})
export class ModalReservationGenericComponent implements OnInit {
  @Output() submitReserve = new EventEmitter();
  @Output() submitEdit = new EventEmitter();

  modalConfig: any;
  modalOpen: boolean = false;
  fullPage: boolean = false;

  showAllReservation: boolean = true;
  showReservation: boolean = false;
  edit: boolean = false;
  idEdit: number;
  id: number;
  date: any;
  hours: any = {
    data: [],
  };
  reservationFree: Array<any> = [];
  occupationList: OccupationList[];
  timeInterval: number = DEFAULT_TIME_INTERVAL;
  openHour = DEFAULT_OPEN_RESERVE;
  openMinute = DEFAULT_OPEN_MINUTE_RESERVE;
  closeHour = DEFAULT_CLOSE_RESERVE;
  url: String;

  messagesPipe = new MessagesPipe();
  reserve_title: string;
  reserve_toSelect: string;
  reserve_toOccupation: string;
  reserve_selected: string;
  reserve_cancel: string;
  reserve_reserve_all: string;
  reserve_reserve: string;
  reserve_edit: string;

  constructor(
    private modalReservationGenericService: ModalReservationGenericService,
    private utils: Utils,
    private modalErrorMobileService: ModalErrorMobileService,
    private route: ActivatedRoute,
    private flashMessagesService: FlashMessagesService
  ) {
    this.modalReservationGenericService.openModal = this.openModal.bind(this);
    this.modalReservationGenericService.closeModal = this.closeModal.bind(this);
    this.modalReservationGenericService.setLanguage =
      this.setLanguage.bind(this);
  }

  ngOnInit() {
    this.reservationFree = [];
    this.hours = this.utils.getReservationsHours();
    this.modalConfig = document.getElementById('modalConfig');
    this.url = this.route.snapshot['_routerState'].url as String;
  }

  openModal(
    date: number,
    openingTime: number,
    endingTime: number,
    timeInterval: number,
    occupationList: OccupationList[],
    fullPage?: boolean,
    idEdit?: number,
    edit?: boolean
  ) {
    if (new Date(openingTime).getHours()) {
      this.openHour = new Date(openingTime).getHours();
      this.openMinute = new Date(openingTime).getMinutes();
    }
    if (new Date(endingTime).getHours()) {
      this.closeHour = new Date(endingTime).getHours() - 1;
    }
    if (timeInterval) {
      this.timeInterval = timeInterval;
    } else {
      this.timeInterval = DEFAULT_TIME_INTERVAL;
    }

    this.occupationList = occupationList;
    this.reservationFree = [];
    if (idEdit) {
      this.idEdit = idEdit;
      this.edit = edit;
    } else {
      this.edit = false;
      this.idEdit = null;
    }
    if (date) {
      this.date = date;
      if (this.edit) {
        this.createReservationFreeEdit();
      } else {
        this.createReservationFree();
      }
    } else {
      this.date = null;
    }

    if (!this.modalOpen) {
      this.modalConfig.style.display = 'flex';
      window.setTimeout(() => {
        this.modalConfig.style.opacity = 1;
        this.modalOpen = true;
      }, 100);
    }

    if (fullPage != null && fullPage == true) {
      this.fullPage = true;
    } else {
      this.fullPage = false;
    }
  }

  closeModal() {
    if (this.modalOpen) {
      this.modalConfig.style.opacity = 0;
      window.setTimeout(() => {
        this.modalOpen = false;
        this.modalConfig.style.display = 'none';
      }, 100);
    }
  }
  createReservationFreeEdit() {
    this.reservationFree = [];
    for (let hour = this.openHour; hour <= this.closeHour; hour++) {
      let maxHour = 60;
      let min = 0;

      if (this.openHour == hour) {
        min = 0;
      }

      for (min; min < maxHour; min += this.timeInterval) {
        let occupied = false;
        let edit = false;
        this.occupationList.forEach((item) => {
          if (item.hourFrom === item.hourTo) {
            if (
              hour === item.hourFrom &&
              min >= item.minuteFrom &&
              min < item.minuteTo &&
              !occupied
            ) {
              occupied = true;
              if (this.edit && item.id == this.idEdit) {
                edit = true;
              }
            }
          } else {
            if (
              (hour === item.hourFrom && min >= item.minuteFrom) ||
              (hour === item.hourTo && min < item.minuteTo + 1 && !occupied)
            ) {
              occupied = true;
              if (this.edit && item.id == this.idEdit) {
                edit = true;
              }
            } else {
              if (
                hour != item.hourFrom &&
                hour != item.hourTo &&
                hour > item.hourFrom &&
                hour < item.hourTo &&
                !occupied
              ) {
                occupied = true;
                if (this.edit && item.id == this.idEdit) {
                  edit = true;
                }
              }
            }
          }
        });
        if (!occupied && !edit) {
          this.reservationFree.push({
            dateFrom: hour,
            minFrom: min,
            occupied: false,
            active: false,
          });
        } else if (occupied && edit) {
          this.reservationFree.push({
            dateFrom: hour,
            minFrom: min,
            occupied: false,
            active: true,
          });
        } else if (occupied && !edit) {
          this.reservationFree.push({
            dateFrom: hour,
            minFrom: min,
            occupied: true,
            active: false,
          });
        }
      }
    }
    this.loadButtonReservation();
    this.loadReservationAll();
    window.setTimeout(() => {
      this.scrollToFirstElement(document.getElementById('interval-0'));
    }, 0);
  }

  createReservationFree() {
    this.reservationFree = [];
    for (let hour = this.openHour; hour <= this.closeHour; hour++) {
      let maxHour = 60;
      let min = 0;

      if (this.openHour == hour) {
        min = 0;
      }

      for (min; min < maxHour; min += this.timeInterval) {
        this.reservationFree.push({
          dateFrom: hour,
          minFrom: min,
          occupied: false,
          active: false,
        });
      }
    }
    this.loadButtonReservation();
    this.loadReservationAll();
    window.setTimeout(() => {
      this.scrollToFirstElement(document.getElementById('interval-0'));
    }, 0);
  }

  // Una vez que le doy click a una reserva
  includeReserver(reservation) {
    let preSelect = this.reservationFree.findIndex(
      (x) =>
        x.dateFrom == reservation.dateFrom && x.minFrom == reservation.minFrom
    );
    if (reservation.active) {
      let countOverReservation = this.reservationFree.filter(
        (reserve) =>
          (reserve.dateFrom < reservation.dateFrom ||
            (reserve.dateFrom === reservation.dateFrom &&
              reserve.minFrom < reservation.minFrom)) &&
          reserve.active
      ).length;
      let countUnderReservation = this.reservationFree.filter(
        (reserve) =>
          (reserve.dateFrom > reservation.dateFrom ||
            (reserve.dateFrom === reservation.dateFrom &&
              reserve.minFrom > reservation.minFrom)) &&
          reserve.active
      ).length;
      if (
        this.reservationFree.filter((reserve) => reserve.active).length <= 2
      ) {
        reservation.active = false;
      } else if (countOverReservation < countUnderReservation) {
        for (let index = preSelect; index >= 0; index--) {
          if (
            this.reservationFree[index] &&
            this.reservationFree[index].active
          ) {
            this.reservationFree[index].active = false;
          }
        }
      } else if (countOverReservation >= countUnderReservation) {
        for (
          let index = preSelect;
          index <= this.reservationFree.length;
          index++
        ) {
          if (
            this.reservationFree[index] &&
            this.reservationFree[index].active
          ) {
            this.reservationFree[index].active = false;
          }
        }
      }
    } else if (!this.reservationFree.find((x) => x.active)) {
      reservation.active = true;
    } else if (preSelect >= 0) {
      if (
        (this.reservationFree[preSelect - 1] &&
          this.reservationFree[preSelect - 1].active) ||
        (this.reservationFree[preSelect + 1] &&
          this.reservationFree[preSelect + 1].active)
      ) {
        reservation.active = true;
      } else {
        const title = this.messagesPipe.transform(
          'message_DeskNotSelected_Title'
        );
        const description = this.messagesPipe.transform(
          'message_DeskConsecutive_Description'
        );
        if (this.fullPage) {
          this.modalErrorMobileService.show(title, description);
        } else {
          this.flashMessagesService.show(description, {
            cssClass: 'alert-success',
            timeout: 3000,
          });
        }
      }
    }
  }

  //Comprueba que se puede pulsar el boton de reserva
  loadButtonReservation() {
    if (this.reservationFree.length <= 0) {
      this.showReservation = false;
    } else {
      const now = new Date();
      const date = new Date(+this.date as number);
      date.setHours(0, 0, 0);
      now.setHours(0, 0, 0);
      if (
        date.getDate() == now.getDate() &&
        date.getMonth() == now.getMonth() &&
        date.getFullYear() == now.getFullYear()
      ) {
        const nowDate = new Date();
        if (this.timeInterval === 60) {
          nowDate.setHours(nowDate.getHours() + 1, 0, 0);
        } else {
          nowDate.setHours(nowDate.getHours(), nowDate.getMinutes(), 0);
        }

        let firstSelect = false;
        this.reservationFree.forEach((reservation) => {
          const dateReservation = new Date();
          dateReservation.setHours(
            reservation.dateFrom,
            reservation.minFrom,
            0
          );
          if (
            dateReservation.getHours() > nowDate.getHours() ||
            (dateReservation.getHours() == nowDate.getHours() &&
              dateReservation.getMinutes() >= nowDate.getMinutes())
          ) {
            this.showReservation = true;
          }
        });
      } else if (
        date.getDate() > now.getDate() ||
        date.getMonth() > now.getMonth() ||
        date.getFullYear() > now.getFullYear()
      ) {
        this.reservationFree.forEach((reservation) => {
          this.showReservation = true;
        });
      } else {
        this.showReservation = false;
      }
    }
  }

  loadReservationAll() {
    const now = new Date();
    const date = new Date(+this.date as number);
    date.setHours(0, 0, 0);
    now.setHours(0, 0, 0);
    if (
      date.getDate() > now.getDate() ||
      date.getMonth() > now.getMonth() ||
      date.getFullYear() > now.getFullYear()
    ) {
      if (this.occupationList && this.occupationList.length == 0) {
        this.showAllReservation = true;
      } else {
        this.showAllReservation = false;
      }
    } else if (
      date.getDate() == now.getDate() &&
      date.getMonth() == now.getMonth() &&
      date.getFullYear() == now.getFullYear()
    ) {
      let nowHour = new Date();

      if (this.timeInterval == 60) {
        nowHour.setHours(nowHour.getHours() + 1, 0, 0);
      } else {
        nowHour.setHours(
          nowHour.getHours(),
          nowHour.getMinutes() + this.timeInterval,
          0
        );
      }
      if (this.occupationList.find((x) => x.hourTo + 1 > nowHour.getHours())) {
        this.showAllReservation = false;
      } else {
        this.showAllReservation = true;
      }
    } else {
      this.showAllReservation = false;
    }
  }

  addReservationAll() {
    const now = new Date();
    const date = new Date(+this.date as number);
    date.setHours(0, 0, 0);
    now.setHours(0, 0, 0);
    if (
      date.getDate() == now.getDate() &&
      date.getMonth() == now.getMonth() &&
      date.getFullYear() == now.getFullYear()
    ) {
      const nowDate = new Date();
      if (this.timeInterval === 60) {
        nowDate.setHours(nowDate.getHours() + 1, 0, 0);
      } else {
        nowDate.setHours(nowDate.getHours(), nowDate.getMinutes(), 0);
      }
      this.reservationFree.forEach((reservation) => {
        const dateReservation = new Date();
        dateReservation.setHours(reservation.dateFrom, reservation.minFrom, 0);
        if (
          this.timeInterval == 60 &&
          dateReservation.getHours() >= nowDate.getHours() &&
          !reservation.occupied
        ) {
          reservation.active = true;
        } else {
          if (
            dateReservation.getHours() > nowDate.getHours() &&
            !reservation.occupied
          ) {
            reservation.active = true;
          } else if (
            dateReservation.getHours() == nowDate.getHours() &&
            dateReservation.getMinutes() > nowDate.getMinutes() &&
            !reservation.occupied
          ) {
            reservation.active = true;
          }
        }
      });
    } else if (
      date.getDate() > now.getDate() ||
      date.getMonth() > now.getMonth() ||
      date.getFullYear() > now.getFullYear()
    ) {
      this.reservationFree.forEach((reservation) => {
        reservation.active = true;
      });
    }
  }

  addReservation() {
    let reserveInit = this.reservationFree
      .filter((reserveInit) => reserveInit.active)
      .shift();
    if (reserveInit) {
      let reserveEnd = this.reservationFree
        .filter((reserve) => reserve.active)
        .pop();
      let dateFrom = new Date(+this.date as number);
      let dateTo = new Date(+this.date as number);

      if (this.timeInterval === 60) {
        dateFrom.setHours(reserveInit.dateFrom, 0, 0, 0);
        dateTo.setHours(reserveEnd.dateFrom + 1, 0, 0, 0);
      } else if (this.timeInterval === 30) {
        dateFrom.setHours(reserveInit.dateFrom, reserveInit.minFrom, 0, 0);
        if (reserveInit.minFrom >= 30) {
          if (reserveInit.dateFrom === reserveEnd.dateFrom) {
            dateTo.setHours(reserveEnd.dateFrom + 1, 0, 0, 0);
          } else {
            dateTo.setHours(
              reserveEnd.dateFrom,
              reserveEnd.minFrom + this.timeInterval,
              0,
              0
            );
          }
        } else {
          dateTo.setHours(
            reserveEnd.dateFrom,
            reserveEnd.minFrom + this.timeInterval,
            0,
            0
          );
        }
      } else if (this.timeInterval === 15) {
        dateFrom.setHours(reserveInit.dateFrom, reserveInit.minFrom, 0, 0);
        if (reserveInit.minFrom >= 45) {
          if (reserveInit.dateFrom === reserveEnd.dateFrom) {
            dateTo.setHours(reserveEnd.dateFrom + 1, 0, 0, 0);
          } else {
            dateTo.setHours(
              reserveEnd.dateFrom,
              reserveEnd.minFrom + this.timeInterval,
              0,
              0
            );
          }
        } else {
          dateTo.setHours(
            reserveEnd.dateFrom,
            reserveEnd.minFrom + this.timeInterval,
            0,
            0
          );
        }
      }
      if (this.edit) {
        this.submitEdit.emit({
          dateFrom: dateFrom.valueOf(),
          dateTo: dateTo.valueOf(),
        });
      } else {
        this.submitReserve.emit({
          dateFrom: dateFrom.valueOf(),
          dateTo: dateTo.valueOf(),
        });
      }
    } else {
      const title = this.messagesPipe.transform(
        'message_DeskNotSelected_Title--NotSelect'
      );
      const description = this.messagesPipe.transform(
        'message_DeskConsecutive_Description--NotSelect'
      );
      if (this.fullPage) {
        this.modalErrorMobileService.show(title, description);
      } else {
        this.flashMessagesService.show(description, {
          cssClass: 'alert-success',
          timeout: 3000,
        });
      }
    }
  }

  getClassScheduleDiv(
    dateIni: any,
    dateEnd: any,
    timeInterval?: any,
    minuteFrom?: any,
    minuteTo?: any
  ) {
    return this.utils.getClassScheduleDiv(
      this.hours,
      dateIni,
      dateEnd,
      timeInterval,
      minuteFrom,
      minuteTo,
      false
    );
  }

  getClassScheduleDivToday(timeInterval) {
    let openingHour = new Date();
    let date = new Date(+this.date as number);
    openingHour.setFullYear(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    openingHour.setHours(this.openHour, this.openMinute, 0);
    let nowHour = new Date();
    if (timeInterval === 60) {
      nowHour.setHours(nowHour.getHours() + 1, 0, 0);
    } else {
      nowHour.setHours(
        nowHour.getHours(),
        nowHour.getMinutes() + timeInterval,
        0
      );
    }
    if (
      date.getFullYear() > nowHour.getFullYear() ||
      date.getMonth() > nowHour.getMonth() ||
      date.getDate() > nowHour.getDate()
    ) {
      return null;
    } else if (
      date.getFullYear() < nowHour.getFullYear() ||
      date.getMonth() < nowHour.getMonth() ||
      date.getDate() < nowHour.getDate()
    ) {
      let fullDay = new Date();
      fullDay.setHours(this.openHour, this.closeHour, 0);
      return this.utils.getClassScheduleDiv(
        this.hours,
        openingHour,
        fullDay,
        timeInterval,
        null,
        null,
        false
      );
    } else {
      return this.utils.getClassScheduleDiv(
        this.hours,
        openingHour,
        nowHour,
        timeInterval,
        null,
        null,
        true
      );
    }
  }

  getClassScheduleDivHour(hourIni: any, hourMin: any, timeInterval: any) {
    return this.utils.getClassScheduleDivHour(
      this.hours,
      hourIni,
      hourMin,
      timeInterval
    );
  }

  setLanguage(language: string, titleKey: string) {
    let message = new MessagesPipe();
    if (language) {
      message.setLanguage(language);
    }

    this.reserve_title = message.transform(titleKey);
    this.reserve_toSelect = message.transform('reserve_toSelect');
    this.reserve_toOccupation = message.transform('reserve_toOccupation');
    this.reserve_selected = message.transform('reserve_selected');
    this.reserve_cancel = message.transform('reserve_cancel');
    this.reserve_reserve_all = message.transform('reserve_reserve_all');
    this.reserve_reserve = message.transform('reserve_reserve');
    this.reserve_edit = message.transform('reserve_edit');
  }

  getClassInterval(timeInterval: any) {
    const interval = timeInterval == 15 ? 'interval15' : '';
    return `hours ${interval}`;
  }

  scrollToFirstElement(element: HTMLElement) {
    setTimeout(() => {
      if (element) {
        const gantt = document.getElementById('calendar');
        gantt.scrollTop = this.getTopOffset(element);
      }
    }, 50);
  }

  getTopOffset(controlEl: HTMLElement): number {
    return controlEl.getBoundingClientRect().top + window.scrollY - 200;
  }
}
