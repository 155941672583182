<div class=" room-card fadeInLayer-delay"
     [ngClass]="{ 'differentBuilding' : currentUser.headquarters.id != room.headquartersId }">
  <div class="image" (click)="roomDetail()">
    <div class="image-wrapper" [ngClass]="{'bloqued': !room.enabled}">
      <img [src]="room.imageUrl" />
    </div>
  </div>
    <div class="button">
      <div class="info">
        <div class="name">
          <span>{{room.name}}</span>
          <div class="assistants" *ngIf="room.capacityMin && room.capacityMax">
            <i class="vdp-label-asistentes"></i><span>{{getAssistants()}}</span>
          </div>
        </div>
      </div>
      <i *ngIf="room.hasIncidence" class="vdp-salas-incidencia"></i>
      <div class="equipment" *ngIf="room.equipment != null && room.equipment.length > 0">
        <div *ngFor="let eq of room.equipment">
          <div class="equipment-item">
            <img [src]="eq.imageUrl" height="10px" width="10px" />
          </div>
        </div>
      </div>
      <button type="button" (click)="reserveRoom()" [disabled]="reservationBtnDisabled || !room.enabled">{{'room_available_btn' | messagesPipe}}</button>
    </div>
</div>
