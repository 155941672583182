import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class NavigatorRefreshService {
  menuRefreshProfile = new EventEmitter<string>();
  menuRefreshLink = new EventEmitter<string>();

  emitMenuRefreshProfileEvent(imageBase64: string) {
    this.menuRefreshProfile.emit(imageBase64);
  }

  getMenuRefreshProfileEmitter(): EventEmitter<string> {
    return this.menuRefreshProfile;
  }

  emitMenuRefreshLinkEvent(linkName: string) {
    this.menuRefreshLink.emit(linkName);
  }

  getMenuRefreshLinkEmitter(): EventEmitter<string> {
    return this.menuRefreshLink;
  }
}
