import { ErrorHandler, Injectable } from '@angular/core';
import { ClientAuthError } from 'msal';
import { Router } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private router: Router) {}

  handleError(error: Error) {
    if (error['rejection'] && error['rejection'] instanceof ClientAuthError) {
      if (error['rejection']['errorCode'] === 'token_renewal_error') {
        localStorage.clear();
        window.location.reload();
      } else {
        console.error(error);
      }
    } else if (
      error['errorCode'] === 'hash_does_not_contain_known_properties'
    ) {
      this.router.navigate(['/menu/home']);
    } else {
      console.log(error);
    }
  }
}
