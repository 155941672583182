<div class="main-container">
  <div class="fadeInLayer">
    <app-spinner></app-spinner>

    <div class="row">
      <div
        class="col-5 card"
        style="
          display: flex;
          flex-direction: initial;
          align-items: center;
          justify-content: center;
        "
      >
        <div class="row container-card">
          <div class="col-12 title">
            <h2>{{ 'security_search_available_places' | messagesPipe }}</h2>
          </div>
          <div
            class="card cardGroup col-4"
            *ngFor="let place of placesAvailables"
          >
            <div class="card-header">{{ place.name }}</div>
            <div class="card-body">
              <blockquote class="blockquote mb-0">
                <div
                  class="elem"
                  *ngFor="let parkingType of place.parkingTypeNumber"
                >
                  {{ parkingType.type }}: {{ parkingType.number }}
                </div>
              </blockquote>
            </div>
          </div>

          <div class="col-12 row-security">
            <button
              type="button"
              (click)="assignPlace()"
              class="check-availability"
            >
              <i class="vdp-icon-mas"></i>
              {{ 'security_assign_place_btn' | messagesPipe }}
            </button>
          </div>
        </div>
      </div>

      <div class="col-1"></div>

      <div class="col-5 card">
        <form
          class="mui-form"
          [formGroup]="securitySearchForm"
          novalidate
          (ngSubmit)="launchSearch()"
        >
          <!-- Search by -->
          <div class="col-12 title">
            <h2>{{ 'security_search_result_list' | messagesPipe }}</h2>
          </div>
          <div class="row-security mui-select">
            <label for="securitySearchSelectId">{{
              'security_search_title' | messagesPipe
            }}</label>
            <select
              id="securitySearchSelectId"
              formControlName="securitySearchSelect"
              (change)="securitySearchSelectChange($event)"
            >
              <option value="">
                {{ 'security_search_select' | messagesPipe }}
              </option>
              <option [value]="item.id" *ngFor="let item of optionList">
                {{ item.description | messagesPipe }}
              </option>
            </select>
            <span
              class="required"
              *ngIf="
                securitySearchForm.controls['securitySearchSelect'].invalid
              "
            >
              {{ 'error_required' | messagesPipe }}
            </span>
          </div>

          <!-- Value -->
          <div class="row-security mui-textfield border-on">
            <label for="searchByOptionId">{{
              'security_search_value' | messagesPipe
            }}</label>
            <input
              id="searchByOptionId"
              type="text"
              formControlName="searchByOption"
            />
            <span
              class="required"
              *ngIf="
                securitySearchForm.controls['searchByOption'].invalid &&
                securitySearchForm.controls['searchByOption'].touched
              "
            >
              {{ 'error_required' | messagesPipe }}
            </span>
          </div>

          <!-- Button -->
          <div class="row-security">
            <button
              type="submit"
              class="check-availability"
              [disabled]="securitySearchForm.invalid"
            >
              <i class="vdp-icon-buscar-sala"></i>
              {{ 'security_search_btn' | messagesPipe }}
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Results -->
    <div class="search-results" *ngIf="showResults">
      <h3>{{ 'security_search_results' | messagesPipe }}:</h3>

      <div class="table-responsive-sm">
        <table class="table table-hover">
          <thead class="table-secondary">
            <tr>
              <th>{{ 'security_search_name' | messagesPipe }}</th>
              <th>{{ 'security_search_email' | messagesPipe }}</th>
              <th>{{ 'security_search_code' | messagesPipe }}</th>
              <th>{{ 'security_search_plate' | messagesPipe }}</th>
              <th>{{ 'security_search_model' | messagesPipe }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let result of results">
              <td>{{ result.Nombre }}</td>
              <td>{{ result.DireccionCorreo }}</td>
              <td>{{ result.codigoPlaza }}</td>
              <td>{{ result.parking_coche1_matricula }}</td>
              <td>{{ result.parking_coche1_modelo }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<side-modal></side-modal>
