import { Pagination } from './pagination';
import { UserLdap } from './user.ldap';
import { RoomWs } from './room.ws';
import { EquipementWs } from './equipement.ws';
import { GenericWS } from './generic.ws';

export class WaitingListWs {
  id: number;
  dateTo: Date;
  dateFrom: Date;
  reason: string;
  assistants: number;
  room: RoomWs;
  equipment: EquipementWs[];
  dayfiltering: Date;
  reservationDuration: number;
}
