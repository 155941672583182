<div id="confirmationModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-info">
            <span class="title">{{title}}</span>
            <span class="message">{{message}}</span>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-default" id="cancelButton">{{cancelText}}</button>
              <button type="button" class="btn btn-default" id="okButton">{{okText}}</button>
          </div>
        </div>
    </div>
</div>

<!-- <div id="confirmationModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{title}}</h4>
            </div>
            <div class="modal-body">
                <p>{{message}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" id="cancelButton">{{cancelText}}</button>
                <button type="button" class="btn btn-default" id="okButton">{{okText}}</button>
            </div>
        </div>
    </div>
</div> -->
