import { Component, OnInit } from '@angular/core';
import { NavigatorRefreshService } from '../../shared/services/navigator.refresh.service';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { BaseService } from '../../shared/services/base.service';
import { FlashMessagesService } from '../../shared/services/flash-messages.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-parking-occupation-menu',
  templateUrl: './parking-occupation-menu.component.html',
  styleUrls: ['./parking-occupation-menu.component.scss']
})
export class ParkingOccupationMenuComponent implements OnInit {

  messagesPipe = new MessagesPipe();

  url: any = null;
  urlSafe: SafeResourceUrl;

  constructor(
    private navigatorRefreshService: NavigatorRefreshService,
    public sanitizer: DomSanitizer,
    private configurationService: ConfigurationService,
    private baseService: BaseService,
    private flashMessagesService: FlashMessagesService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      this.messagesPipe.transform('menu_parking')
    );

    this.getData();
  }

  getData() {
      this.spinnerService.show();
    this.configurationService.getData().pipe(
      finalize(() => {
        this.spinnerService.hide();
      })
    ).subscribe(
      (res) => {
        if (res) {
          this.url = res.parkingUrl;
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        }
      },
      (error) => {
        this.baseService.showErrorDialog(
          this.flashMessagesService,
          error,
          this.messagesPipe.transform('config_getData_text_error')
        );
      }
    );
  }
}
