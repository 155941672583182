import { Message } from './message';

export class MessageLocale {
  locale: string;
  messages: Message[];

  constructor(locale: string, messages: Message[]) {
    this.locale = locale;
    this.messages = messages;
  }
}
