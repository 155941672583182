import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { BuildingWs } from '../../core/models/building.ws';
import { DeskAttribute } from '../../core/models/deskAttribute';
import { HeadquartersWS } from '../../core/models/headquarters.ws';
import { UserLdapWs } from '../../core/models/user.ldap.ws';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';

@Injectable()
export class BuildingService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getBuildingList(token: string): Observable<BuildingWs[]> {
    let bodyString = JSON.stringify({
      headquarters: {
        id: this.authenticationService.getCurrentUser().headquarters.id,
      },
    });
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/building/list?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as BuildingWs[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  saveBuilding(token: string, building: BuildingWs): Observable<any> {
    let user = new UserLdapWs();
    user.building = building;
    let bodyString = JSON.stringify(user);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/user/update?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  findById(buildingList: BuildingWs[], id: number): BuildingWs {
    let buildingResult = buildingList.filter((building) => {
      return building.id == id;
    });

    if (buildingResult && buildingResult.length > 0) {
      return buildingResult[0];
    } else {
      return null;
    }
  }

  getBuildingListByHeadquarters(
    token: string,
    headquartersId: number
  ): Observable<BuildingWs[]> {
    var buildingWs = new BuildingWs(
      null,
      null,
      new HeadquartersWS(headquartersId, null)
    );

    let bodyString = JSON.stringify(buildingWs);
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/building/list?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as BuildingWs[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getBuildingListByHeadquartersAndAttribute(
    token: string,
    listAttributes?: DeskAttribute[]
  ): Observable<BuildingWs[]> {
    var buildingWs = {
      headquarters: {
        id: this.authenticationService.getCurrentUser().headquarters.id,
      },
      attributesId: listAttributes ? listAttributes : [],
    };

    let bodyString = JSON.stringify(buildingWs);
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/desks/buildings/list?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as BuildingWs[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
