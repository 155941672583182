import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import {
  environment, PARKING_DATA_API_PATH,
  SERVICE_BASE_URL
} from '../../../environments/environment';
import { LOCAL_CURRENT_USER, OPTIONS } from '../../core/constants/const';
import { PARKING_TYPE_FIJA, PARKING_TYPE_TEMPORAL } from '../../core/constants/consts';
import { DalkonValorDevuelto } from '../../core/models/dalkon.valor.devuelto';
import { MessagesPipe } from '../pipes/messages.pipe';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';

@Injectable()
export class DalkonService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  obtainData(token: string): Observable<DalkonValorDevuelto> {
    let bodyString = JSON.stringify({});

    if(environment.dalkon_token){
      let currentUser = JSON.parse(
        localStorage.getItem(LOCAL_CURRENT_USER)
      );
      bodyString = JSON.stringify({
        dalkon_token : ( currentUser.dalkon_token ? currentUser.dalkon_token : null )
      });
    }

    return this.http
      .post(
        `${SERVICE_BASE_URL}/${PARKING_DATA_API_PATH}?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        const parkingResponse = response.json();
        const parking = parkingResponse.dto ? parkingResponse.dto.Valor_Devuelto[0] : parkingResponse.Valor_Devuelto[0];
        const messagesPipe = new MessagesPipe();
        const hasPlazaCodigo =
          parking.Parking_plaza_Codigo &&
          parking.Parking_plaza_Codigo.length > 0;
        const hasPlazaReservadaCodigo =
          parking.Parking_plazaReservada_Codigo &&
          parking.Parking_plazaReservada_Codigo.length > 0;

        if (hasPlazaCodigo || hasPlazaReservadaCodigo) {
          if (parking.Plaza_Cedida == 'true') {
            parking.parkingTipoTitle = messagesPipe.transform('parking_ceded');
          } else {
            if (parking.Parking_plaza_Tipo === PARKING_TYPE_FIJA) {
              parking.parkingTipoTitle = messagesPipe.transform(
                'parking_resident'
              );
            } else if(parking.Parking_plaza_Tipo !== PARKING_TYPE_TEMPORAL) {
              parking.parkingTipoTitle = messagesPipe.transform('parking_lot');
            }
          }

          parking.parkingCodTitle = hasPlazaCodigo
            ? parking.Parking_plaza_Codigo
            : parking.Parking_plazaReservada_Codigo;
        } else {
          parking.parkingTipoTitle = messagesPipe.transform('no_parking');

          if (parking && !parking.Vehiculos || parking.Vehiculos.length <= 0) {
            parking.parkingCodTitle = messagesPipe.transform('no_vehicle');
          } else {
            parking.parkingCodTitle = '';
          }
        }
        return parking;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  private hasVehicle(vehicleId: string): boolean {
    return vehicleId && vehicleId.length > 0;
  }
}
