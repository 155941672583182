export const ReservationRoutes = {
  reservationNewDetail: {
    title: 'room_reserve_btn',
    routerName: 'reservationForm',
  },
  reservationNewDetailByRoom: {
    title: 'room_reserve_btn',
    routerName: 'reservationNewDetailByRoom',
  },
  reservationSearchByRoomAndPeriod: {
    title: 'search_reservations_periods_by_room',
    routerName: 'reservationSearchByRoomAndPeriod',
  },
  reservationNewFormByHQ: {
    title: 'room_reserve_btn',
    routerName: 'reservationNewFormByHQ',
  },
  reservationNewSpeed: {
    title: 'room_reserve_btn',
    routerName: 'reservationNewSpeed',
  },
};
