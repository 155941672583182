import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { Utils } from 'src/app/shared/utils/utils';
import { MessagesPipe } from '../../pipes/messages.pipe';
import { BoxOfficeService } from '../../services/box-office.service';
import { FlashMessagesService } from '../../services/flash-messages.service';
import { ModalErrorMobileService } from '../modal-error-mobile/modal-error-mobile.service';
import { SpinnerService } from '../spinner/spinner.service';
import { ModalReservationService } from './modal-reservation.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AreaService } from '../../../shared/services/area.service';

@Component({
  selector: 'app-modal-reservation',
  templateUrl: './modal-reservation.component.html',
  styleUrls: ['./modal-reservation.component.scss'],
})
export class ModalReservationComponent implements OnInit {
  @Output() addReservedOk = new EventEmitter();
  modalOpen: boolean = false;
  fullPage: boolean = false;
  showAllReservation: boolean = false;
  showReservation: boolean = false;
  edit: boolean = false;
  checkin: boolean = false;
  token: string;
  areaId: number;
  id: number;
  idReservation: number;
  modalConfig: any;
  date: any;
  hours: any = {
    data: [],
  };
  reservation: any;
  reservationFree: Array<any> = [];
  xMin: number;
  messagesPipe = new MessagesPipe();
  timeOutApiHor: any;
  timeOutApiAddReservation: any;
  timeOutApiEditReservation: any;
  isWebVersion = false;
  backCancelOk = false;

  reserveDesk_title: string;
  reserveDesk_busy: string;
  reservation_by: string;
  reserveDesk_toSelect: string;
  reserveDesk_selected: string;
  reserveDesk_cancel: string;
  reserveDesk_reserve_all: string;
  reserveDesk_reserve: string;
  reserveDesk_edit: string;
  reservation_busy_by: string;
  url: String;
  selectOptionMenu: string;
  isDinner = false;

  constructor(
    private modalReservationService: ModalReservationService,
    private utils: Utils,
    private boxOfficeService: BoxOfficeService,
    private modalErrorMobileService: ModalErrorMobileService,
    private spinnerService: SpinnerService,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private route: ActivatedRoute,
    private areaService: AreaService
  ) {
    this.modalReservationService.openModal = this.openModal.bind(this);
    this.modalReservationService.closeModal = this.closeModal.bind(this);
    this.modalReservationService.setLanguage = this.setLanguage.bind(this);
  }

  ngOnInit() {
    this.reservation = null;
    this.reservationFree = [];
    this.hours = this.utils.getReservationsHours();
    this.modalConfig = document.getElementById('modalConfig');
    this.checkAppVersion();
    this.url = this.route.snapshot['_routerState'].url as String;
    this.selectOptionMenu =
      this.url.includes('/dinning-position') || this.url.includes('-dinner/')
        ? 'dinner'
        : 'desks';
    this.isDinner = this.url.includes('/dinning-position') || this.url.includes('-dinner/') ? true : false;
  }

  openModal(
    id: number,
    date: number,
    areaId: number,
    token: string,
    fullPage?: boolean,
    checkin?: boolean,
    idReservation?: number
  ) {
    this.spinnerService.show();
    if (checkin) {
      this.checkin = checkin;
    } else {
      this.checkin = false;
    }
    if (idReservation) {
      this.idReservation = idReservation;
      this.edit = true;
    } else {
      this.idReservation = null;
      this.edit = false;
    }
    this.reservation = null;
    this.reservationFree = [];
    if (id && date && token) {
      this.id = id;
      this.date = date;
      this.areaId = areaId;
      this.token = token;
      this.getHourReservationDesk(id, date, token);
    }
    if (!this.modalOpen) {
      this.modalConfig.style.display = 'flex';
      window.setTimeout(() => {
        this.modalConfig.style.opacity = 1;
        this.modalOpen = true;
      }, 100);
    }
    if (fullPage != null && fullPage == true && !this.isWebVersion) {
      this.fullPage = true;
    } else {
      this.fullPage = false;
    }
  }

  closeModal() {
    if (this.modalOpen) {
      this.modalConfig.style.opacity = 0;
      window.setTimeout(() => {
        this.modalOpen = false;
        this.modalConfig.style.display = 'none';
      }, 100);

      if (this.isWebVersion && this.backCancelOk) {
        const optionBack = this.url.includes('/dinning-position')
          ? 'dinning-position'
          : 'job-position';
        this.router.navigate([`/menu/${optionBack}`]);
      }
    }
  }

  getHourReservationDesk(id: number, date: number, token: string) {
    setTimeout(() => {
      this.spinnerService.show();
    }, 0);
    let subsBoxOffice = this.boxOfficeService
      .hourReservationDesk(id, date, token, this.selectOptionMenu)
      .subscribe(
        (res: any) => {
          if (res) {
            this.reservation = res;
            this.getInterval();
          }
          clearTimeout(this.timeOutApiHor);
          this.spinnerService.hide();
        },
        (error) => {
          if (error && error.code) {
            this.dipastchEventError(error.code);
          }
          this.spinnerService.hide();
          clearTimeout(this.timeOutApiHor);
        }
      );
    this.timeOutApiHor = setTimeout(() => {
      subsBoxOffice.unsubscribe();
      this.dipastchEventError(408);
      this.spinnerService.hide();
    }, 10000);
  }

  createReservationFree() {
    this.reservationFree = [];
    for (
      let hour = this.reservation.openingHour;
      hour <= this.reservation.closingHour;
      hour++
    ) {
      let maxHour = 60;
      let min = 0;

      if(this.reservation.openingHour == hour){
        min = this.reservation.openingMinute;
      }
      if(this.reservation.closingHour == hour){
        maxHour = this.reservation.closingMinute;
      }

      for (min; min < maxHour; min += this.xMin) {
        let occupied = false;
        let edit = false;
        this.reservation.reservations.forEach((item) => {
          if (item.hourFrom === item.hourTo) {
            if (
              hour === item.hourFrom &&
              min >= item.minuteFrom &&
              min < item.minuteTo &&
              !occupied
            ) {
              occupied = true;
              if (this.edit && item.id == this.idReservation) {
                edit = true;
              }
            }
          } else {
            if (
              (hour === item.hourFrom && min >= item.minuteFrom) ||
              (hour === item.hourTo && min < item.minuteTo + 1 && !occupied)
            ) {
              occupied = true;
              if (this.edit && item.id == this.idReservation) {
                edit = true;
              }
            } else {
              if (
                hour != item.hourFrom &&
                hour != item.hourTo &&
                hour > item.hourFrom &&
                hour < item.hourTo &&
                !occupied
              ) {
                occupied = true;
                if (this.edit && item.id == this.idReservation) {
                  edit = true;
                }
              }
            }
          }
        });
        if (!occupied && !edit) {
          this.reservationFree.push({
            dateFrom: hour,
            minFrom: min,
            active: false,
          });
        } else if (occupied && edit) {
          this.reservationFree.push({
            dateFrom: hour,
            minFrom: min,
            active: true,
          });
        }
      }
    }
    this.loadReservationDeskAll();
    this.loadReservation();
    window.setTimeout(() => {
      this.scrollToFirstElement(document.getElementById('desk-0'));
    }, 0);
  }

  scrollToFirstElement(element: HTMLElement) {
    setTimeout(() => {
      if (element) {
        const gantt = document.getElementById('calendar');
        gantt.scrollTop = this.getTopOffset(element);
      }
    }, 50);
  }

  getTopOffset(controlEl: HTMLElement): number {
    return controlEl.getBoundingClientRect().top + window.scrollY - 200;
  }

  includeReserver(reservation) {
    let preSelect = this.reservationFree.findIndex(
      (x) => x.dateFrom == reservation.dateFrom && x.minFrom == reservation.minFrom
    );

    if (reservation.active) {
      let countOverReservation = this.reservationFree.filter(
        (desk) =>
          (desk.dateFrom < reservation.dateFrom ||
            (desk.dateFrom === reservation.dateFrom &&
              desk.minFrom < reservation.minFrom)) &&
          desk.active
      ).length;
      let countUnderReservation = this.reservationFree.filter(
        (desk) =>
          (desk.dateFrom > reservation.dateFrom ||
            (desk.dateFrom === reservation.dateFrom &&
              desk.minFrom > reservation.minFrom)) &&
          desk.active
      ).length;
      if (this.edit) {
        const reservationEdit = this.reservation.reservations.find(
          (x) => x.id == this.idReservation
        );
        if (countOverReservation < 1 && reservationEdit.status.id !== 4) {
          for (let index = preSelect; index >= 0; index--) {
            if (
              this.reservationFree[index] &&
              this.reservationFree[index].active
            ) {
              this.reservationFree[index].active = false;
            }
          }
        } else {
          for (
            let index = preSelect;
            index <= this.reservationFree.length;
            index++
          ) {
            if (
              this.reservationFree[index] &&
              this.reservationFree[index].active
            ) {
              this.reservationFree[index].active = false;
            }
          }
        }
      } else if (
        this.reservationFree.filter((desk) => desk.active).length <= 2
      ) {
        reservation.active = false;
      } else if (this.checkin) {
        for (
          let index = preSelect;
          index <= this.reservationFree.length;
          index++
        ) {
          if (
            this.reservationFree[index] &&
            this.reservationFree[index].active
          ) {
            this.reservationFree[index].active = false;
          }
        }
      } else if (countOverReservation < countUnderReservation) {
        for (let index = preSelect; index >= 0; index--) {
          if (
            this.reservationFree[index] &&
            this.reservationFree[index].active
          ) {
            this.reservationFree[index].active = false;
          }
        }
      } else if (countOverReservation >= countUnderReservation) {
        for (
          let index = preSelect;
          index <= this.reservationFree.length;
          index++
        ) {
          if (
            this.reservationFree[index] &&
            this.reservationFree[index].active
          ) {
            this.reservationFree[index].active = false;
          }
        }
      }
    } else if (!this.reservationFree.find((x) => x.active)) {
      reservation.active = true;
    } else if (preSelect >= 0) {
      if (
        (this.reservationFree[preSelect - 1] &&
          this.reservationFree[preSelect - 1].active) ||
        (this.reservationFree[preSelect + 1] &&
          this.reservationFree[preSelect + 1].active)
      ) {
        if (this.selectOptionMenu == 'dinner' && !this.checkin) {
          if (preSelect > 0 && this.reservationFree[preSelect - 1].active) {
            this.reservationFree[preSelect - 1].active = false;
          } else if (this.reservationFree[preSelect + 1].active) {
            this.reservationFree[preSelect + 1].active = false;
          }
          reservation.active = true;
        } else if(this.selectOptionMenu != 'dinner'){
          reservation.active = true;
        } else {
          const title = this.messagesPipe.transform(
            'message_DeskNotSelected_Title'
          );
          const description = this.messagesPipe.transform(
            'message_DeskNot_Description'
          );
          this.modalErrorMobileService.show(title, description);
        }
      } else {
        if (this.selectOptionMenu == 'dinner' && !this.checkin) {
          this.reservationFree.forEach((element) => {
            if (element.active) {
              element.active = false;
            }
          });
          reservation.active = true;
        } else {
          const title = this.messagesPipe.transform(
            'message_DeskNotSelected_Title'
          );
          const description = this.messagesPipe.transform('message_DeskConsecutive_Description');
          this.modalErrorMobileService.show(title, description);
        }
      }
    }
  }

  loadReservation() {
    if (this.reservationFree.length <= 0) {
      this.showReservation = false;
    } else {
      const now = new Date();
      const date = new Date(+this.date as number);
      date.setHours(0, 0, 0);
      now.setHours(0, 0, 0);
      if (
        date.getDate() == now.getDate() &&
        date.getMonth() == now.getMonth() &&
        date.getFullYear() == now.getFullYear()
      ) {
        const nowDate = new Date();
        if (this.checkin) {
          if (this.xMin === 60) {
            nowDate.setHours(nowDate.getHours(), 0, 0);
          } else {
            nowDate.setHours(nowDate.getHours(), nowDate.getMinutes(), 0);
          }
        } else {
          if (this.xMin === 60) {
            nowDate.setHours(nowDate.getHours() + 1, 0, 0);
          } else {
            nowDate.setHours(nowDate.getHours(), nowDate.getMinutes(), 0);
          }
        }
        let firstSelect = false;
        this.reservationFree.forEach((reservation) => {
          const dateReservation = new Date();
          dateReservation.setHours(
            reservation.dateFrom,
            reservation.minFrom,
            0
          );
          if (this.checkin) {
            if (
              this.xMin == 60 &&
              dateReservation.getHours() == nowDate.getHours()
            ) {
              this.showReservation = true;
              if (!reservation.active && !firstSelect) {
                reservation.active = true;
                firstSelect = true;
              }
            } else if (
              dateReservation.getHours() > nowDate.getHours() ||
              (dateReservation.getHours() == nowDate.getHours() &&
                dateReservation.getMinutes() < nowDate.getMinutes() &&
                nowDate.getMinutes() >= dateReservation.getMinutes() &&
                nowDate.getMinutes() < dateReservation.getMinutes() + this.xMin)
            ) {
              this.showReservation = true;
              if (!reservation.active && !firstSelect) {
                reservation.active = true;
                firstSelect = true;
              }
            }
          } else {
            if (
              dateReservation.getHours() > nowDate.getHours() ||
              (dateReservation.getHours() == nowDate.getHours() &&
                dateReservation.getMinutes() >= nowDate.getMinutes())
            ) {
              this.showReservation = true;
            }
          }
        });
      } else if (
        date.getDate() > now.getDate() ||
        date.getMonth() > now.getMonth() ||
        date.getFullYear() > now.getFullYear()
      ) {
        this.reservationFree.forEach((reservation) => {
          this.showReservation = true;
        });
      } else {
        this.showReservation = false;
      }
    }
  }

  loadReservationDeskAll() {
    const now = new Date();
    const date = new Date(+this.date as number);
    date.setHours(0, 0, 0);
    now.setHours(0, 0, 0);
    if (
      date.getDate() > now.getDate() ||
      date.getMonth() > now.getMonth() ||
      date.getFullYear() > now.getFullYear()
    ) {
      if (this.reservation && this.reservation.reservations.length == 0) {
        this.showAllReservation = true;
      } else {
        this.showAllReservation = false;
      }
    } else if (
      date.getDate() == now.getDate() &&
      date.getMonth() == now.getMonth() &&
      date.getFullYear() == now.getFullYear()
    ) {
      let nowHour = new Date();
      if (this.checkin) {
        if (this.xMin == 60) {
          nowHour.setHours(nowHour.getHours(), 0, 0);
        } else {
          nowHour.setHours(nowHour.getHours(), nowHour.getMinutes(), 0);
        }
      } else {
        if (this.xMin == 60) {
          nowHour.setHours(nowHour.getHours() + 1, 0, 0);
        } else {
          nowHour.setHours(
            nowHour.getHours(),
            nowHour.getMinutes() + this.xMin,
            0
          );
        }
      }
      if (
        this.reservation.reservations.find(
          (x) => x.hourTo + 1 > nowHour.getHours()
        )
      ) {
        this.showAllReservation = false;
      } else {
        this.showAllReservation = true;
      }
    } else {
      this.showAllReservation = false;
    }
  }

  addReservationDeskAll() {
    const now = new Date();
    const date = new Date(+this.date as number);
    date.setHours(0, 0, 0);
    now.setHours(0, 0, 0);
    if (
      date.getDate() == now.getDate() &&
      date.getMonth() == now.getMonth() &&
      date.getFullYear() == now.getFullYear()
    ) {
      const nowDate = new Date();
      if (this.checkin) {
        if(this.xMin === 60){
          nowDate.setHours(nowDate.getHours(), 0, 0);
        } else {
          nowDate.setHours(nowDate.getHours(), nowDate.getMinutes(), 0);
        }
      } else {
        if(this.xMin === 60){
          nowDate.setHours(nowDate.getHours() + 1, 0, 0);
        } else {
          nowDate.setHours(nowDate.getHours(), nowDate.getMinutes(), 0);
        }
      }
      this.reservationFree.forEach((reservation) => {
        const dateReservation = new Date();
        dateReservation.setHours(reservation.dateFrom, reservation.minFrom, 0);
        if (
          this.xMin == 60 &&
          dateReservation.getHours() >= nowDate.getHours()
        ) {
          reservation.active = true;
        } else {
          if (dateReservation.getHours() > nowDate.getHours()) {
            reservation.active = true;
          } else if (
            dateReservation.getHours() == nowDate.getHours() &&
            dateReservation.getMinutes() > nowDate.getMinutes()
          ) {
            reservation.active = true;
          }
        }
      });
    } else if (
      date.getDate() > now.getDate() ||
      date.getMonth() > now.getMonth() ||
      date.getFullYear() > now.getFullYear()
    ) {
      this.reservationFree.forEach((reservation) => {
        reservation.active = true;
      });
    }
  }

  addReservationDesk() {
    let deskInit = this.reservationFree.filter((desk) => desk.active).shift();
    if (deskInit) {
      this.spinnerService.show();
      let deskEnd = this.reservationFree.filter((desk) => desk.active).pop();
      let dateFrom = new Date(+this.date as number);
      let dateTo = new Date(+this.date as number);
      if (this.xMin === 60) {
        dateFrom.setHours(deskInit.dateFrom, 0, 0, 0);
        dateTo.setHours(deskEnd.dateFrom + 1, 0, 0, 0);
      } else if (this.xMin === 30) {
        dateFrom.setHours(deskInit.dateFrom, deskInit.minFrom, 0, 0);
        if (deskInit.minFrom >= 30) {
          if (deskInit.dateFrom === deskEnd.dateFrom) {
            dateTo.setHours(deskEnd.dateFrom + 1, 0, 0, 0);
          } else {
            dateTo.setHours(
              deskEnd.dateFrom,
              deskEnd.minFrom + this.xMin,
              0,
              0
            );
          }
        } else {
          dateTo.setHours(deskEnd.dateFrom, deskEnd.minFrom + this.xMin, 0, 0);
        }
      } else if (this.xMin === 15) {
        dateFrom.setHours(deskInit.dateFrom, deskInit.minFrom, 0, 0);
        if (deskInit.minFrom >= 45) {
          if (deskInit.dateFrom === deskEnd.dateFrom) {
            dateTo.setHours(deskEnd.dateFrom + 1, 0, 0, 0);
          } else {
            dateTo.setHours(
              deskEnd.dateFrom,
              deskEnd.minFrom + this.xMin,
              0,
              0
            );
          }
        } else {
          dateTo.setHours(deskEnd.dateFrom, deskEnd.minFrom + this.xMin, 0, 0);
        }
      }
      let subsBoxOffice = this.boxOfficeService
        .addReservationDesk(
          this.reservation.deskId,
          dateFrom.valueOf(),
          dateTo.valueOf(),
          this.checkin,
          this.token,
          this.selectOptionMenu
        )
        .subscribe(
          (res) => {
            if (res && res.ok) {
              this.getHourReservationDesk(this.id, this.date, this.token);
              if (!this.fullPage) {
                this.addReservedOk.emit();
                this.closeModal();
              }

              this.dipastchEventReservation(true);

              if (this.isWebVersion) {
                this.flashMessagesService.show(
                  this.messagesPipe.transform('reservation_desk_success'),
                  { cssClass: 'alert-success', timeout: 3000 }
                );

                this.router.navigate([
                  'menu',
                  this.url.includes('/dinning-position/')
                    ? 'dinning-position'
                    : 'job-position'
                ]);
              }
            } else {
              this.dipastchEventReservation(false);

              if (this.isWebVersion) {
                this.flashMessagesService.show(
                  this.messagesPipe.transform('reservation_desk_error'),
                  { cssClass: 'alert-error', timeout: 3000 }
                );
              }
            }
            this.spinnerService.hide();
            clearTimeout(this.timeOutApiAddReservation);
          },
          (error) => {
            if (error && error.code) {
              this.dipastchEventError(error.code);

              this.closeModal();

              if (this.isWebVersion) {
                this.flashMessagesService.show(
                  this.messagesPipe.transform('reservation_not_available'),
                  { cssClass: 'alert-error', timeout: 3000 }
                );
              }
            }
            this.spinnerService.hide();
            clearTimeout(this.timeOutApiAddReservation);
          }
        );
      this.timeOutApiAddReservation = setTimeout(() => {
        subsBoxOffice.unsubscribe();
        this.dipastchEventError(408);
        this.spinnerService.hide();
      }, 10000);
    } else {
      const title = this.messagesPipe.transform(
        'message_DeskNotSelected_Title--NotSelect'
      );
      const description = this.messagesPipe.transform(
        'message_DeskConsecutive_Description--NotSelect'
      );
      this.modalErrorMobileService.show(title, description);
    }
  }

  editReservationDesk() {
    let deskInit = this.reservationFree.filter((desk) => desk.active).shift();
    if (deskInit) {
      this.spinnerService.show();
      let deskEnd = this.reservationFree.filter((desk) => desk.active).pop();
      let dateFrom = new Date(+this.date as number);
      let dateTo = new Date(+this.date as number);
      if (this.xMin === 60) {
        dateFrom.setHours(deskInit.dateFrom, 0, 0, 0);
        dateTo.setHours(deskEnd.dateFrom + 1, 0, 0, 0);
      } else if (this.xMin === 30) {
        dateFrom.setHours(deskInit.dateFrom, deskInit.minFrom, 0, 0);
        if (deskInit.minFrom >= 30) {
          if (deskInit.dateFrom === deskEnd.dateFrom) {
            dateTo.setHours(deskEnd.dateFrom + 1, 0, 0, 0);
          } else {
            dateTo.setHours(
              deskEnd.dateFrom,
              deskEnd.minFrom + this.xMin,
              0,
              0
            );
          }
        } else {
          dateTo.setHours(deskEnd.dateFrom, deskEnd.minFrom + this.xMin, 0, 0);
        }
      } else if (this.xMin === 15) {
        dateFrom.setHours(deskInit.dateFrom, deskInit.minFrom, 0, 0);
        if (deskInit.minFrom >= 45) {
          if (deskInit.dateFrom === deskEnd.dateFrom) {
            dateTo.setHours(deskEnd.dateFrom + 1, 0, 0, 0);
          } else {
            dateTo.setHours(
              deskEnd.dateFrom,
              deskEnd.minFrom + this.xMin,
              0,
              0
            );
          }
        } else {
          dateTo.setHours(deskEnd.dateFrom, deskEnd.minFrom + this.xMin, 0, 0);
        }
      }
      let subsBoxOffice = this.boxOfficeService
        .editReservationDesk(
          this.idReservation,
          dateFrom.valueOf(),
          dateTo.valueOf(),
          this.token,
          this.selectOptionMenu
        )
        .subscribe(
          (res) => {
            if (res && res.ok) {
              this.getHourReservationDesk(this.id, this.date, this.token);
              if (!this.fullPage) {
                this.addReservedOk.emit();
                this.closeModal();
              }
              this.dipastchEventReservation(true);

              if (this.isWebVersion) {
                this.flashMessagesService.show(
                  this.messagesPipe.transform('reservation_edit_success'),
                  { cssClass: 'alert-success', timeout: 3000 }
                );

                this.router.navigate([
                  'menu',
                  this.url.includes('/dinning-position/')
                    ? 'dinning-position'
                    : 'job-position'
                ]);
              }
            } else {
              this.dipastchEventReservation(false);

              if (this.isWebVersion) {
                this.flashMessagesService.show(
                  this.messagesPipe.transform('reservation_edit_error'),
                  { cssClass: 'alert-error', timeout: 3000 }
                );
              }
            }
            this.spinnerService.hide();
            clearTimeout(this.timeOutApiEditReservation);
          },
          (error) => {
            if (error && error.code) {
              this.dipastchEventError(error.code);

              this.closeModal();

              if (this.isWebVersion) {
                this.flashMessagesService.show(
                  this.messagesPipe.transform('reservation_not_available'),
                  { cssClass: 'alert-error', timeout: 3000 }
                );
              }
            }
            this.spinnerService.hide();
            clearTimeout(this.timeOutApiEditReservation);
          }
        );
      this.timeOutApiEditReservation = setTimeout(() => {
        subsBoxOffice.unsubscribe();
        this.dipastchEventError(408);
        this.spinnerService.hide();
      }, 10000);
    } else {
      const title = this.messagesPipe.transform(
        'message_DeskNotSelected_Title--NotSelect'
      );
      const description = this.messagesPipe.transform(
        'message_DeskConsecutive_Description--NotSelect'
      );
      this.modalErrorMobileService.show(title, description);
    }
  }

  dipastchEventReservation(status: boolean) {
    var ua = navigator.userAgent;
    var checker = {
      iphone: ua.match(/(iPhone|iPod|iPad)/),
      android: ua.match(/Android/),
    };
    if (checker.android) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
      deskReserve.statusReserve(${status});
      `);
      x.appendChild(t);
      document.body.appendChild(x);
    } else if (checker.iphone) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
      window.webkit.messageHandlers.statusReserve.postMessage(${status});
      `);
      x.appendChild(t);
      document.body.appendChild(x);
    }
  }

  dipastchEventError(codeError: number) {
    var ua = navigator.userAgent;
    var checker = {
      iphone: ua.match(/(iPhone|iPod|iPad)/),
      android: ua.match(/Android/),
    };
    if (checker.android) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
      deskReserve.errorReserve(${codeError});
      `);
      x.appendChild(t);
      document.body.appendChild(x);
    } else if (checker.iphone) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
      window.webkit.messageHandlers.errorReserve.postMessage(${codeError});
      `);
      x.appendChild(t);
      document.body.appendChild(x);
    }
  }

  getClassScheduleDiv(
    dateIni: any,
    dateEnd: any,
    xMin?: any,
    minuteFrom?: any,
    minuteTo?: any
  ) {
    return this.utils.getClassScheduleDiv(
      this.hours,
      dateIni,
      dateEnd,
      xMin,
      minuteFrom,
      minuteTo,
      false
    );
  }

  getClassScheduleDivToday(xMin) {
    let openingHour = new Date();
    let date = new Date(+this.date as number);
    openingHour.setFullYear(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    openingHour.setHours(
      this.reservation.openingHour,
      this.reservation.openingMinute,
      0
    );
    let nowHour = new Date();
    if (this.checkin) {
      if (xMin === 60) {
        nowHour.setHours(nowHour.getHours() + 1, 0, 0);
      } else {
        nowHour.setHours(nowHour.getHours(), nowHour.getMinutes() + xMin, 0);
      }
    } else {
      if (xMin === 60) {
        nowHour.setHours(nowHour.getHours() + 1, 0, 0);
      } else {
        nowHour.setHours(nowHour.getHours(), nowHour.getMinutes() + xMin, 0);
      }
    }
    if (
      date.getFullYear() > nowHour.getFullYear() ||
      date.getMonth() > nowHour.getMonth() ||
      date.getDate() > nowHour.getDate()
    ) {
      return null;
    } else if (
      date.getFullYear() < nowHour.getFullYear() ||
      date.getMonth() < nowHour.getMonth() ||
      date.getDate() < nowHour.getDate()
    ) {
      let fullDay = new Date();
      fullDay.setHours(
        this.reservation.closingHour,
        this.reservation.closingMinute,
        0
      );
      return this.utils.getClassScheduleDiv(
        this.hours,
        openingHour,
        fullDay,
        xMin,
        null,
        null,
        false
      );
    } else {
      return this.utils.getClassScheduleDiv(
        this.hours,
        openingHour,
        nowHour,
        xMin,
        null,
        null,
        true
      );
    }
  }

  getClassScheduleDivHour(hourIni: any, hourMin: any, xMin: any) {
    return this.utils.getClassScheduleDivHour(
      this.hours,
      hourIni,
      hourMin,
      xMin
    );
  }

  checkAppVersion(): void {
    const currentUrl = this.router.url;
    this.isWebVersion =
      currentUrl.includes('select-position') ||
      currentUrl.includes('view-position') ||
      currentUrl.includes('viewMultiple-position') ||
      currentUrl.includes('selectView-position') ||
      currentUrl.includes('edit-position');
    this.backCancelOk = currentUrl.includes('edit-position');
  }

  setLanguage(language: string) {
    // Language modification for the mobility part
    let message = new MessagesPipe();
    if (language) {
      message.setLanguage(language);
    }

    this.reserveDesk_title = this.url.includes('/dinning-position') || this.url.includes('-dinner')
      ? message.transform('reserveDeskDinning_title')
      : message.transform('reserveDesk_title');
    this.reserveDesk_busy = message.transform('reserveDesk_busy');
    this.reservation_by = message.transform('reservation_by');
    this.reserveDesk_toSelect = message.transform('reserveDesk_toSelect');
    this.reserveDesk_selected = message.transform('reserveDesk_selected');
    this.reserveDesk_cancel = message.transform('reserveDesk_cancel');
    this.reserveDesk_reserve_all = message.transform('reserveDesk_reserve_all');
    this.reserveDesk_reserve = message.transform('reserveDesk_reserve');
    this.reserveDesk_edit = message.transform('reserveDesk_edit');
    this.reservation_busy_by = message.transform('reservation_busy_by');
  }

  getInterval() {
    if (this.searchInterval()) {
        this.areaService
        .getIntervalByAreaId(this.areaId, this.token)
        .subscribe((res) => {
          if (res) {
            this.xMin = parseInt(res.toString());
          } else {
            this.xMin = 60;
          }
          this.createReservationFree();
        });
    } else {
      this.xMin = 60;
      this.createReservationFree();
    }
  }

  getClassInterval(xMin: any) {
    const interval = xMin == 15 ? 'interval15' : '';
    return `hours ${interval}`;
  }

  searchInterval(): boolean {
    return environment['searchInterval'];
  }
}
