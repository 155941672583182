<div class="search-people">
  <!-- Description text -->
  <label>{{
    translationKey ? (translationKey | messagesPipe) : ('reservation_add_people' | messagesPipe)
  }}</label>

  <!-- Autocomplete  -->
  <div class="ng-autocomplete">
    <ng-autocomplete
      #usersAutocompleteComponent
      [data]="users"
      [disabled]="disabled"
      [searchKeyword]="usersKeyword"
      (selected)="onUserSelected($event)"
      (inputChanged)="onSearchChanged($event)"
      [itemTemplate]="itemTemplate"
      [notFoundTemplate]="notFoundTemplate"
      [debounceTime]="600"
      [isLoading]="isLoadingUsers"
      [minQueryLength]="2"
      placeHolder="{{  placeHolderkey ? (placeHolderkey | messagesPipe) : ('user_to_send_invite' | messagesPipe) }}"
    >
    </ng-autocomplete>

    <ng-template #itemTemplate let-item>
      <a href="javascript:void(0)" class="invitation-users-item">
        <span class="invitation-users-info">
          <span *ngIf="item?.tipo!=-1" [innerHTML]="item?.name"></span>
          <span *ngIf="item?.tipo==-1" [innerHTML]="'profile_department' | messagesPipe"></span>
          <span
            class="invitation-users-email"
            *ngIf="item?.email != null && item?.email.length > 0"
          >
            ({{ item.email }})</span
          >
        </span>
      </a>
    </ng-template>

    <ng-template #notFoundTemplate let-notFound>
      {{ 'invitation_user_not_found' | messagesPipe }}
    </ng-template>
  </div>
</div>

<!-- User list -->
<ul class="invitation-users" *ngIf="selectedUsers.length > 0">
  <li
    class="invitation-users-item"
    *ngFor="let invitationSelectedUser of selectedUsers"
  >
    <span class="invitation-users-info">
      <span class="invitation-users-name"
        >{{
        invitationSelectedUser.tipo!=-1?invitationSelectedUser.name:'profile_department'
        | messagesPipe }}</span
      >
      <span
        class="invitation-users-email"
        *ngIf="
            invitationSelectedUser.email != null &&
            invitationSelectedUser.email.length > 0
          "
      >
        ({{ invitationSelectedUser.email }})</span
      >
    </span>
    <button (click)="removeUser(invitationSelectedUser)">
      <i class="vdp-icon-cancelar"></i>
    </button>
  </li>
</ul>
