import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalAcceptDialogService {
  constructor() {}

  openModal: (assistanceInfo: any) => void;
  closeModal: () => void;
}