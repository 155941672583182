<div class="main-container">
  <div class="fadeInLayer downloads">
    <div class="app-wrapper">
      <div class="row">
        <div class="app-card">
          <div class="logo">
            <i class="vdp-android"></i>
          </div>
          <div class="download">
            <span>{{ 'dowloads_android_title' | messagesPipe }}</span>
            <p>{{ 'dowloads_android_desc' | messagesPipe }}</p>
            <img
              *ngIf="
                ('../../assets/img/QR_Android.png' | imageExist | async) == true
              "
              class="qr"
              src="../../assets/img/QR_Android.png"
            />
            <a
              *ngIf="
                ('../../assets/img/QR_Android.png' | imageExist | async) != true
              "
              [href]="androidUrl"
              >{{ 'dowloads_btn' | messagesPipe }}</a
            >
          </div>
        </div>
        <div class="app-card">
          <div class="logo">
            <i class="vdp-ios"></i>
          </div>
          <div class="download">
            <span>{{ 'dowloads_ios_title' | messagesPipe }}</span>
            <p>{{ 'dowloads_ios_desc' | messagesPipe }}</p>
            <img
              *ngIf="
                ('../../assets/img/QR_Ios.png' | imageExist | async) == true
              "
              class="qr"
              src="../../assets/img/QR_Ios.png"
              alt=""
            />
            <a
              *ngIf="
                ('../../assets/img/QR_Ios.png' | imageExist | async) != true
              "
              [href]="iosUrl"
              >{{ 'dowloads_btn' | messagesPipe }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
