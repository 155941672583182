import { Component, OnInit } from '@angular/core';
import { AttendanceService } from '../../shared/services/attendance.service';
import { STATUS_FORBIDDEN } from 'src/app/core/constants/const';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { FlashMessagesService } from '../../shared/services/flash-messages.service';
import { LOGOUT_TIMEOUT } from '../../core/constants/consts';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { finalize } from 'rxjs/operators';
import { CalendarService } from '../../shared/components/calendar/calendar.service';

@Component({
  selector: 'app-assistance-user',
  templateUrl: './assistance-user.component.html',
  styleUrls: ['./assistance-user.component.scss'],
})
export class AssistanceUserComponent implements OnInit {
  listAttendancesUser: any;
  mode = 'ASSISTANCE-USER';
  messagesPipe = new MessagesPipe();


  constructor(
    private attendanceService: AttendanceService,
    private authenticationService: AuthenticationService,
    private flashMessagesService: FlashMessagesService,
    private calendarService: CalendarService
  ) {}

  ngOnInit() {
    this.getAttendancesUsers();
  }

  getAttendancesUsers() {
    this.attendanceService.getListAttendancesUserManager().subscribe(
      (res) => {
        if (res) {
          this.listAttendancesUser = res;
          this.calendarService.inicio();
        }
      },
      (error) => {
        this.handleNetworkError(
          error,
          'assistance_user_manager_search_error',
          () => {
            this.getAttendancesUsers();
          }
        );
      }
    );
  }

  private handleNetworkError(
    error: any,
    errorMessage: string,
    callback: Function
  ): void {
    if (error.code === STATUS_FORBIDDEN) {
      this.authenticationService.refreshToken().subscribe(
        (response) => {
          callback();
        },
        () => {
          this.authenticationService.validateSessionId().subscribe(
            (response) => {
              callback();
            },
            (error) => {
              this.flashMessagesService.grayOut(false);
              this.flashMessagesService.show(
                this.messagesPipe.transform('error_forbidden'),
                { cssClass: 'alert-danger', timeout: 3000 }
              );

              setTimeout(() => {
                this.authenticationService.logout();
              }, LOGOUT_TIMEOUT);
            }
          );
        }
      );
    } else {
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show(
        this.messagesPipe.transform(errorMessage),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
    }
  }
}
