import { Injectable } from '@angular/core';
import { HORAS_ROOM_DETAIL } from '../../core/constants/consts';
import { ReservationWs } from '../../core/models/reservation.ws';
import { RoomWs } from '../../core/models/room.ws';
import { MyDatePicker } from '../components/date_picker/datepicker.component';
import { DateStringPipe } from '../pipes/date.string.pipe';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class Utils {
  padLeft(num: number, size: number): string {
    let s = num + '';

    while (s.length < size) {
      s = '0' + s;
    }

    return s;
  }

  getDatepickerOptions(dateMax?: Date, from?: Date): any {
    const actualDate = from ? from : new Date();

    if (!from) {
      actualDate.setDate(actualDate.getDate() - 1);
    }

    if (dateMax) {
      dateMax.setDate(dateMax.getDate() + 1);
    }

    const month = actualDate.getMonth() + 1;
    const maxMonth = dateMax.getMonth() + 1;

    return {
      inline: false,
      editableDateField: false,
      inputValueRequired: true,
      dateFormat: 'dd-mm-yyyy',
      firstDayOfWeek: 'mo',
      height: '34px',
      width: '260px',
      selectionTxtFontSize: '16px',
      minYear: actualDate.getFullYear(),
      disableUntil: {
        year: actualDate.getFullYear(),
        month: month,
        day: actualDate.getDate(),
      },
      disableSince: {
        year: dateMax.getFullYear(),
        month: maxMonth,
        day: dateMax.getDate(),
      },
    };
  }

  getDatepickerOptionsOnlyFrom(from?: Date): any {
    const actualDate = from ? from : new Date();

    if (!from) {
      actualDate.setDate(actualDate.getDate() - 1);
    }

    const month = actualDate.getMonth() + 1;

    return {
      inline: false,
      editableDateField: false,
      inputValueRequired: true,
      dateFormat: 'dd-mm-yyyy',
      firstDayOfWeek: 'mo',
      height: '34px',
      width: '260px',
      selectionTxtFontSize: '16px',
      minYear: actualDate.getFullYear(),
      disableUntil: {
        year: actualDate.getFullYear(),
        month: month,
        day: actualDate.getDate(),
      },
    };
  }

  setDatepickerOptions(): any {
    const actualDate = new Date();

    return {
      inline: false,
      editableDateField: false,
      inputValueRequired: true,
      firstDayOfWeek: 'mo',
      minYear: actualDate.getFullYear(),
      disableUntil: {
        year: actualDate.getFullYear(),
        month: actualDate.getMonth() + 1,
        day: actualDate.getDate(),
      },
    };
  }

  setDatepickerOptionsRequest(): any {
    const actualDate = new Date();

    actualDate.setDate(actualDate.getDate() - 1);

    return {
      inline: false,
      editableDateField: false,
      inputValueRequired: true,
      firstDayOfWeek: 'mo',
      minYear: actualDate.getFullYear(),
      disableUntil: {
        year: actualDate.getFullYear(),
        month: actualDate.getMonth() + 1,
        day: actualDate.getDate(),
      },
    };
  }

  selectHoursSetPositionFrom(
    authenticationService: AuthenticationService,
    hoursFrom: string[],
    minutesFrom: string[],
    selectHourFrom: any,
    selectMinuteFrom: any
  ): string {
    let hourS;
    let minuteS;
    let positionHour;
    let positionMinute;
    const actualDate = new Date();
    const minSelectHour = authenticationService.getMinReservationTime();
    const dateStringPipe = new DateStringPipe();
    const minuteNum = parseInt(
      dateStringPipe.transform(actualDate.toString(), 'mm'),
      0
    );

    minutesFrom.some((minute, index) => {
      if (+minute != 0) {
        if (minuteNum > +minutesFrom[index - 1] && minuteNum < +minute) {
          actualDate.setMinutes(+minute);

          return true;
        } else if (minuteNum > +minutesFrom[minutesFrom.length - 1]) {
          actualDate.setHours(actualDate.getHours() + 1);
          actualDate.setMinutes(0);
          return true;
        }
      }
    });

    hourS = dateStringPipe.transform(actualDate.toString(), 'HH');
    minuteS = dateStringPipe.transform(actualDate.toString(), 'mm');

    if (minSelectHour != null) {
      if (actualDate >= minSelectHour) {
        positionHour = hoursFrom.indexOf(hourS);
        positionMinute = minutesFrom.indexOf(minuteS);
      } else {
        positionHour = hoursFrom.indexOf(
          dateStringPipe.transform(minSelectHour.toString(), 'HH')
        );
        positionMinute = minutesFrom.indexOf(
          dateStringPipe.transform(minSelectHour.toString(), 'mm')
        );
      }
    } else {
      positionHour = hoursFrom.indexOf(hourS);
      positionMinute = minutesFrom.indexOf(minuteS);
    }

    selectHourFrom.nativeElement.selectedIndex = positionHour;
    selectMinuteFrom.nativeElement.selectedIndex = positionMinute;

    return `${
      selectHourFrom.nativeElement.options[
        selectHourFrom.nativeElement.selectedIndex
      ].value
    }:${
      selectMinuteFrom.nativeElement.options[
        selectMinuteFrom.nativeElement.selectedIndex
      ].value
    }`;
  }

  selectHoursSetPositionTo(
    reservationDateFrom: string,
    selectHourTo: any,
    selectMinuteTo: any,
    selectHourFrom: any
  ): string {
    const minutesFrom = parseInt(reservationDateFrom.split(':')[1], 0);

    if (minutesFrom === 0) {
      selectHourTo.nativeElement.selectedIndex =
        selectHourFrom.nativeElement.selectedIndex;
      selectMinuteTo.nativeElement.selectedIndex = 2;
    } else if (minutesFrom === 15) {
      selectHourTo.nativeElement.selectedIndex =
        selectHourFrom.nativeElement.selectedIndex;
      selectMinuteTo.nativeElement.selectedIndex = 3;
    } else if (minutesFrom === 30) {
      if (
        selectHourTo.nativeElement.options.length >
        selectHourFrom.nativeElement.selectedIndex + 1
      ) {
        selectHourTo.nativeElement.selectedIndex =
          selectHourFrom.nativeElement.selectedIndex + 1;
        selectMinuteTo.nativeElement.selectedIndex = 0;
      } else {
        selectHourTo.nativeElement.selectedIndex =
          selectHourFrom.nativeElement.selectedIndex;
        selectMinuteTo.nativeElement.selectedIndex = 3;
      }
    } else {
      if (
        selectHourTo.nativeElement.options.length >
        selectHourFrom.nativeElement.selectedIndex + 1
      ) {
        selectHourTo.nativeElement.selectedIndex =
          selectHourFrom.nativeElement.selectedIndex + 1;
        selectMinuteTo.nativeElement.selectedIndex = 1;
      } else {
        selectHourTo.nativeElement.selectedIndex =
          selectHourFrom.nativeElement.selectedIndex;
        selectMinuteTo.nativeElement.selectedIndex = 3;
      }
    }

    return `${
      selectHourTo.nativeElement.options[
        selectHourTo.nativeElement.selectedIndex
      ].value
    }:${
      selectMinuteTo.nativeElement.options[
        selectMinuteTo.nativeElement.selectedIndex
      ].value
    }`;
  }

  setDatePickerDate(date: Date, datePickerElement: MyDatePicker) {
    const dateAux = new Date(date.getTime());
    const month = dateAux.getMonth() + 1;

    datePickerElement.clearDate();
    datePickerElement.selectDate({
      day: dateAux.getDate(),
      month: month,
      year: dateAux.getFullYear(),
    });
  }

  getHoursBetweenDates(dateMin: Date, dateMax: Date): number {
    const diffMs = Math.abs(dateMax.getTime() - dateMin.getTime());
    const hours = Math.floor((diffMs % 86400000) / 3600000);

    return hours;
  }

  getMinutesBetweenDates(dateMin: Date, dateMax: Date): number {
    const diffMs = Math.abs(dateMax.getTime() - dateMin.getTime());
    const minutes = Math.round(((diffMs % 86400000) % 3600000) / 60000);

    return minutes;
  }

  setDateBooking(date: Date): void {
    const dateStringPipe = new DateStringPipe();
    const minS = parseInt(dateStringPipe.transform(date.toString(), 'mm'), 0);

    if (minS > 0 && minS <= 15) {
      date.setMinutes(15);
    } else if (minS > 15 && minS <= 30) {
      date.setMinutes(30);
    } else if (minS > 30 && minS <= 45) {
      date.setMinutes(45);
    } else if (minS > 45) {
      date.setMinutes(0);
      date.setHours(date.getHours() + 1);
    }
  }

  compareDates(date1: any, date2: any): number {
    let date1D;
    let date2D;

    if (typeof date1 === 'number') {
      date1D = new Date(date1 as number);
    } else {
      date1D = new Date();
      date1D.setTime(date1.getTime());
    }

    if (typeof date2 === 'number') {
      date2D = new Date(date2 as number);
    } else {
      date2D = new Date();
      date2D.setTime(date2.getTime());
    }

    date1D.setSeconds(0, 0);
    date2D.setSeconds(0, 0);

    if (date1D > date2D) {
      return 1;
    } else if (date1D < date2D) {
      return -1;
    } else {
      return 0;
    }
  }

  compareDatesSecondsInclude(date1: any, date2: any): number {
    let date1D;
    let date2D;

    if (typeof date1 === 'number') {
      date1D = new Date(date1 as number);
    } else {
      date1D = new Date();
      date1D.setTime(date1.getTime());
    }

    if (typeof date2 === 'number') {
      date2D = new Date(date2 as number);
    } else {
      date2D = new Date();
      date2D.setTime(date2.getTime());
    }

    date1D.setMilliseconds(0);
    date2D.setMilliseconds(0);

    if (date1D > date2D) {
      return 1;
    } else if (date1D < date2D) {
      return -1;
    } else {
      return 0;
    }
  }

  compareDatesWithoutTime(date1: any, date2: any): number {
    let date1D;
    let date2D;

    if (typeof date1 === 'number') {
      date1D = new Date(date1 as number);
    } else {
      date1D = new Date();
      date1D.setTime(date1.getTime());
    }

    if (typeof date2 === 'number') {
      date2D = new Date(date2 as number);
    } else {
      date2D = new Date();
      date2D.setTime(date2.getTime());
    }

    date1D.setHours(0, 0, 0, 0);
    date2D.setHours(0, 0, 0, 0);

    if (date1D > date2D) {
      return 1;
    } else if (date1D < date2D) {
      return -1;
    } else {
      return 0;
    }
  }

  getMaximunDateOfDay(now: Date): Date {
    const maxDate = new Date(now.getTime());

    maxDate.setMinutes(0);
    maxDate.setSeconds(0);
    maxDate.setHours(23);
    maxDate.setMinutes(45);

    return maxDate;
  }

  getMinimunDateOfDay(now: Date): Date {
    const minDate = new Date(now.getTime());

    minDate.setMinutes(0);
    minDate.setSeconds(0);
    minDate.setHours(0);

    return minDate;
  }

  getReservationsHours(): string[] {
    const hours = [];

    for (const hourComplete of HORAS_ROOM_DETAIL) {
      const hour = hourComplete.split(':');
      hours.push(`- ${hourComplete}`);
      hours.push('-');
      hours.push(`- ${hour[0]}:30`);
      hours.push('-');
    }

    return hours;
  }

  getSpeedReservationsHours(): string[] {
    const hours = [];

    for (const hour of HORAS_ROOM_DETAIL) {
      hours.push(`- ${hour}`);
      hours.push('-');
      hours.push('-');
      hours.push('-');
      hours.push('-');
      hours.push('-');
    }

    return hours;
  }

  getClassScheduleDiv(
    hours: string[],
    dateIni: any,
    dateEnd: any,
    xMin: any,
    minuteFrom: any,
    minuteTo: any,
    bToday: boolean
  ) {
    if (typeof dateIni === 'number') {
      dateIni = new Date(dateIni as number);
      dateIni.setHours(dateIni.getHours(), minuteFrom, 0, 0);
    }

    if (typeof dateEnd === 'number') {
      dateEnd = new Date(dateEnd as number);
      dateEnd.setHours(dateEnd.getHours(), minuteTo, 0, 0);
    }

    const intervalClass = xMin == 15 ? 'interval15' : '';

    if (bToday) {
      return `card ${intervalClass} start-${this.getPosHourToday(
        hours,
        dateIni,
        xMin
      )} end-${this.getPosHourToday(hours, dateEnd, xMin)}`;
    } else {
      return `card ${intervalClass} start-${this.getPosHour(
        hours,
        dateIni
      )} end-${this.getPosHour(hours, dateEnd)}`;
    }
  }

  getPosHour(hours: string[], date: Date): number {
    const dateStringPipe = new DateStringPipe();
    let hourS = dateStringPipe.transform(date.toString(), 'HH');
    hourS = hourS.concat(':00');
    const minS = parseInt(dateStringPipe.transform(date.toString(), 'mm'), 0);
    let position = hours.indexOf(`- ${hourS}`);

    if (minS >= 0 && minS < 14) {
      position = position + 1;
    } else if (minS >= 14 && minS < 29) {
      position = position + 2;
    } else if (minS >= 29 && minS < 44) {
      position = position + 3;
    } else if (minS == 44 || minS == 45) {
      position = position + 4;
    } else if (minS > 45 && minS <= 60) {
      position = position + 5;
    }
    return position;
  }

  getPosHourToday(hours: string[], date: Date, xMin: number): number {
    const dateStringPipe = new DateStringPipe();
    let hourS = dateStringPipe.transform(date.toString(), 'HH');
    hourS = hourS.concat(':00');
    const minS = parseInt(dateStringPipe.transform(date.toString(), 'mm'), 0);
    let position = hours.indexOf(`- ${hourS}`);

    if (xMin == 15) {
      if (minS >= 0 && minS < 15) {
        position = position + 1;
      } else if (minS >= 15 && minS < 30) {
        position = position + 2;
      } else if (minS >= 30 && minS < 45) {
        position = position + 3;
      } else if (minS >= 45 && minS < 60) {
        position = position + 4;
      }
    } else if (xMin == 30) {
      if (minS >= 0 && minS < 30) {
        position = position + 1;
      } else if (minS >= 30) {
        position = position + 3;
      }
    } else {
      position = position + 1;
    }

    return position;
  }

  getClassScheduleDivHour(hours: string[], hourIni: any, min: any, xMin: any) {
    const intervalClass = xMin == 15 ? 'interval15' : '';
    return `card ${intervalClass} start-${this.getPosOneHour(
      hours,
      hourIni,
      min
    )} end-${this.getPosOneHour(hours, hourIni, min + xMin)}`;
  }

  getPosOneHour(hours: string[], hour: number, min): number {
    if (min >= 60) {
      hour++;
      min = 0;
    }

    let position = null;
    if (hour < 10) {
      position = hours.indexOf(`- 0${hour}:00`);
    } else {
      position = hours.indexOf(`- ${hour}:00`);
    }

    if (min === 0) {
      position = position + 1;
    } else if (min === 15) {
      position = position + 2;
    } else if (min === 30) {
      position = position + 3;
    } else if (min === 45) {
      position = position + 4;
    }

    return position;
  }

  addMinimunHour(date: Date) {
    const dateStringPipe = new DateStringPipe();
    const minS = parseInt(dateStringPipe.transform(date.toString(), 'mm'), 0);

    if (minS === 0) {
      date.setHours(date.getHours() - 1);
      date.setMinutes(45);
    } else if (minS === 15) {
      date.setMinutes(0);
    } else if (minS === 30) {
      date.setMinutes(15);
    } else if (minS === 45) {
      date.setMinutes(30);
    }

    date.setSeconds(0);
  }

  addMaximunHour(date: Date) {
    const dateStringPipe = new DateStringPipe();
    const minS = parseInt(dateStringPipe.transform(date.toString(), 'mm'), 0);

    if (minS === 0) {
      date.setMinutes(15);
    } else if (minS === 15) {
      date.setMinutes(30);
    } else if (minS === 30) {
      date.setMinutes(45);
    } else if (minS === 45) {
      date.setMinutes(0);
      date.setHours(date.getHours() + 1);
    }

    date.setSeconds(0);
  }

  getSpeedClassScheduleDiv(hours: string[], dateIni: any, dateEnd: any) {
    if (typeof dateIni === 'number') {
      dateIni = new Date(dateIni as number);
    }

    if (typeof dateEnd === 'number') {
      dateEnd = new Date(dateEnd as number);
    }

    return `card start-${this.getSpeedPosHour(
      hours,
      dateIni
    )} end-${this.getSpeedPosHour(hours, dateEnd)}`;
  }

  getSpeedPosHour(hours: string[], date: Date): number {
    const dateStringPipe = new DateStringPipe();
    let hourS = dateStringPipe.transform(date.toString(), 'HH');
    hourS = hourS.concat(':00');
    const minS = parseInt(dateStringPipe.transform(date.toString(), 'mm'), 0);

    let position = hours.indexOf(`- ${hourS}`);
    position = position + 1;

    if (minS > 0 && minS <= 10) {
      position = position + 1;
    } else if (minS > 10 && minS <= 20) {
      position = position + 2;
    } else if (minS > 20 && minS <= 30) {
      position = position + 3;
    } else if (minS > 30 && minS <= 40) {
      position = position + 4;
    } else if (minS > 40 && minS <= 50) {
      position = position + 5;
    }

    return position;
  }

  addSpeedMinimunHour(date: Date) {
    const dateStringPipe = new DateStringPipe();
    const minS = parseInt(dateStringPipe.transform(date.toString(), 'mm'), 0);

    if (minS === 0) {
      date.setHours(date.getHours() - 1);
      date.setMinutes(50);
    } else if (minS === 10) {
      date.setMinutes(0);
    } else if (minS === 20) {
      date.setMinutes(10);
    } else if (minS === 30) {
      date.setMinutes(20);
    } else if (minS === 40) {
      date.setMinutes(30);
    } else if (minS === 50) {
      date.setMinutes(40);
    }

    date.setSeconds(0);
  }

  addSpeedMaximunHour(date: Date) {
    const dateStringPipe = new DateStringPipe();
    const minS = parseInt(dateStringPipe.transform(date.toString(), 'mm'), 0);

    if (minS === 0) {
      date.setMinutes(10);
    } else if (minS === 10) {
      date.setMinutes(20);
    } else if (minS === 20) {
      date.setMinutes(30);
    } else if (minS === 30) {
      date.setMinutes(40);
    } else if (minS === 40) {
      date.setMinutes(50);
    } else if (minS === 50) {
      date.setMinutes(0);
      date.setHours(date.getHours() + 1);
    }

    date.setSeconds(0);
  }

  canChangeDates(dateMin: Date, dateMax: Date, minMunutes: number): boolean {
    let minutes;
    const hours = this.getHoursBetweenDates(dateMin, dateMax);

    if (hours > 0) {
      return true;
    } else {
      minutes = this.getMinutesBetweenDates(dateMin, dateMax);

      if (minutes > minMunutes) {
        return true;
      } else {
        return false;
      }
    }
  }

  canChangeDateFrom(dateFrom: Date, room: RoomWs, openDate: Date): boolean {
    let reservations;
    let lastReservation;
    let lastReservationDate;
    let minimunDateRes;

    // Set actual date to an correct interval
    this.setDateBooking(room.serverDate);

    if (openDate) {
      openDate = this.getOpeningTimeWithReservationDate(openDate, dateFrom);

      if (this.compareDates(room.serverDate, openDate) >= 0) {
        minimunDateRes = room.serverDate;
      } else {
        minimunDateRes = openDate;
      }
    } else {
      minimunDateRes = room.serverDate;
    }

    const minimunDate = this.getMinimunDateOfDay(dateFrom);

    if (
      this.compareDates(dateFrom, minimunDateRes) > 0 &&
      this.compareDates(dateFrom, minimunDate) > 0
    ) {
      reservations = room.reservations.filter((reservation) => {
        return this.compareDates(reservation.dateTo, dateFrom) < 1;
      });

      if (reservations.length > 0) {
        reservations = reservations.sort(
          (reservationA: ReservationWs, reservationB: ReservationWs) => {
            const dateA = new Date(reservationA.dateTo);
            const dateB = new Date(reservationB.dateTo);

            if (dateA < dateB) {
              return -1;
            } else if (dateA > dateB) {
              return 1;
            } else {
              return 0;
            }
          }
        );

        lastReservation = reservations[reservations.length - 1];
        lastReservationDate = new Date(lastReservation.dateTo);

        if (this.compareDates(dateFrom, lastReservationDate) > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  canChangeDateTo(dateTo: Date, room: RoomWs): boolean {
    let reservations;
    let lastReservation;
    let lastReservationDate;
    const maxDate = this.getMaximunDateOfDay(dateTo);

    if (this.compareDates(dateTo, maxDate) < 0) {
      reservations = room.reservations.filter((reservation) => {
        return this.compareDates(reservation.dateFrom, dateTo) >= 0;
      });

      if (reservations.length > 0) {
        reservations = reservations.sort(
          (reservationA: ReservationWs, reservationB: ReservationWs) => {
            const dateA = new Date(reservationA.dateTo);
            const dateB = new Date(reservationB.dateTo);

            if (dateA < dateB) {
              return -1;
            } else if (dateA > dateB) {
              return 1;
            } else {
              return 0;
            }
          }
        );

        lastReservation = reservations[0];
        lastReservationDate = new Date(lastReservation.dateFrom);

        if (this.compareDates(dateTo, lastReservationDate) < 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  getExpireDate(expireIn: number): Date {
    const date = new Date();
    expireIn = expireIn - 5;
    date.setSeconds(date.getSeconds() + expireIn);

    return date;
  }

  getOpeningTimeWithReservationDate(openingTime: Date, dateFrom: Date) {
    const dateAux = new Date();
    dateAux.setTime(dateFrom.getTime());
    dateAux.setHours(openingTime.getHours(), openingTime.getMinutes(), 0, 0);

    return dateAux;
  }

  applyDateMask(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return year + month + day;
  }
}
