import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import { UserLdapWs } from 'src/app/core/models/user.ldap.ws';
import { environment } from '../../../../environments/environment';
import { STATUS_FORBIDDEN } from '../../../core/constants/const';
import {
  LOGOUT_TIMEOUT,
  RESERVATION_STATE_CHECKED,
  RESERVATION_STATUS,
} from '../../../core/constants/consts';
import { ReservationWs } from '../../../core/models/reservation.ws';
import { CustomDatePipe } from '../../../shared/pipes/custom.date.pipe';
import { DateStringPipe } from '../../../shared/pipes/date.string.pipe';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { BaseService } from '../../../shared/services/base.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { ReservationService } from '../../../shared/services/reservation.service';

@Component({
  selector: 'app-reservation-card',
  templateUrl: 'reservation.card.component.html',
  styleUrls: ['reservation-card.scss'],
})
export class ReservationCardComponent implements OnInit {
  @Input() reservationWs: ReservationWs;
  @Input() viewCard: boolean;
  @Output() eventDetail = new EventEmitter<ReservationWs>();
  @Output() eventCancel = new EventEmitter<ReservationWs>();
  @Output() eventInvitation = new EventEmitter<ReservationWs>();
  messagesPipe: MessagesPipe;
  btnSendDisabled: Boolean;
  currentUser: UserLdapWs;
  permissions: MenuPermissions;

  constructor(
    private reservationService: ReservationService,
    private authenticationService: AuthenticationService,
    private flashMessagesService: FlashMessagesService,
    private baseService: BaseService
  ) {
    this.messagesPipe = new MessagesPipe();
    this.btnSendDisabled = false;
  }

  ngOnInit() {
    this.currentUser = this.authenticationService.getCurrentUser();
    this.permissions =
      this.authenticationService.getPermissionsForCurrentUser();
  }

  getRoomBuildingName(): string {
    if (
      this.reservationWs.room.floor &&
      this.reservationWs.room.floor.building
    ) {
      return `${this.reservationWs.room.name}, ${this.reservationWs.room.floor.building.name}`;
    } else {
      return this.reservationWs.room.name;
    }
  }

  getRoomHeadquartersName(): string {
    if (
      this.reservationWs.room.floor &&
      this.reservationWs.room.floor.building &&
      this.reservationWs.room.floor.building.headquarters
    ) {
      return `${this.reservationWs.room.floor.building.headquarters.name}`;
    }
  }

  getAssistants(): string {
    return `${this.reservationWs.assistants}/${this.reservationWs.room.capacityMax}`;
  }

  getListViewDate(): string {
    const dateStringPipe = new DateStringPipe();
    const customDatePipe = new CustomDatePipe();

    return this.messagesPipe.transform('reservation_listView_date', [
      dateStringPipe.transform(this.reservationWs.dateFrom, 'DD'),
      customDatePipe.transform(this.reservationWs.dateFrom, true),
      dateStringPipe.transform(this.reservationWs.dateFrom, 'HH:mm'),
      dateStringPipe.transform(this.reservationWs.dateTo, 'HH:mm'),
    ]);
  }

  showDetail() {
    this.eventDetail.emit(this.reservationWs);
  }

  cancelReservation() {
    this.eventCancel.emit(this.reservationWs);
  }

  showInvitationPopup() {
    this.eventInvitation.emit(this.reservationWs);
  }

  getIsChecked(): boolean {
    if (
      this.reservationWs.status != null &&
      this.reservationWs.status.id != null
    ) {
      if (RESERVATION_STATE_CHECKED === this.reservationWs.status.id) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  inProgress(): boolean {
    return (
      this.reservationWs.inProgress && !this.reservationPendingConfirmation()
    );
  }

  reservationPendingConfirmation(): boolean {
    return (
      this.reservationWs.status.id === RESERVATION_STATUS.PENDING_CONFIRMATION
    );
  }

  sendToMail(): void {
    this.btnSendDisabled = true;

    this.reservationService
      .sendToMail(this.reservationWs, this.authenticationService.getToken())
      .subscribe(
        (response) => {
          this.btnSendDisabled = false;
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform('send_reservation_mail_success'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
        },
        (error) => {
          if (error.code === STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.sendToMail();
              },
              () => {
                this.flashMessagesService.grayOut(false);
                this.flashMessagesService.show(
                  this.messagesPipe.transform('error_forbidden'),
                  { cssClass: 'alert-danger', timeout: 3000 }
                );

                setTimeout(() => {
                  this.authenticationService.logout();
                }, LOGOUT_TIMEOUT);
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('send_reservation_mail_error')
            );
            this.btnSendDisabled = false;
          }
        }
      );
  }

  get hiddenInviteReservationRoom(): boolean {
    return environment['hiddenInviteReservationRoom'];
  }
}
