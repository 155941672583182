import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LOCAL_CURRENT_USER } from '../../core/constants/const';
import { MessagesPipe } from '../pipes/messages.pipe';
import { FlashMessagesService } from '../services/flash-messages.service';
import { UserService } from '../services/user.service';
import { Utils } from '../utils/utils';

@Component({
  template: '<div></div>'
})
export class OauthComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private utils: Utils,
    private userService: UserService,
    private flashMessagesService: FlashMessagesService
  ) {}

  ngOnInit(): void {
    let expireDate;
    let token;
    let refresh;
    let messagesPipe = new MessagesPipe();

    this.route.params.forEach((params: Params) => {
      if (params['token'] && params['expire_in'] && params['refresh']) {
        expireDate = this.utils.getExpireDate(params['expire_in']);
        token = params['token'];
        refresh = params['refresh'];
      }
    });

    if (token && expireDate && refresh) {
      this.userService.getUserDetail(token).subscribe(
        response => {
          response.accessToken = token;
          response.expireIn = expireDate;
          response.refreshToken = refresh;

          localStorage.setItem(LOCAL_CURRENT_USER, JSON.stringify(response));

          if (
            !response.name ||
            (response.name && response.name.length == 0) ||
            (!response.email || (response.email && response.email.length == 0))
          ) {
            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(
              messagesPipe.transform('user_emailOrName_empty'),
              { cssClass: 'alert-danger', timeout: 7000 }
            );
          }

          this.router.navigate(['/menu/home']);
        },
        error => {
          this.router.navigate(['/login']);
        }
      );
    } else {
      this.router.navigate(['/login']);
    }
  }
}
