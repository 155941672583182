import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalErrorMobileService {

  constructor() { }

  show: (title: string, description: string) => void;
  hide: () => void;
}
