<div class="modal" id="modalConfig" #modalConfig>
  <div class="modal__content" [ngClass]="{ 'modal-fullPage': fullPage }">
    <div class="modal__content--title" *ngIf="!fullPage">
      <span>{{ reserve_title }}</span>
    </div>
    <div class="modal__content--timeLine" id="calendar">
      <div class="calendar" *ngIf="true">
        <ul [ngClass]="getClassInterval(timeInterval)">
          <li *ngFor="let hour of hours">{{ hour }}</li>
        </ul>
        <div
          class="disabled"
          [ngClass]="getClassScheduleDivToday(timeInterval)"
        ></div>
        <div *ngFor="let reservation of reservationFree; let i = index">
          <div
            *ngIf="!reservation?.occupied"
            id="interval-{{ i }}"
            class="free"
            [ngClass]="[
              getClassScheduleDivHour(
                reservation.dateFrom,
                reservation.minFrom,
                timeInterval
              ),
              reservation?.active ? 'active' : ''
            ]"
            (click)="includeReserver(reservation)"
          >
            <div class="message">
              <span *ngIf="!reservation?.active">{{ reserve_toSelect }}</span>
              <span *ngIf="reservation?.active">{{ reserve_selected }}</span>
              <span class="vdp-icon-mas"></span>
            </div>
          </div>
          <div
            *ngIf="reservation?.occupied"
            id="interval-{{ i }}"
            class="free"
            [ngClass]="[
              getClassScheduleDivHour(
                reservation.dateFrom,
                reservation.minFrom,
                timeInterval
              ),
              'occupied'
            ]"
          >
            <div class="message">
              <span>{{ reserve_toOccupation }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__content--bottom">
      <a (click)="closeModal()" *ngIf="!fullPage">{{ reserve_cancel }}</a>
      <a
        class="reserve"
        *ngIf="showReservation && showAllReservation"
        (click)="addReservationAll()"
        >{{ reserve_reserve_all }}</a
      >
      <a class="reserve" *ngIf="showReservation" (click)="addReservation()">{{
        edit ? reserve_edit : reserve_reserve
      }}</a>
    </div>
  </div>
</div>
