import { Injectable } from '@angular/core';
import { NAME } from 'src/environments/environment';
const dataLog = require('../../../../logStatusApp.json');

@Injectable()
export class BuildLogService {
  constructor() {}

  logBuildApp() {
    if (dataLog) {
      console.log(
        `[${new Date(
          dataLog.dateBuild
        ).toDateString()}] SMARTBUILDING ${NAME} V${dataLog.version}  `
      );
    }
  }
}
