import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { FlashMessagesService } from './flash-messages.service';
import {
  STATUS_SERVER_ERROR,
  STATUS_CONFLICT,
  STATUS_FORBIDDEN,
  STATUS_INCORRECT_DATA,
  STATUS_POSITION_OCCUPIED,
  STATUS_ERROR_EXTERNAL,
  STATUS_NOT_ACCEPTABLE
} from '../../core/constants/const';
import { MessagesPipe } from '../pipes/messages.pipe';
import { LOGOUT_TIMEOUT } from '../../core/constants/consts';
import { GenericErrorsWs } from '../../core/models/generic.errors.ws';

@Injectable()
export class BaseService {
  constructor() {}

  handleError(
    authenticationService: AuthenticationService,
    error: Response | any
  ) {
    if (error instanceof Response) {
      if (error.status == STATUS_SERVER_ERROR) {
        return Observable.throw({
          code: STATUS_SERVER_ERROR,
          message: 'Server error'
        });
      } else if (error.status == STATUS_CONFLICT) {
        let body = error.json() as GenericErrorsWs;
        return Observable.throw({
          code: STATUS_CONFLICT,
          message: body.errors ? body.errors[0].message : body,
        });
      } else if(error.status == STATUS_INCORRECT_DATA){
        return Observable.throw({
          code: STATUS_INCORRECT_DATA,
          message: 'Incorrect data'
        });
      }else if(error.status == STATUS_POSITION_OCCUPIED){
        return Observable.throw({
          code: STATUS_POSITION_OCCUPIED,
          message: 'Position occupied or reserved'
        });
      } else if (error.status === STATUS_ERROR_EXTERNAL){
        return Observable.throw({
          code: STATUS_ERROR_EXTERNAL,
          message: 'External error'
        });
      } else if (error.status === STATUS_NOT_ACCEPTABLE){
        return Observable.throw({
          code: STATUS_NOT_ACCEPTABLE,
          message: 'Not Acceptable'
        });
      } else if (authenticationService.isTokenExpire()) {
        return Observable.throw({
          code: STATUS_FORBIDDEN,
          message: ''
        });
      }
    }

    return Observable.throw({
      code: 0
    });
  }

  showErrorDialog(
    flashMessagesService: FlashMessagesService,
    error: any,
    errorMessage: string
  ): void {
    if (error.code == STATUS_CONFLICT) {
      flashMessagesService.grayOut(false);
      flashMessagesService.show(error.message, {
        cssClass: 'alert-danger',
        timeout: 3000
      });
    } else {
      flashMessagesService.grayOut(false);
      flashMessagesService.show(errorMessage, {
        cssClass: 'alert-danger',
        timeout: 3000
      });
    }
  }
}
