<div class="login">
  <div class="login-wrapper">
    <div class="login-form">
      
        <img src="../../assets/img/logo-login.png" alt="" />
        
        <div class="errorLogin" *ngIf="errorLogin.length > 0">
          <span>{{ errorLogin }}</span>
        </div>
      
        <button class="btn waves-effect waves-light" routerLink="/login">login</button>
    </div>
  </div>
</div>



