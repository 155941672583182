import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { AvailablePeriodsInput } from 'src/app/core/models/available.periods.input';
import { ReservationPeriodsPaged } from 'src/app/core/models/reservation.periods.paged';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { DetailWs } from '../../core/models/detail.ws';
import { ReservationEdit } from '../../core/models/reservation.edit';
import { ReservationResponseWs } from '../../core/models/reservation.response.ws';
import { ReservationSpeedLimitsResponseWs } from '../../core/models/reservation.speed.ws';
import { ReservationTypesWS } from '../../core/models/reservation.types.ws';
import { ReservationWaitingWS } from '../../core/models/reservation.waiting.ws';
import { ReservationWs } from '../../core/models/reservation.ws';
import { RoomListWs } from '../../core/models/room.list.ws';
import { RoomWs } from '../../core/models/room.ws';
import { WaitingListWs } from '../../core/models/waiting.list.ws';
import { Utils } from '../utils/utils';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';

@Injectable()
export class ReservationService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService,
    private utils: Utils
  ) {}

  reservationList(
    token: string,
    dayfiltering?: Date
  ): Observable<ReservationWaitingWS> {
    let filter = new WaitingListWs();

    if (dayfiltering) {
      filter.dayfiltering = dayfiltering;
    }

    let bodyString = JSON.stringify(filter);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/reservation/listResAndWaiting?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        let resJson = response.json();
        let reservations = resJson as ReservationWaitingWS;

        return reservations;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  cancelReservation(
    reservation: ReservationWs,
    token: string
  ): Observable<ReservationResponseWs> {
    let detailWs = new DetailWs(reservation.id);
    let json = JSON.stringify(detailWs);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/reservation/cancel?access_token=${token}`,
        json,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as ReservationResponseWs;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  deleteReservationFromList(
    reservations: ReservationWs[],
    reservationDelete: ReservationWs
  ): ReservationWs[] {
    return reservations.filter((reservation) => {
      return reservation.id != reservationDelete.id;
    });
  }

  reservationBooking(
    room: RoomWs,
    roomList: RoomListWs,
    token: string
  ): Observable<ReservationResponseWs> {
    let reservation = new ReservationWs();
    reservation.dateFrom = roomList.dateFrom;
    reservation.dateTo = roomList.dateTo;
    reservation.assistants = roomList.assistants;
    reservation.reason = roomList.reason;
    reservation.room = room;
    reservation.type = roomList.type;
    reservation.periodicalDateTo = roomList.periodicalDateTo;

    let bodyString = JSON.stringify(reservation);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/reservation/booking?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as ReservationResponseWs;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  // TODO: Quitar dateTo, ahora mismo hay que ponerla porque si no falla el servicio
  reservationSpeedBooking(
    room: RoomWs,
    roomList: RoomListWs,
    token: string
  ): Observable<ReservationResponseWs> {
    let reservation = new ReservationWs();
    let dateTo = new Date(roomList.dateFrom.getTime());
    dateTo.setMinutes(dateTo.getMinutes() + 15);

    reservation.dateFrom = roomList.dateFrom;
    //reservation.dateTo = dateTo;
    reservation.dateTo = null;
    reservation.reservationDuration = roomList.duration;
    reservation.reason = roomList.reason;
    reservation.room = room;
    reservation.type = roomList.type;
    reservation.periodicalDateTo = roomList.periodicalDateTo;

    let bodyString = JSON.stringify(reservation);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/reservation/speed/booking?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as ReservationResponseWs;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  expressReservation(
    time: number,
    token: string
  ): Observable<ReservationResponseWs> {
    let reservation = new ReservationWs();
    reservation.reservationDuration = time;

    let bodyString = JSON.stringify(reservation);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/reservation/expressBooking?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        let responseReservation = response.json() as ReservationResponseWs;

        if (typeof responseReservation.reservation.dateFrom == 'number') {
          responseReservation.reservation.dateFrom = new Date(
            responseReservation.reservation.dateFrom as number
          );
        }

        if (typeof responseReservation.reservation.dateTo == 'number') {
          responseReservation.reservation.dateTo = new Date(
            responseReservation.reservation.dateTo as number
          );
        }

        return responseReservation;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  spliceReservation(
    reservations: ReservationWs[],
    reservation: ReservationWs
  ): void {
    let indexNextRes = this.getNextReservationIndex(reservations, reservation);

    if (indexNextRes >= reservations.length) {
      reservations.push(reservation);
    } else {
      reservations.splice(indexNextRes, 0, reservation);
    }
  }

  private getNextReservationIndex(
    reservations: ReservationWs[],
    reservation: ReservationWs
  ): number {
    for (let res of reservations) {
      if (this.utils.compareDates(res.dateFrom, reservation.dateFrom) >= 0) {
        return reservations.indexOf(res);
      }
    }

    return reservations.length;
  }

  nextReservation(token: string): Observable<ReservationWs> {
    let bodyString = JSON.stringify({});

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/reservation/next?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        let reservation = response.json() as ReservationWs;

        if (typeof reservation.dateFrom == 'number') {
          reservation.dateFrom = new Date(reservation.dateFrom as number);
        }

        if (typeof reservation.dateTo == 'number') {
          reservation.dateTo = new Date(reservation.dateTo as number);
        }

        return reservation;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  editReservation(
    bodyReservation: string,
    serviceUrl: string
  ): Observable<ReservationWs> {
    return this.http
      .post(serviceUrl, bodyReservation, OPTIONS)
      .map((response: Response) => {
        let reservationResponse = response.json() as ReservationResponseWs;

        if (typeof reservationResponse.reservation.dateFrom == 'number') {
          reservationResponse.reservation.dateFrom = new Date(
            reservationResponse.reservation.dateFrom as number
          );
        }

        if (typeof reservationResponse.reservation.dateTo == 'number') {
          reservationResponse.reservation.dateTo = new Date(
            reservationResponse.reservation.dateTo as number
          );
        }

        return reservationResponse.reservation;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  editReservationService(
    reservationEdit: ReservationEdit,
    token: string
  ): Observable<ReservationWs> {
    let reservation = new ReservationWs();
    let serviceUrl = '';

    if (reservationEdit.duration) {
      reservation.id = reservationEdit.reservation.id;
      reservation.dateFrom = reservationEdit.dateFromEdit;
      reservation.dateTo = null;
      reservation.reason = reservationEdit.reasonEdit;
      reservation.reservationDuration = reservationEdit.duration;
      serviceUrl = `${SERVICE_BASE_URL}/api/reservation/speed/edit?access_token=${token}`;
    } else {
      reservation.id = reservationEdit.reservation.id;
      reservation.dateFrom = reservationEdit.dateFromEdit;
      reservation.dateTo = reservationEdit.dateToEdit;
      reservation.reason = reservationEdit.reasonEdit;
      serviceUrl = `${SERVICE_BASE_URL}/api/reservation/edit?access_token=${token}`;
    }

    return this.editReservation(JSON.stringify(reservation), serviceUrl);
  }

  getSpeedLimits(
    token: string
  ): Observable<ReservationSpeedLimitsResponseWs[]> {
    return this.http
      .get(
        `${SERVICE_BASE_URL}/api/reservation/speed/limits?access_token=${token}`,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as ReservationSpeedLimitsResponseWs[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  sendToMail(
    reservation: ReservationWs,
    token: string
  ): Observable<ReservationWs> {
    let reservationMail = new ReservationWs();
    reservationMail.id = reservation.id;

    let bodyString = JSON.stringify(reservationMail);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/reservation/sendToMail?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as ReservationWs;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getPeriodicityTypes(token: string): Observable<ReservationTypesWS[]> {
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/reservation/getTypes?access_token=${token}`,
        JSON.stringify({}),
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as ReservationTypesWS[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getReservationById(reservations: ReservationWs[], id: number): ReservationWs {
    let res = reservations.filter((reservation) => {
      return reservation.id == id;
    });

    if (res.length > 0) {
      return res[0];
    } else {
      return null;
    }
  }

  sendICalendarToInvitatedUsersMail(
    token: string,
    idReservation: number,
    emails: string[]
  ): Observable<ReservationWs> {
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/reservation/sendToInvitatedUsersMail/${idReservation}/?access_token=${token}`,
        JSON.stringify({
          emails,
        }),
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as ReservationWs;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getAvailablePeriods(
    token: string,
    input: AvailablePeriodsInput,
    page: number = 0
  ): Observable<ReservationPeriodsPaged> {
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/reservation/listAvailablePeriods/?access_token=${token}&page=${page}`,
        JSON.stringify(input),
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as ReservationPeriodsPaged;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
