<div class="main-container">
  <div class="fadeInLayer">
    <div class="assistance">
      <div #calendar>
        <div class="row header-calendar">
          <div
            class="col-3"
            *ngIf="months.length > 1 && mode !== 'ASSISTANCE-USER'"
          >
            <label>{{ 'assistance_select_user' | messagesPipe }}</label>
          </div>
          <div
            class="col-9 selectUser"
            *ngIf="months.length > 1 && mode !== 'ASSISTANCE-USER'"
          >
            <div class="mui-select selectPrueba">
              <div class="ng-autocomplete">
                <ng-autocomplete
                  #usersAutocompleteComponent
                  [data]="users"
                  searchKeyword="name"
                  (selected)="selectEvent($event)"
                  (inputChanged)="onChangeSearch($event)"
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  [formControl]="userEntry"
                  placeHolder="{{
                    placeHolderkey
                      ? (placeHolderkey | messagesPipe)
                      : ('user_to_send_invite' | messagesPipe)
                  }}"
                >
                </ng-autocomplete>
                <ng-template #itemTemplate let-item>
                  <a href="javascript:void(0)">
                    <span [innerHTML]="item.name"></span>
                  </a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound>
                  {{ 'invitation_user_not_found' | messagesPipe }}
                </ng-template>
              </div>
            </div>
          </div>
          <div class="col-2">
            <button (click)="changeDate(-1)" class="btn-prev">
              {{ 'assistance_bottom_previous' | messagesPipe }}
            </button>
          </div>
          <div class="col-4 selectMonth">
            <div class="mui-select">
              <select
                class="form-control"
                id="selectMonth"
                name="selectMonth"
                (change)="changeMonthList($event.target.value)"
              >
                <option
                  *ngFor="let month of months"
                  [value]="month.value"
                  [selected]="monthSelected() === month.value"
                >
                  <span>{{ month.desc }}</span>
                </option>
              </select>
            </div>
          </div>
          <div class="col-4 selectAnyo">
            <div class="mui-select">
              <select
                class="form-control"
                id="selectAnyo"
                name="selectAnyo"
                (change)="changeAnyoList($event.target.value)"
              >
                <option
                  *ngFor="let anyo of anyos"
                  [value]="anyo"
                  [selected]="anyoSelected() == anyo"
                >
                  <span>{{ anyo }}</span>
                </option>
              </select>
            </div>
          </div>
          <div class="col-2" [style.text-align]="'right'">
            <button (click)="changeDate(1)" class="btn-next">
              {{ 'assistance_bottom_next' | messagesPipe }}
            </button>
          </div>
        </div>
        <div class="calendarContainer">
          <ol>
            <li *ngFor="let day of week" class="day-name">
              {{ day }}
            </li>
            <li
              (click)="clickDay(day)"
              [style.gridColumnStart]="first ? day?.indexWeek : 'auto'"
              *ngFor="let day of selectList; let first = first"
            >
              <app-calendar-view-assistance
                *ngIf="mode === 'ASSISTANCE'"
                [day]="day"
                [attendances]="attendances"
                [selectList]="selectList"
              ></app-calendar-view-assistance>
              <app-calendar-view-assistance-user
                *ngIf="mode === 'ASSISTANCE-USER'"
                [day]="day"
                [listAttendancesUser]="listAttendancesUser"
              ></app-calendar-view-assistance-user>
            </li>
          </ol>
          <app-spinner [stylesContent]="{ position: 'absolute' }"></app-spinner>
        </div>
      </div>
      <div class="bottom">
        <span class="bottom__title">{{
          'assistance_text_legend' | messagesPipe
        }}</span>
        <div class="row bottom__option">
          <div class="col-12">
            <label *ngFor="let legend of configLegend | keyvalue">
              {{ legend.value }}
              <svg
                [style.background]="legend.key"
                aria-hidden="true"
                focusable="false"
              ></svg>
            </label>
          </div>
          <div class="col-12 legendWarning" *ngIf="getAlertAssist()">
            <i class="vdp-salas-incidencia"></i>
            <span>{{ 'assistance_text_legend_alert' | messagesPipe }}</span>
          </div>
        </div>
      </div>
      <div class="save" *ngIf="mode != 'ASSISTANCE-USER'">
        <button
          type="button"
          [ngClass]="[getClassSelectedSave()]"
          (click)="this.btndisable=true;this.spinnerService.show();save()"
          [disabled]="!getClassSelectedActive() || btndisable"
        >
          <span>{{ 'assistance_bottom_save' | messagesPipe }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
<side-modal></side-modal>
