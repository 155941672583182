<div *ngIf="!existAvailableRooms" class="modal fade" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-info">
                <span class="title">{{'no_available_rooms_msg' | messagesPipe}}</span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="addToWaitingList()">{{'waiting_list' | messagesPipe}}</button>
                <span class="vdp-icon-cancelar" (click)="backToBookings()"></span>
            </div>
        </div>
    </div>
</div>
<div *ngIf="availableRooms.length > 0">
    <div class="main-container-tab">
        <div class="fadeInLayer">
            <ul class="tabBuildings">
                <li *ngFor="let roomListRes of availableRooms" (click)="changeBuilding(roomListRes)" [ngClass]="{'selected':roomListRes == roomListResponseSelect}"><span>{{roomListRes.buildingName}}</span></li>
            </ul>
            <div class="listRooms row">
                <div class="col-xs-12 col-sm-6 col-md-4" *ngFor="let room of roomListResponseSelect.rooms">
                    <roomCard [room]="room" [reservationBtnDisabled]="reservationBtnDisabled" (roomReserveEmitter)="reserveRoom($event)" (roomDetailEmitter)="showRoomDetail($event)"></roomCard>
                </div>
            </div>
        </div>
        <div >
            <roomSpeedDetail [reservationBtnDisabled]="reservationBtnDisabled" [roomDetail]="roomDetail" [buildingName]="roomListResponseSelect.buildingName" [dateFromInMillis]="roomList.dateFromInMillis" [duration]="roomList.duration"
                             (closeRoomDetailEmitter)="closeRoomDetail()" (roomReserveEmitter)="reserveRoom($event)"></roomSpeedDetail>
        </div>
    </div>
</div>

<!-- Lunch time confirmation -->
<div *ngIf="showServerLunchMsg" class="modal fade" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-info">
                <span class="message">{{reservationResponse.message}}</span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="backToBookings()">{{'ok' | messagesPipe}}</button>
            </div>
        </div>
    </div>
</div>
