import { LowerCasePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { STATUS_FORBIDDEN } from 'src/app/core/constants/const';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import { environment } from '../../../../environments/environment';
import { STATUS_POSITION_OCCUPIED } from '../../../core/constants/const';
import {
  ANYOS_ASSISTANCE,
  EN_DAYS_WEEK,
  ES_DAYS_WEEK,
  LOGOUT_TIMEOUT,
  MONTHS_ASSISTANCE_EN,
  MONTHS_ASSISTANCE_ES,
  MONTHS_ASSISTANCE_PT,
  PT_DAYS_WEEK
} from '../../../core/constants/consts';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';
import { SideModalService } from '../../../views/side-modal/side-modal.service';
import { MessagesPipe } from '../../pipes/messages.pipe';
import { AttendanceService } from '../../services/attendance.service';
import { AuthenticationService } from '../../services/authentication.service';
import { BaseService } from '../../services/base.service';
import { FlashMessagesService } from '../../services/flash-messages.service';
import { HolidayService } from '../../services/holiday.service';
import { NavigatorRefreshService } from '../../services/navigator.refresh.service';
import { VehicleService } from '../../services/vehicle.service';
import { SpinnerService } from '../spinner/spinner.service';
import { CalendarRoutes } from './calendar.routes';
import { CalendarService } from './calendar.service';

declare var $: any;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, AfterViewInit {
  @ViewChild('usersAutocompleteComponent', null)
  usersAutocompleteComponent: AutocompleteComponent;
  @Input() listAttendancesUser: any;
  @Input() mode: string;

  week: any[];
  selectList: any[];
  dateSelect: any;
  selectedYear: any;
  showEditAssistance = false;
  colorDays: any;
  holidays: any;
  dinnerHolidays: any;
  attendances: any;
  dateNew: Date;
  user: UserLdapWs;
  monthSelect: any;
  userSelect: any;
  assistanceConfirm: boolean;
  selectedDays: any;
  assistanceSelected: any;
  assistanceInfo: any;
  // modeAssistance: string;
  listUsersForDay: any;
  numMaxAssistant: number;
  permissions: MenuPermissions;
  configLegend = new Map();
  language: string;
  maxDayAssistance: number;
  messagesPipe = new MessagesPipe();
  months: any;
  anyos: any;
  lower = new LowerCasePipe();
  users: any;
  mainVehicle: any;
  userEntry = new FormControl();

  shiftPriority: any;
  typeVehicle: any;
  btndisable: boolean;

  constructor(
    private navigatorRefreshService: NavigatorRefreshService,
    private holidayService: HolidayService,
    private authenticationService: AuthenticationService,
    private attendanceService: AttendanceService,
    private baseService: BaseService,
    private flashMessagesService: FlashMessagesService,
    private calendarService: CalendarService,
    private spinnerService: SpinnerService,
    private sideModalService: SideModalService,
    private route: ActivatedRoute,
    private vehicleService: VehicleService
  ) {
    this.calendarService.inicio = this.inicio.bind(this);
  }

  ngOnInit() {
    this.getTypesVehicle();
    this.closeSideModal();
    this.anyos = ANYOS_ASSISTANCE;
    this.user = this.authenticationService.getCurrentUser();
    this.permissions =
      this.authenticationService.getPermissionsForCurrentUser();
    this.language = this.languageEnglish()
      ? 'en'
      : localStorage.getItem('language_app')
      ? localStorage.getItem('language_app')
      : navigator.language.substr(0, 2);
    this.week =
      this.language === 'en'
        ? EN_DAYS_WEEK
        : this.language === 'pt'
        ? PT_DAYS_WEEK
        : ES_DAYS_WEEK;
    this.months =
      this.language === 'en'
        ? MONTHS_ASSISTANCE_EN
        : this.language === 'pt'
        ? MONTHS_ASSISTANCE_PT
        : MONTHS_ASSISTANCE_ES;
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      this.mode === 'ASSISTANCE-USER'
        ? this.messagesPipe.transform('menu_assistance_user')
        : this.messagesPipe.transform('menu_assistance')
    );
    this.dateNew = new Date();
    this.monthSelect = this.dateNew.getMonth() + 1;
    this.selectedYear = this.dateNew.getFullYear();
    this.maxDayAssistance = this.permissions.maxDaysAssistance;
    this.userEntry = new FormControl(this.user.name);
    this.btndisable = false;
  }

  ngAfterViewInit() {
    if (this.mode !== 'ASSISTANCE-USER') {
      this.inicio();
    } else {
      this.getConfigurationDays();
    }
  }

  inicio() {
    this.getUsers();
  }

  selectEvent(item) {
    this.changeUsers(item.id);
  }

  getDaysFromDate(month: any, year: number) {
    const startDate = moment
      .utc(`${year}/${month}/01`, 'YYYY-MM-DD')
      .locale(this.language);
    const endDate = startDate.clone().endOf('month');
    this.dateSelect = startDate;

    const diffDays = endDate.diff(startDate, 'days', true);
    const numberDays = Math.round(diffDays);

    const arrayDays = [];

    for (let i = 1; i <= numberDays; i++) {
      const dayObject = moment
        .utc(`${year}-${month}-${i}`, 'YYYY-MM-DD')
        .locale(this.language);
      const dayColor = this.colorDays.filter((colorDay) => {
        return parseInt(colorDay.dia, 10) === dayObject.isoWeekday();
      });

      const currentDayColor = this.colorDays.filter((colorDay) => {
        return parseInt(colorDay.dia, 10) === 8;
      });

      const holidayColor = this.colorDays.filter((colorDay) => {
        return parseInt(colorDay.dia, 10) === 9;
      });

      const partTimeColor = this.colorDays.filter((colorDay) => {
        return parseInt(colorDay.dia, 10) === 10;
      });

      const workingHomeColor = this.colorDays.filter((colorDay) => {
        return parseInt(colorDay.dia, 10) === 11;
      });

      const festiveMonth = this.holidays.filter((el) => {
        const holiday = moment(el.day, 'DD/MM/YYYY');
        return holiday.month() + 1 === parseInt(month, 10);
      });

      let festive = false;
      let currentDay = false;
      const actualDate = new Date();
      const actualDateFormat = moment
        .utc(
          `${actualDate.getFullYear()}-${
            actualDate.getMonth() + 1
          }-${actualDate.getDate()}`,
          'YYYY-MM-DD'
        )
        .locale(this.language);

      festiveMonth.filter((festiveM) => {
        const dayMonth = moment
          .utc(festiveM.day, 'DD/MM/YYYY')
          .locale(this.language);
        if (dayMonth.isSame(dayObject)) {
          festive = true;
        }
      });

      if (dayObject.isSame(moment(actualDateFormat))) {
        currentDay = true;
      }

      const assignments = this.attendances
        ? this.attendances.filter((att) => {
            const day = moment(dayObject).format('DD/MM/YYYY');
            return att.day === day;
          })
        : [];

      let disabled = false;

      const d = this.dateNew.getDate();
      const m = this.dateNew.getMonth() + 1;
      const a = this.dateNew.getFullYear();

      const date = moment
        .utc(`${a}-${m}-${d}`, 'YYYY-MM-DD')
        .locale(this.language);

      const finalDate = moment().add(this.maxDayAssistance, 'days');

      if (
        moment(dayObject).isBefore(date) ||
        moment(dayObject).isAfter(finalDate)
      ) {
        disabled = true;
      }

      let selected = false;

      if (this.selectList) {
        this.selectList.forEach((selectL) => {
          const formatDate = moment
            .utc(selectL.dateComplete, 'DD/MM/YYYY')
            .locale(this.language);
          const daySelected = moment
            .utc(
              `${formatDate.year()}-${
                formatDate.month() + 1
              }-${formatDate.date()}`,
              'YYYY-MM-DD'
            )
            .locale(this.language);
          if (
            selectL.selected &&
            assignments.length <= 0 &&
            daySelected.isSame(dayObject)
          ) {
            selected = true;
          }
        });
      }

      const workingHomeBool = this.dinnerHolidays?.find(
        (x) => x.day == dayObject.format('DD/MM/YYYY')
      )?.workinghome;

      const colorShow = () => {
        let color: any = {};
        switch (true) {
          case festive:
            color = holidayColor[0];
            break;
          case workingHomeBool == true:
            color = workingHomeColor[0];
            break;
          case workingHomeBool == false:
            color = partTimeColor[0];
            break;
          default:
            color = dayColor[0];
            break;
        }
        return color;
      };

      const day = {
        name: dayObject.format('dddd'),
        date: dayObject.format('dddd DD MMMM YYYY'),
        dateComplete: dayObject.format('DD/MM/YYYY'),
        value: i,
        indexWeek: dayObject.isoWeekday(),
        color: colorShow().color,
        borderColor: currentDayColor[0].color,
        textColor: colorShow().textColor,
        festive: festive,
        workingHome: workingHomeBool ? workingHomeBool : null,
        partTime: workingHomeBool == false ? true : null,
        disabled: disabled,
        currentDay: currentDay,
        disabledWhitAssistance:
          disabled && assignments.length > 0 ? true : false,
        assignments: assignments.length > 0 ? assignments[0] : null,
        selected: selected,
        isParking:
          assignments.length > 0 &&
          assignments[0].parking &&
          assignments[0].parking.hasParking
            ? true
            : false,
        isDinner:
          assignments.length > 0 &&
          assignments[0].dinnerDesk &&
          assignments[0].dinnerDesk.hasDinnerDesk
            ? true
            : false,
        info: [],
      };
      arrayDays.push(day);
    }

    this.selectList = arrayDays;
  }

  getDaysFromDateAssistUser(month: any, year: number) {
    const startDate = moment
      .utc(`${year}/${month}/01`, 'YYYY-MM-DD')
      .locale(this.language);
    const endDate = startDate.clone().endOf('month');
    this.dateSelect = startDate;

    const diffDays = endDate.diff(startDate, 'days', true);
    const numberDays = Math.round(diffDays);

    const arrayDays = [];

    for (let i = 1; i <= numberDays; i++) {
      const dayObject = moment
        .utc(`${year}-${month}-${i}`, 'YYYY-MM-DD')
        .locale(this.language);

      const dayColor = this.colorDays.filter((colorDay) => {
        return parseInt(colorDay.dia, 10) === dayObject.isoWeekday();
      });
      const currentDayColor = this.colorDays.filter((colorDay) => {
        return parseInt(colorDay.dia, 10) === 8;
      });

      const holidayColor = this.colorDays.filter((colorDay) => {
        return parseInt(colorDay.dia, 10) === 9;
      });

      const partTimeColor = this.colorDays.filter((colorDay) => {
        return parseInt(colorDay.dia, 10) === 10;
      });

      const workingHomeColor = this.colorDays.filter((colorDay) => {
        return parseInt(colorDay.dia, 10) === 11;
      });

      const festiveMonth = this.holidays.filter((el) => {
        const holiday = moment(el.day, 'DD/MM/YYYY');
        return holiday.month() + 1 === parseInt(month, 10);
      });

      let festive = false;
      let currentDay = false;
      const actualDate = new Date();
      const actualDateFormat = moment
        .utc(
          `${actualDate.getFullYear()}-${
            actualDate.getMonth() + 1
          }-${actualDate.getDate()}`,
          'YYYY-MM-DD'
        )
        .locale(this.language);

      festiveMonth.filter((festiveM) => {
        const dayMonth = moment
          .utc(festiveM.day, 'DD/MM/YYYY')
          .locale(this.language);
        if (dayMonth.isSame(dayObject)) {
          festive = true;
        }
      });

      if (dayObject.isSame(moment(actualDateFormat))) {
        currentDay = true;
      }

      const assignments = this.listAttendancesUser
        ? this.listAttendancesUser.filter((att) => {
            const day = moment(dayObject).format('DD/MM/YYYY');
            return att.day === day;
          })
        : [];

      let disabled = false;

      const d = this.dateNew.getDate();
      const m = this.dateNew.getMonth() + 1;
      const a = this.dateNew.getFullYear();

      const date = moment
        .utc(`${a}-${m}-${d}`, 'YYYY-MM-DD')
        .locale(this.language);

      const finalDate = moment().add(this.maxDayAssistance, 'days');

      if (
        moment(dayObject).isBefore(date) ||
        moment(dayObject).isAfter(finalDate)
      ) {
        disabled = true;
      }

      const numAssist =
        assignments.length > 0 ? assignments[0].numberAssist : null;

      const occupationPercentage = this.permissions
        .occupationPercentageAssistance
        ? this.permissions.occupationPercentageAssistance
        : 0;
      const calcPercentage = (this.user.isManager * occupationPercentage) / 100;

      const workingHomeBool = this.dinnerHolidays.find(
        (x) => x.day == dayObject.format('DD/MM/YYYY')
      )?.workinghome;

      const colorShow = () => {
        let color: any = {};
        switch (true) {
          case festive:
            color = holidayColor[0];
            break;
          case workingHomeBool == true:
            color = workingHomeColor[0];
            break;
          case workingHomeBool == false:
            color = partTimeColor[0];
            break;
          default:
            color = dayColor[0];
            break;
        }
        return color;
      };

      const day = {
        name: dayObject.format('dddd'),
        date: dayObject.format('dddd DD MMMM'),
        dateComplete: dayObject.format('DD/MM/YYYY'),
        value: i,
        indexWeek: dayObject.isoWeekday(),
        color: colorShow().color,
        borderColor: currentDayColor[0].color,
        workingHome: workingHomeBool ? workingHomeBool : null,
        partTime: workingHomeBool == false ? true : null,
        textColor: colorShow().color,
        festive: festive,
        disabled: disabled,
        currentDay: currentDay,
        disabledWhitAssistance:
          disabled && assignments.length > 0 ? true : false,
        assignments: assignments.length > 0 ? assignments[0] : null,
        selected: false,
        numberAssist: numAssist,
        alertAssist: !numAssist
          ? false
          : numAssist > calcPercentage
          ? true
          : false,
      };
      arrayDays.push(day);
    }

    this.selectList = arrayDays;
  }

  changeDate(flag) {
    const date =
      flag < 0
        ? this.dateSelect?.clone().subtract(1, 'month')
        : this.dateSelect?.clone().add(1, 'month');
    this.monthSelect = date.month() + 1;
    this.selectedYear = date.year();
    this.getAttendances();
    this.mode === 'ASSISTANCE-USER'
      ? this.getDaysFromDateAssistUser(date.format('MM'), date.format('YYYY'))
      : this.getDaysFromDate(date.format('MM'), date.format('YYYY'));
    $('#selectMonth').val(parseInt(date.format('M'), 10));
    $('#selectAnyo').val(parseInt(date.format('YYYY'), 10));
  }

  changeUsers(val: any) {
    this.userSelect = parseInt(val, 10);
    this.getAttendances();
  }

  changeMonthList(val: any) {
    this.monthSelect = parseInt(val, 10);
    this.getAttendances();
    this.mode === 'ASSISTANCE-USER'
      ? this.getDaysFromDateAssistUser(this.monthSelect, this.selectedYear)
      : this.getDaysFromDate(this.monthSelect, this.selectedYear);
  }

  changeAnyoList(val: any) {
    this.selectedYear = parseInt(val, 10);
    this.getAttendances();
    this.getDaysFromDate(this.monthSelect, this.selectedYear);
  }

  clickDay(day) {
    if (this.mode !== 'ASSISTANCE-USER') {
      if (
        !day.festive &&
        (!day.disabled || day.disabledWhitAssistance) &&
        day.assignments
      ) {
        this.spinnerService.show();
        this.assistanceConfirm = true;
        this.assistanceSelected = day;
        this.getInfoAssistance();
      } else if (
        !day.festive &&
        (!day.disabled || day.disabledWhitAssistance) &&
        !day.assignments
      ) {
        this.selectList.forEach((selectL) => {
          if (selectL.value === day.value) {
            if (selectL.selected) {
              selectL.selected = false;
            } else {
              selectL.selected = true;
            }
          }
        });
      }
    } else {
      if (
        !day.festive &&
        (!day.disabled || day.disabledWhitAssistance) &&
        day.assignments
      ) {
        this.selectList.forEach((selectL) => {
          if (selectL.value === day.value) {
            if (selectL.selected) {
              selectL.selected = false;
            } else {
              selectL.selected = true;
            }
          }
        });
        this.assistanceSelected = day;
        this.getListUserForDay(this.assistanceSelected.assignments.day);
      }
    }
  }

  monthSelected() {
    return parseInt(
      moment(this.dateNew)
        .locale(this.language)
        .format('M')
        .toLocaleUpperCase(),
      10
    );
  }

  anyoSelected() {
    return parseInt(
      moment(this.dateNew)
        .locale(this.language)
        .format('YYYY')
        .toLocaleUpperCase(),
      10
    );
  }

  getTypesVehicle() {
    this.vehicleService
      .getTypesVehicle(this.authenticationService.getToken())
      .subscribe((res) => {
        if (res) {
          this.typeVehicle = res;
        }
      });
  }

  openModalEditAssistance() {
    this.shiftPriority =
      this.user.dinnerShiftFrom && this.user.dinnerShiftTo
        ? moment(this.user.dinnerShiftFrom).format('HHmm') +
          '' +
          moment(this.user.dinnerShiftTo).format('HHmm')
        : null;
    switch (true) {
      case this.mode === 'ASSISTANCE' && this.assistanceConfirm:
        this.sideModalService.openSideModal(
          CalendarRoutes.calendarEditConfirm.routerName,
          {
            assistanceSelected: this.assistanceSelected,
            assistanceInfo: this.assistanceInfo,
            shiftPriority: this.shiftPriority,
            mainVehicle: this.mainVehicle,
            typeVehicle: this.typeVehicle,
            showParkingSpotInfo: this.user.headquarters.showParkingSpotInfo,
            attendanceIntegrationParking:
              this.user.headquarters.attendanceIntegrationParking,
            attendanceIntegrationDinnerDesk:
              this.user.headquarters.attendanceIntegrationDinnerDesk,
            userSelect: this.userSelect,
          },
          this.messagesPipe.transform(CalendarRoutes.calendarEditConfirm.title),
          this.route,
          true
        );
        break;

      case this.mode === 'ASSISTANCE' && !this.assistanceConfirm:
        this.sideModalService.openSideModal(
          CalendarRoutes.calendarEditNotConfirm.routerName,
          {
            assistancesList: this.selectedDays,
            shiftPriority: this.shiftPriority,
            mainVehicle: this.mainVehicle,
            showParkingSpotInfo: this.user.headquarters.showParkingSpotInfo,
            assistanceSelected: this.assistanceSelected,
            attendanceIntegrationParking:
              this.user.headquarters.attendanceIntegrationParking,
            attendanceIntegrationDinnerDesk:
              this.user.headquarters.attendanceIntegrationDinnerDesk,
            userSelect: this.userSelect,
          },
          this.messagesPipe.transform(
            CalendarRoutes.calendarEditNotConfirm.title
          ),
          this.route,
          true
        );
        break;

      case this.mode === 'ASSISTANCE-USER':
        this.sideModalService.openSideModal(
          CalendarRoutes.calendarEditListUser.routerName,
          {
            assistanceSelected: this.assistanceSelected,
            listUsersForDay: this.listUsersForDay,
          },
          this.messagesPipe.transform(
            CalendarRoutes.calendarEditListUser.title
          ),
          this.route,
          true
        );
        break;

      default:
        break;
    }
  }

  closeSideModal() {
    this.sideModalService.subjectSideModal(this.route).subscribe((res) => {
      if (res.close) {
        switch (res.nameComponent) {
          case CalendarRoutes.calendarEditConfirm.routerName:
          case CalendarRoutes.calendarEditNotConfirm.routerName:
          case CalendarRoutes.calendarEditListUser.routerName:
            this.closeModalEditAssistance();
            break;

          default:
            break;
        }
      }
    });
  }

  closeModalEditAssistance() {
    this.showEditAssistance = false;
    this.btndisable = false;
    this.getAttendances();
  }
  getAttendances() {
    this.spinnerService.show();
    this.attendanceService
      .getAttendances(this.userSelect, this.monthSelect, this.selectedYear)
      .subscribe(
        (res) => {
          this.attendances = res;
          this.getConfigurationDays();
        },
        (error) => {
          this.spinnerService.hide();
          this.handleNetworkError(error, 'assistance_search_error', () => {
            this.getAttendances();
          });
        }
      );
  }

  getConfigurationDays() {
    this.spinnerService.show();
    this.holidayService
      .getConfigurationDays(this.user.headquarters.id)
      .subscribe(
        (res) => {
          if (res) {
            this.colorDays = res.daysColor;
            this.colorDays.forEach((element) => {
              if (element.dia !== 8) {
                this.configLegend.set(element.color, element.tag);
              }
            });
            this.holidays = res.holidays;
            this.dinnerHolidays = res.dinnerHolidays;
            this.mode === 'ASSISTANCE-USER'
              ? this.getDaysFromDateAssistUser(
                  this.monthSelect,
                  this.selectedYear
                )
              : this.getDaysFromDate(this.monthSelect, this.selectedYear);
          }
          this.spinnerService.hide();
        },
        (error) => {
          this.handleNetworkError(
            error,
            'assistance_configuration_days_search_error',
            () => {
              this.getConfigurationDays();
            }
          );
          this.spinnerService.hide();
        }
      );
  }

  getListUserForDay(day: string) {
    this.listUsersForDay = null;
    const date = moment(day, 'DD/MM/YYYY').format('DD/MM/YYYY');
    this.attendanceService.getListAttendancesUserManagerForDay(date).subscribe(
      (res) => {
        if (res) {
          this.listUsersForDay = res;
          this.openModalEditAssistance();
        }
      },
      (error) => {
        this.handleNetworkError(
          error,
          'assistance_users_for_day_search_error',
          () => {
            this.getListUserForDay(day);
          }
        );
      }
    );
  }

  getInfoAssistance() {
    this.mainVehicle = null;
    this.attendanceService
      .getAttendanceInfo(
        this.assistanceSelected.assignments.id,
        this.userSelect
      )
      .subscribe(
        (res) => {
          if (res) {
            this.assistanceInfo = res;
            if (
              this.assistanceInfo &&
              this.assistanceInfo.dinner &&
              this.assistanceInfo.dinner.availableTurns
            ) {
              let turn: any;
              for (
                let i = 0;
                i < this.assistanceInfo.dinner.availableTurns.length;
                i++
              ) {
                const dateFrom = moment(
                  this.assistanceInfo.dinner.availableTurns[i].shiftFrom
                ).format('HH:mm');
                const dateTo = moment(
                  this.assistanceInfo.dinner.availableTurns[i].shiftTo
                ).format('HH:mm');
                turn = {
                  id:
                    moment(
                      this.assistanceInfo.dinner.availableTurns[i].shiftFrom
                    ).format('HHmm') +
                    '' +
                    moment(
                      this.assistanceInfo.dinner.availableTurns[i].shiftTo
                    ).format('HHmm'),
                  shiftFrom:
                    this.assistanceInfo.dinner.availableTurns[i].shiftFrom,
                  shiftTo: this.assistanceInfo.dinner.availableTurns[i].shiftTo,
                  shiftFromAux: dateFrom,
                  shiftToAux: dateTo,
                };
                this.assistanceInfo.dinner.availableTurns[i] = turn;
              }
            }
            if (
              this.assistanceInfo &&
              this.assistanceInfo.parking &&
              this.assistanceInfo.parking.parkingSizeAttendance
            ) {
              this.assistanceInfo.parking.parkingSizeAttendance.forEach(
                (element) => {
                  if (element.main && element.enabled) {
                    this.mainVehicle = element.typeId;
                  }
                }
              );
            }
            this.openModalEditAssistance();
          }
          this.spinnerService.hide();
        },
        (error) => {
          this.spinnerService.hide();
          this.handleNetworkError(error, 'assistance_info_search_error', () => {
            this.getInfoAssistance();
          });
        }
      );
  }

  getClassSelectedSave() {
    let ret = false;

    if (this.selectList) {
      this.selectList.forEach((selectL) => {
        if (selectL.selected) {
          ret = true;
        }
      });
    }
    if(this.btndisable){
      ret=false;
    }
    const cad = ` vdp-save `;

    return ret ? cad + ` save-active ` : cad + ` save-no-active `;
  }

  getClassSelectedActive() {
    let ret = false;

    if (this.selectList) {
      this.selectList.forEach((selectL) => {
        if (selectL.selected) {
          ret = true;
        }
      });
    }

    return ret;
  }

  save() {
    const arrayDay = [];
    let day = {};

    this.selectList.map((selectL) => {
      if (selectL.selected) {
        day = selectL.dateComplete;
        arrayDay.push({ day });
      }
    });

    this.attendanceService.getCandidates(arrayDay, this.userSelect).subscribe(
      (res) => {
        this.selectedDays = this.selectList.filter((selectL) => {
          if (selectL.selected) {
            return selectL;
          }
        });
        this.mainVehicle = null;
        if (res) {
          res.forEach((candidates) => {
            this.selectedDays.forEach((selectedDay) => {
              if (candidates.day === selectedDay.dateComplete) {
                selectedDay.info = candidates;
                const turns = [];
                if (
                  candidates.dinnerDesk &&
                  candidates.dinnerDesk.availableTurns &&
                  candidates.dinnerDesk.availableTurns.length > 0
                ) {
                  candidates.dinnerDesk.availableTurns.forEach((element) => {
                    const dateFrom = moment(element.shiftFrom).format('HH:mm');
                    const dateTo = moment(element.shiftTo).format('HH:mm');
                    turns.push({
                      id:
                        moment(element.shiftFrom).format('HHmm') +
                        '' +
                        moment(element.shiftTo).format('HHmm'),
                      shiftFrom: element.shiftFrom,
                      shiftTo: element.shiftTo,
                      shiftFromAux: dateFrom,
                      shiftToAux: dateTo,
                      enabled: element.enabled,
                    });
                  });
                  candidates.dinnerDesk.availableTurns = turns;
                }
                if (candidates.parking?.parkingSizeAttendance?.length > 0) {
                  candidates.parking.parkingSizeAttendance.forEach(
                    (element) => {
                      if (element.main && element.enabled) {
                        this.mainVehicle = element.typeId;
                      }
                    }
                  );
                }
              }
            });
          });
          if (this.selectedDays.length > 0) {
            this.assistanceConfirm = false;
            this.openModalEditAssistance();
          }
        }
        this.spinnerService.hide();
      },
      (error) => {
        this.btndisable= false;
        this.spinnerService.hide();
        this.handleNetworkError(
          error,
          'assistance_candidates_search_error',
          () => {
            this.save();
          }
        );
      }
    );
  }

  languageEnglish(): boolean {
    return environment['languageEnglish'];
  }

  getAlertAssist() {
    let res = false;
    if (this.selectList) {
      this.selectList.forEach((element) => {
        if (element.alertAssist) {
          res = true;
        }
      });
    }

    return res;
  }

  private handleNetworkError(
    error: any,
    errorMessage: string,
    callback: Function
  ): void {
    if (error.code === STATUS_FORBIDDEN) {
      this.authenticationService.refreshToken().subscribe(
        (response) => {
          callback();
        },
        () => {
          this.authenticationService.validateSessionId().subscribe(
            (response) => {
              callback();
            },
            (error) => {
              this.flashMessagesService.grayOut(false);
              this.flashMessagesService.show(
                this.messagesPipe.transform('error_forbidden'),
                { cssClass: 'alert-danger', timeout: 3000 }
              );

              setTimeout(() => {
                this.authenticationService.logout();
              }, LOGOUT_TIMEOUT);
            }
          );
        }
      );
    } else if (error.code === STATUS_POSITION_OCCUPIED) {
      this.baseService.showErrorDialog(
        this.flashMessagesService,
        error,
        this.messagesPipe.transform('assistance_text_reserve_error')
      );
      this.closeModalEditAssistance();
    } else {
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show(
        this.messagesPipe.transform(errorMessage),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
    }
  }

  getUsers() {
    this.attendanceService
      .getUsers()
      .pipe(
        finalize(() => {
          this.getAttendances();
        })
      )
      .subscribe(
        (res) => {
          if (res) {
            this.users = res;
            this.userSelect = res[0].id;
          }
        },
        (error) => {
          this.handleNetworkError(error, 'assistance_third_users_error', () => {
            this.getUsers();
          });
        }
      );
  }
}
