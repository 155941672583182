<div class="assistance-user">
    <div class="row">
      <div class="col-sm-10 col-9">
        <span>{{ assistanceSelected?.date }}</span>
      </div>
      <div class="col-sm-1 col-1 spanUser">
        <span>{{ assistanceSelected?.numberAssist }}</span>
      </div>
      <div class="col-sm-1 col-1 iconUser">
        <i class="vdp-buscar-usuario"></i>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-12" *ngFor="let user of listUsersForDay">
        <p>{{ user?.name }}</p>
      </div>
    </div>
  </div>