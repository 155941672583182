<div class="main-container">
  <div class="fadeInLayer">
    <div
      class="roomDetail"
      [@slideDetailsBooking]="getDetailReservationAnimateOpacity()"
      [ngClass]="{'centerbox': !showCloseDetail}"
    >
      <div
        class="roomDetailCard"
        [@slideDetailsBooking]="getRoomDetailAnimation()"
        [ngClass]="{'bloqued': !room.enabled}"
      >
        <app-room-detail-header [hideCloseButton]="true" [room]="room">
        </app-room-detail-header>

        <div class="room-not-available" *ngIf="!room.enabled">
          <p>{{'room_not_available' | messagesPipe}}</p>
        </div>
        <div class="date-info col-sm-6">
          <label for="fecha">{{'date' | messagesPipe}}</label>
          <datepicker
            #datePickerElement
            class="custom-datepicker"
            id="fecha"
            [options]="datepickerOptions"
            (dateChanged)="onDateChanged($event)"
          ></datepicker>
          <span class="required" [hidden]="reservationDate"
            >{{'error_required' | messagesPipe}}</span
          >
        </div>
        <div class="button-room col-sm-6">
          <button
            type="button"
            (click)="showModalAction()"
            [disabled]="reservationDate == null || !room.enabled"
          >
            {{'reserve_room' | messagesPipe}}
          </button>
        </div>
        <div class="info">
          <div class="equipment clearfix">
            <span class="title">{{'equipment' | messagesPipe}}</span>
            <div class="col-xs-3" *ngFor="let equipment of room.equipment">
              <div class="equipment-item">
                <img [src]="equipment.imageUrl" height="50px" width="50px" />
                <span>{{equipment.name}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="calendar">
          <ul>
            <li *ngFor="let hour of hours">{{hour}}</li>
          </ul>
          <div
            class="busy"
            *ngFor="let reservation of room.reservations"
            [ngClass]="getClassScheduleDiv(reservation.dateFrom, reservation.dateTo)"
          >
            <div class="message">
              <span *ngIf="reservation.userTo != null && !reservation.private"
                >{{'room_busy_user' | messagesPipe:
                [reservation.userTo.userName]}}</span
              >
              <span *ngIf="reservation.userTo != null && reservation.private"
                >Ocupada</span
              >
              <span *ngIf="reservation.userTo == null"
                >{{'room_busy' | messagesPipe}}</span
              >
            </div>
          </div>
          <div class="card" [ngClass]="getClassLaunchTime()">
            <i class="vdp-salas-lunch"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
