<div class="main-container">
  <div class="fadeInLayer">
    <div class="row">
      <div class="col-md-12">
        <div class="welcome-header">
          <div class="welcome-header-layer"></div>
          <div class="welcome-header-text">
            <h1>{{ 'welcome' | messagesPipe }}</h1>
            <h2>{{ 'welcome2' | messagesPipe }}</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      
      <app-home-parking class="col-sm-5" *ngIf="permissions.allowParking" [home]="'true'"></app-home-parking>

      <div class="col-sm-7" *ngIf="permissions.allowReservation && !permissions?.disableReservation">
        <h3>{{ 'home_events_title' | messagesPipe }}</h3>
        <div class="no-reservation" *ngIf="nextReservation == null">
          <div class="image">
            <img src="../../assets/img/icon-sala-vacia.png" alt="" />
          </div>
          <span>{{ 'rooms_reserve_empty' | messagesPipe }}</span>
        </div>
        <div
          *ngIf="nextReservation != null"
          class="next-reservation-card clearfix"
          (click)="showDetail()"
          [ngClass]="{
            differentBuilding:
              nextReservation.room.floor.building.headquarters.id !=
              user.headquarters.id
          }"
        >
          <img [src]="nextReservation.room.imageUrl" alt="" />
          <div class="wrapper">
            <div class="date">
              <span class="month">{{
                nextReservation.dateFrom | customDatePipe
              }}</span>
              <span class="day">{{
                nextReservation.dateFrom | dateStringPipe: 'DD'
              }}</span>
              <span class="hour">{{
                nextReservation.dateFrom | dateStringPipe: 'HH:mm'
              }}</span>
            </div>
            <div class="info">
              <span class="name">{{ getHeadquartersName() }}</span>
              <span class="name">{{ getRoomBuildingName() }}</span>
              <span
                class="code"
                *ngIf="nextReservation.room.conferenceCode != null"
                >{{ 'room_conference_code_title' | messagesPipe }}
                {{ nextReservation.room.conferenceCode }}</span
              >
              <span class="reason">{{ nextReservation.reason }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-5" *ngIf="permissions.allowReservation && permissions?.disableReservation">
        <h3>{{ 'home_events_title' | messagesPipe }}</h3>
        <div class="disable-reservation">
          <label> {{'rooms_disable_reservation_title' | messagesPipe}}</label>
          <span> {{'rooms_disable_reservation_description' | messagesPipe}}</span>
        </div>
      </div>
      <app-reservation-detail
        *ngIf="permissions.allowReservation"
        (eventCloseDetail)="closeDetail()"
        (eventCancel)="cancelReservation($event)"
        [reservationWs]="reservationDetail"
        (eventEdit)="editReservation($event)"
      ></app-reservation-detail>
      <reservationSpeedDetail
        *ngIf="permissions.allowReservation"
        (eventCloseDetail)="closeDetail()"
        (eventCancel)="cancelReservation($event)"
        [reservationWs]="reservationDetailSpeed"
        (eventEdit)="editReservation($event)"
      ></reservationSpeedDetail>
    </div>
  </div>
</div>
<app-modal-indicate-department #modalIndicateDept></app-modal-indicate-department>
