import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { STATUS_FORBIDDEN } from '../../../core/constants/const';
import { LOGOUT_TIMEOUT } from '../../../core/constants/consts';
import { BuildingWs } from '../../../core/models/building.ws';
import { Headquarters } from '../../../core/models/headquarters';
import { ReservationWs } from '../../../core/models/reservation.ws';
import { RoomWs } from '../../../core/models/room.ws';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';
import { MyDatePicker } from '../../../shared/components/date_picker/datepicker.component';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { BaseService } from '../../../shared/services/base.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { RoomService } from '../../../shared/services/room.service';
import { Utils } from '../../../shared/utils/utils';

@Component({
  selector: 'reservationNewDetailByRoom',
  templateUrl: 'reservation.new.detail.room.html',
  styleUrls: ['new-reservation.scss'],
})
export class ReservationNewDetailByRoom implements OnInit, AfterViewInit {
  buildings: BuildingWs[];
  @ViewChild('datePickerElement', null) datePickerElement: MyDatePicker;
  @ViewChild('selectBuildings', null) selectBuildings: any;
  @ViewChild('selectRooms', null) selectRooms: any;
  datepickerOptions: any;
  reservationDate: Date;
  rooms: RoomWs[];
  buildingSelected: BuildingWs;
  roomSelected: RoomWs;
  user: UserLdapWs;
  userHeadquarter: Headquarters;

  constructor(
    private authenticationService: AuthenticationService,
    private utils: Utils,
    private roomService: RoomService,
    private flashMessagesService: FlashMessagesService,
    private baseService: BaseService,
    private router: Router
  ) {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state
    ) {
      this.buildings =
        this.router.getCurrentNavigation().extras.state.buildings;
    }
  }

  ngOnInit() {
    this.datepickerOptions = this.utils.getDatepickerOptions(
      this.authenticationService.getMaxReservationDate()
    );

    /* Setting the User's headquarters */
    this.user = this.authenticationService.getCurrentUser();
    this.userHeadquarter = this.user.headquarters;
    this.buildingSelected = new BuildingWs(this.user?.building?.id, '');
    if (this.buildingSelected.id) {
      this.changeRooms(this.buildingSelected);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let actualDate = new Date();
      this.utils.setDatePickerDate(actualDate, this.datePickerElement);
      this.selectRooms.nativeElement.selectedIndex = 0;
    }, 0);
  }

  onDateChanged($event) {
    if ($event.formatted.length > 0) {
      this.reservationDate = new Date();
      this.reservationDate.setFullYear(
        $event.date.year,
        $event.date.month - 1,
        $event.date.day
      );
    } else {
      this.reservationDate = null;
    }
  }

  buildingChanged($event) {
    this.roomSelected = null;
    let buildingId = parseInt($event.target.value);

    if (buildingId > 0) {
      this.buildingSelected = new BuildingWs($event.target.value, '');
      this.changeRooms(this.buildingSelected);
    } else {
      this.buildingSelected = null;
      this.rooms = [];
    }
  }

  roomChanged($event) {
    if ($event.target.value.length > 0) {
      this.roomSelected = new RoomWs('', '', '', '', '');
      this.roomSelected.id = $event.target.value;
    } else {
      this.roomSelected = null;
    }
  }

  changeRooms(building: BuildingWs) {
    let messagesPipe = new MessagesPipe();
    this.roomService
      .getRoomsByBuilding(building, this.authenticationService.getToken())
      .subscribe(
        (rooms) => {
          this.rooms = rooms;
        },
        (error) => {
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.changeRooms(building);
              },
              (error) => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.changeRooms(building);
                  },
                  (error) => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              messagesPipe.transform('load_rooms_error')
            );
          }
        }
      );
  }

  checkReservations() {
    let reservation = new ReservationWs();
    reservation.dateFromInMillis = this.reservationDate.getTime();
    reservation.room = null;

    if (this.roomSelected) {
      reservation.room = this.roomSelected;
      this.router.navigate(['/menu/room/detail']);
    } else {
      this.router.navigate(['/menu/room/mosaic']);
    }

    /* We set the same headquarteers as the user because in this screen the user can search by a room
        under his headquarters. */
    reservation.headquarters = this.user.headquarters;
    localStorage.setItem(
      'reservationFilterNoTime',
      JSON.stringify(reservation)
    );
  }
}
