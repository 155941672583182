import { LowerCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import {
  ANYOS_ASSISTANCE,
  EN_DAYS_WEEK,
  ES_DAYS_WEEK,
  MONTHS_ASSISTANCE_EN,
  MONTHS_ASSISTANCE_ES,
  MONTHS_ASSISTANCE_PT,
  PT_DAYS_WEEK,
} from '../../core/constants/consts';
import { MenuPermissions } from '../../core/models/menu.permissions';
import { UserLdapWs } from '../../core/models/user.ldap.ws';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { AttendanceService } from '../../shared/services/attendance.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { BaseService } from '../../shared/services/base.service';
import { FlashMessagesService } from '../../shared/services/flash-messages.service';
import { HolidayService } from '../../shared/services/holiday.service';
import { NavigatorRefreshService } from '../../shared/services/navigator.refresh.service';
import { STATUS_POSITION_OCCUPIED } from '../../core/constants/const';

declare var $: any;

@Component({
  selector: 'app-assistance-iberdrola',
  templateUrl: './assistance-iberdrola.component.html',
  styleUrls: ['./assistance-iberdrola.component.scss'],
})
export class AssistanceIberdrolaComponent implements OnInit {
  @Input() assistanceUser: boolean;
  @Input() listAttendancesUser: any;
  week: any[];
  selectList: any[];
  dateSelect: any;
  selectedYear: any;
  showEditAssistance = false;
  colorDays: any;
  holidays: any;
  attendances: any;
  dateNew: Date;
  user: UserLdapWs;
  monthSelect: any;
  assistanceConfirm: boolean;
  selectedDays: any;
  assistanceSelected: any;
  assistanceInfo: any;
  modeAssistance: string;
  listUsersForDay: any;
  numMaxAssistant: number;
  permissions: MenuPermissions;
  configLegend = new Map();
  language: string;
  maxDayAssistance: number;
  messagesPipe = new MessagesPipe();
  months: any;
  anyos: any;
  lower = new LowerCasePipe();

  constructor(
    private navigatorRefreshService: NavigatorRefreshService,
    private holidayService: HolidayService,
    private authenticationService: AuthenticationService,
    private attendanceService: AttendanceService,
    private baseService: BaseService,
    private flashMessagesService: FlashMessagesService
  ) {
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      this.assistanceUser
        ? this.messagesPipe.transform('menu_assistance_user')
        : this.messagesPipe.transform('menu_assistance')
    );
  }

  ngOnInit() {
    this.anyos = ANYOS_ASSISTANCE;
    this.user = this.authenticationService.getCurrentUser();
    this.permissions =
      this.authenticationService.getPermissionsForCurrentUser();
    this.language = localStorage.getItem('language_app')
      ? localStorage.getItem('language_app')
      : navigator.language.substr(0, 2);
    this.week =
      this.language === 'en'
        ? EN_DAYS_WEEK
        : this.language === 'pt'
        ? PT_DAYS_WEEK
        : ES_DAYS_WEEK;
    this.months =
      this.language === 'en'
        ? MONTHS_ASSISTANCE_EN
        : this.language === 'pt'
        ? MONTHS_ASSISTANCE_PT
        : MONTHS_ASSISTANCE_ES;

    this.dateNew = new Date();
    this.monthSelect = this.dateNew.getMonth() + 1;
    this.selectedYear = this.dateNew.getFullYear();
    this.maxDayAssistance = this.permissions.maxDaysAssistance;
    this.getAttendances();
  }

  getDaysFromDate(month: any, year: number) {
    const startDate = moment
      .utc(`${year}/${month}/01`, 'YYYY-MM-DD')
      .locale(this.language);
    const endDate = startDate.clone().endOf('month');
    this.dateSelect = startDate;

    const diffDays = endDate.diff(startDate, 'days', true);
    const numberDays = Math.round(diffDays);

    const arrayDays = [];

    for (let i = 1; i <= numberDays; i++) {
      const dayObject = moment
        .utc(`${year}-${month}-${i}`, 'YYYY-MM-DD')
        .locale(this.language);
      const dayColor = this.colorDays.filter((colorDay) => {
        return parseInt(colorDay.dia, 10) === dayObject.isoWeekday();
      });

      const currentDayColor = this.colorDays.filter((colorDay) => {
        return parseInt(colorDay.dia, 10) === 8;
      });

      const holidayColor = this.colorDays.filter((colorDay) => {
        return parseInt(colorDay.dia, 10) === 9;
      });

      const festiveMonth = this.holidays.filter((el) => {
        const holiday = moment(el.day, 'YYYY-MM-DD');
        return holiday.month() + 1 === parseInt(month, 10);
      });

      let festive = false;
      let currentDay = false;
      const actualDate = new Date();
      const actualDateFormat = moment
        .utc(
          `${actualDate.getFullYear()}-${
            actualDate.getMonth() + 1
          }-${actualDate.getDate()}`,
          'YYYY-MM-DD'
        )
        .locale(this.language);

      festiveMonth.filter((festiveM) => {
        const dayMonth = moment
          .utc(festiveM.day, 'YYYY-MM-DD')
          .locale(this.language);
        if (dayMonth.isSame(dayObject)) {
          festive = true;
        }
      });

      if (dayObject.isSame(moment(actualDateFormat))) {
        currentDay = true;
      }

      const assignments = this.attendances
        ? this.attendances.filter((att) => {
            const assignment = moment
              .utc(att.day, 'YYYY-MM-DD')
              .locale(this.language);
            return assignment.isSame(dayObject);
          })
        : [];

      let disabled = false;

      const d = this.dateNew.getDate();
      const m = this.dateNew.getMonth() + 1;
      const a = this.dateNew.getFullYear();

      const date = moment
        .utc(`${a}-${m}-${d}`, 'YYYY-MM-DD')
        .locale(this.language);

      const finalDate = moment().add(this.maxDayAssistance, 'days');

      if (
        moment(dayObject).isBefore(date) ||
        moment(dayObject).isAfter(finalDate)
      ) {
        disabled = true;
      }

      let selected = false;

      if (this.selectList) {
        this.selectList.forEach((selectL) => {
          const formatDate = moment
            .utc(selectL.dateComplete, 'DD/MM/YYYY')
            .locale(this.language);
          const daySelected = moment
            .utc(
              `${formatDate.year()}-${
                formatDate.month() + 1
              }-${formatDate.date()}`,
              'YYYY-MM-DD'
            )
            .locale(this.language);
          if (
            selectL.selected &&
            assignments.length <= 0 &&
            daySelected.isSame(dayObject)
          ) {
            selected = true;
          }
        });
      }

      const day = {
        name: dayObject.format('dddd'),
        date: dayObject.format('dddd DD MMMM YYYY'),
        dateComplete: dayObject.format('DD/MM/YYYY'),
        value: i,
        indexWeek: dayObject.isoWeekday(),
        color: festive ? holidayColor[0].color : dayColor[0].color,
        borderColor: currentDayColor[0].color,
        textColor: festive ? holidayColor[0].textColor : dayColor[0].textColor,
        festive: festive,
        disabled: disabled,
        currentDay: currentDay,
        disabledWhitAssistance:
          disabled && assignments.length > 0 ? true : false,
        assignments: assignments.length > 0 ? assignments[0] : null,
        selected: selected,
        isParking:
          assignments.length > 0 &&
          assignments[0].parking &&
          assignments[0].parking.hasParking
            ? true
            : false,
        isDinner:
          assignments.length > 0 && assignments[0].hasDinnerDesk ? true : false,
        info: [],
      };
      arrayDays.push(day);
    }

    this.selectList = arrayDays;
  }

  getDaysFromDateAssistUser(month: any, year: number) {
    const startDate = moment
      .utc(`${year}/${month}/01`, 'YYYY-MM-DD')
      .locale(this.language);
    const endDate = startDate.clone().endOf('month');
    this.dateSelect = startDate;

    const diffDays = endDate.diff(startDate, 'days', true);
    const numberDays = Math.round(diffDays);

    const arrayDays = [];

    for (let i = 1; i <= numberDays; i++) {
      const dayObject = moment
        .utc(`${year}-${month}-${i}`, 'YYYY-MM-DD')
        .locale(this.language);

      const dayColor = this.colorDays.filter((colorDay) => {
        return parseInt(colorDay.dia, 10) === dayObject.isoWeekday();
      });
      const currentDayColor = this.colorDays.filter((colorDay) => {
        return parseInt(colorDay.dia, 10) === 8;
      });

      const holidayColor = this.colorDays.filter((colorDay) => {
        return parseInt(colorDay.dia, 10) === 9;
      });

      const festiveMonth = this.holidays.filter((el) => {
        const holiday = moment(el.day, 'YYYY-MM-DD');
        return holiday.month() + 1 === parseInt(month, 10);
      });

      let festive = false;
      let currentDay = false;
      const actualDate = new Date();
      const actualDateFormat = moment
        .utc(
          `${actualDate.getFullYear()}-${
            actualDate.getMonth() + 1
          }-${actualDate.getDate()}`,
          'YYYY-MM-DD'
        )
        .locale(this.language);

      festiveMonth.filter((festiveM) => {
        const dayMonth = moment
          .utc(festiveM.day, 'YYYY-MM-DD')
          .locale(this.language);
        if (dayMonth.isSame(dayObject)) {
          festive = true;
        }
      });

      if (dayObject.isSame(moment(actualDateFormat))) {
        currentDay = true;
      }

      const assignments = this.listAttendancesUser
        ? this.listAttendancesUser.filter((att) => {
            const assignment = moment
              .utc(att.day, 'YYYY-MM-DD')
              .locale(this.language);
            return assignment.isSame(dayObject);
          })
        : [];

      let disabled = false;

      const d = this.dateNew.getDate();
      const m = this.dateNew.getMonth() + 1;
      const a = this.dateNew.getFullYear();

      const date = moment
        .utc(`${a}-${m}-${d}`, 'YYYY-MM-DD')
        .locale(this.language);

      const finalDate = moment().add(this.maxDayAssistance, 'days');

      if (
        moment(dayObject).isBefore(date) ||
        moment(dayObject).isAfter(finalDate)
      ) {
        disabled = true;
      }

      const numAssist =
        assignments.length > 0 ? assignments[0].numberAssist : null;

      const occupationPercentage = this.permissions
        .occupationPercentageAssistance
        ? this.permissions.occupationPercentageAssistance
        : 0;
      const calcPercentage = (this.user.isManager * occupationPercentage) / 100;

      const day = {
        name: dayObject.format('dddd'),
        date: dayObject.format('dddd DD MMMM'),
        dateComplete: dayObject.format('DD/MM/YYYY'),
        value: i,
        indexWeek: dayObject.isoWeekday(),
        color: festive ? holidayColor[0].color : dayColor[0].color,
        borderColor: currentDayColor[0].color,
        textColor: festive ? holidayColor[0].textColor : dayColor[0].textColor,
        festive: festive,
        disabled: disabled,
        currentDay: currentDay,
        disabledWhitAssistance:
          disabled && assignments.length > 0 ? true : false,
        assignments: assignments.length > 0 ? assignments[0] : null,
        selected: false,
        numberAssist: numAssist,
        alertAssist: !numAssist
          ? false
          : numAssist > calcPercentage
          ? true
          : false,
      };
      arrayDays.push(day);
    }

    this.selectList = arrayDays;
  }

  changeDate(flag) {
    const date =
      flag < 0
        ? this.dateSelect.clone().subtract(1, 'month')
        : this.dateSelect.clone().add(1, 'month');
    this.assistanceUser
      ? this.getDaysFromDateAssistUser(date.format('MM'), date.format('YYYY'))
      : this.getDaysFromDate(date.format('MM'), date.format('YYYY'));
    $('#selectMonth').val(parseInt(date.format('M'), 10));
    $('#selectAnyo').val(parseInt(date.format('YYYY'), 10));
    this.monthSelect = this.dateSelect.month() + 1;
    this.selectedYear = this.dateSelect.year();
  }

  changeMonthList(val: any) {
    this.monthSelect = parseInt(val, 10);
    this.assistanceUser
      ? this.getDaysFromDateAssistUser(this.monthSelect, this.selectedYear)
      : this.getDaysFromDate(this.monthSelect, this.selectedYear);
  }

  changeAnyoList(val: any) {
    this.selectedYear = parseInt(val, 10);
    this.getDaysFromDate(this.monthSelect, this.selectedYear);
  }

  clickDay(day) {
    if (!this.assistanceUser) {
      if (
        !day.festive &&
        (!day.disabled || day.disabledWhitAssistance) &&
        day.assignments
      ) {
        this.modeAssistance = 'ASSISTANCE';
        this.assistanceConfirm = true;
        this.assistanceSelected = day;
        this.getInfoAssistance();
        this.openModalEditAssistance();
      } else if (
        !day.festive &&
        (!day.disabled || day.disabledWhitAssistance) &&
        !day.assignments
      ) {
        this.selectList.forEach((selectL) => {
          if (selectL.value === day.value) {
            if (selectL.selected) {
              selectL.selected = false;
            } else {
              selectL.selected = true;
            }
          }
        });
      }
    } else {
      if (
        !day.festive &&
        (!day.disabled || day.disabledWhitAssistance) &&
        day.assignments
      ) {
        this.modeAssistance = 'ASSISTANCE-USER';
        this.selectList.forEach((selectL) => {
          if (selectL.value === day.value) {
            if (selectL.selected) {
              selectL.selected = false;
            } else {
              selectL.selected = true;
            }
          }
        });
        this.assistanceSelected = day;
        this.getListUserForDay(this.assistanceSelected.assignments.day);
        this.openModalEditAssistance();
      }
    }
  }

  monthSelected() {
    return parseInt(
      moment(this.dateNew)
        .locale(this.language)
        .format('M')
        .toLocaleUpperCase(),
      10
    );
  }

  anyoSelected() {
    return parseInt(
      moment(this.dateNew)
        .locale(this.language)
        .format('YYYY')
        .toLocaleUpperCase(),
      10
    );
  }

  openModalEditAssistance() {
    this.showEditAssistance = true;
  }

  closeModalEditAssistance() {
    this.showEditAssistance = false;
    this.getAttendances();
  }

  getAttendances() {
    this.attendanceService.getAttendancesIberdrola().subscribe((res) => {
      if (res) {
        this.attendances = res;
      } else {
        this.attendances = null;
      }
      this.getConfigurationDays();
    });
  }

  getConfigurationDays() {
    this.holidayService
      .getConfigurationDays(this.user.headquarters.id)
      .subscribe((res) => {
        if (res) {
          this.colorDays = res.daysColor;
          this.colorDays.forEach((element) => {
            if (element.dia !== 8) {
              this.configLegend.set(element.color, element.tag);
            }
          });
          this.holidays = res.holidays;
          this.assistanceUser
            ? this.getDaysFromDateAssistUser(
                this.monthSelect,
                this.selectedYear
              )
            : this.getDaysFromDate(this.monthSelect, this.selectedYear);
        }
      });
  }

  getListUserForDay(day: string) {
    this.listUsersForDay = null;
    const date = moment(day).format('DD/MM/YYYY');
    this.attendanceService
      .getListAttendancesUserManagerForDay(date)
      .subscribe((res) => {
        if (res) {
          this.listUsersForDay = res;
        }
      });
  }

  getInfoAssistance() {
    this.attendanceService
      .getAttendanceInfoIberdola(this.assistanceSelected.assignments.id)
      .subscribe((res) => {
        if (res) {
          this.assistanceInfo = res;
          this.assistanceInfo.parking.size = this.lower.transform(
            this.assistanceInfo.parking.size
          );
          if (this.assistanceInfo.dinner.availableTurns) {
            let turn: any;
            for (
              let i = 0;
              i < this.assistanceInfo.dinner.availableTurns.length;
              i++
            ) {
              const dateFrom = moment(
                this.assistanceInfo.dinner.availableTurns[i].shiftFrom
              ).format('HH:mm');
              const dateTo = moment(
                this.assistanceInfo.dinner.availableTurns[i].shiftTo
              ).format('HH:mm');
              turn = {
                id:
                  moment(
                    this.assistanceInfo.dinner.availableTurns[i].shiftFrom
                  ).format('HHmm') +
                  '' +
                  moment(
                    this.assistanceInfo.dinner.availableTurns[i].shiftTo
                  ).format('HHmm'),
                shiftFrom:
                  this.assistanceInfo.dinner.availableTurns[i].shiftFrom,
                shiftTo: this.assistanceInfo.dinner.availableTurns[i].shiftTo,
                shiftFromAux: dateFrom,
                shiftToAux: dateTo,
              };
              this.assistanceInfo.dinner.availableTurns[i] = turn;
            }
          }
        }
      });
  }

  getClassSelected(day: any) {
    let ret = '';
    if (day.selected) {
      ret = ` selected`;
    }
    if (day.disabledWhitAssistance) {
      ret += ` dayCalendarDisabledWhitAssistance`;
    }
    if (day.assignments && (day.assignments.id || day.assignments.day)) {
      ret += this.assistanceUser ? ` assignment-assist-user` : ` assignment`;
    }
    return ret;
  }

  getClassSelectedSave() {
    let ret = false;

    if (this.selectList) {
      this.selectList.forEach((selectL) => {
        if (selectL.selected) {
          ret = true;
        }
      });
    }

    const cad = ` vdp-save `;

    return ret ? cad + ` save-active ` : cad + ` save-no-active `;
  }

  getClassSelectedActive() {
    let ret = false;

    if (this.selectList) {
      this.selectList.forEach((selectL) => {
        if (selectL.selected) {
          ret = true;
        }
      });
    }

    return ret;
  }

  save() {
    const arrayDay = [];
    let day = {};

    this.selectedDays = this.selectList.filter((selectL) => {
      if (selectL.selected) {
        day = selectL.dateComplete;
        arrayDay.push({ day });
        return selectL;
      }
    });

    this.attendanceService.getCandidatesIberdrola(arrayDay).subscribe(
      (res) => {
        if (res) {
          res.forEach((candidates) => {
            if (candidates && candidates.parking) {
              candidates.parking.size = this.lower.transform(
                candidates.parking.size
              );
            }
            this.selectedDays.forEach((selectedDay) => {
              if (candidates.day === selectedDay.dateComplete) {
                selectedDay.info = candidates;
                const turns = [];
                if (
                  candidates.availableTurns &&
                  candidates.availableTurns.length > 0
                ) {
                  candidates.availableTurns.forEach((element) => {
                    const dateFrom = moment(element.shiftFrom).format('HH:mm');
                    const dateTo = moment(element.shiftTo).format('HH:mm');
                    turns.push({
                      id:
                        moment(element.shiftFrom).format('HHmm') +
                        '' +
                        moment(element.shiftTo).format('HHmm'),
                      shiftFrom: element.shiftFrom,
                      shiftTo: element.shiftTo,
                      shiftFromAux: dateFrom,
                      shiftToAux: dateTo,
                    });
                  });
                  candidates.availableTurns = turns;
                }
              }
            });
          });
          if (this.selectedDays.length > 0) {
            this.modeAssistance = 'ASSISTANCE';
            this.openModalEditAssistance();
            this.assistanceConfirm = false;
          }
        }
      },
      (error) => {
        if (error.code === STATUS_POSITION_OCCUPIED) {
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform('assistance_text_reserve_error')
          );
          this.closeModalEditAssistance();
        }
      }
    );
  }

  getAlertAssist() {
    let res = false;
    if (this.selectList) {
      this.selectList.forEach((element) => {
        if (element.alertAssist) {
          res = true;
        }
      });
    }

    return res;
  }
}
