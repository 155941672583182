<div class="new-reservation">
  <form
    class="row mui-form"
    (ngSubmit)="checkReservations()"
    [formGroup]="reservationForm"
    novalidate
  >
    <div class="form-group col-sm-6 mui-textfield">
      <label for="fecha">{{'date' | messagesPipe}}</label>
      <datepicker
        class="custom-datepicker"
        #datePickerElement
        id="fecha"
        [options]="datepickerOptions"
        (dateChanged)="onDateChanged($event)"
      ></datepicker>
      <span class="required" [hidden]="reservationDate"
        >{{'error_required' | messagesPipe}}</span
      >
    </div>
    <div class="form-group select-group col-sm-6 from">
      <div class="mui-select">
        <label for="hourFrom">{{'from' | messagesPipe}}</label>
        <select
          #selectHourFrom
          class="form-control"
          id="hourFrom"
          name="hourFrom"
          (change)="onChangeHourFrom($event)"
        >
          <option *ngFor="let hourFrom of hoursFrom" [value]="hourFrom">
            {{hourFrom}}
          </option>
        </select>
      </div>
      <span>:</span>
      <div class="mui-select">
        <select
          #selectMinuteFrom
          class="form-control"
          id="minuteFrom"
          name="minuteFrom"
          (change)="onChangeMinuteFrom($event)"
        >
          <option *ngFor="let minuteFrom of minutesFrom" [value]="minuteFrom">
            {{minuteFrom}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group select-group col-sm-6">
      <div class="mui-select">
        <label for="duration">{{'duration' | messagesPipe}}</label>
        <select
          #selectDuration
          class="form-control"
          id="duration"
          name="duration"
          (change)="onChangeDuration($event)"
        >
          <option *ngFor="let minute of duration" [value]="minute?.time">
            {{minute?.time}}
          </option>
        </select>
      </div>
    </div>

    <div class="last-textfield form-group col-sm-12 mui-textfield">
      <label for="reason">{{'meeting_reason' | messagesPipe}}</label>
      <textarea
        placeholder="{{'reservation_reason_placeholder' | messagesPipe}}"
        id="reason"
        name="reason"
        class="form-control"
        rows="3"
        [formControl]="reservationForm.controls['reason']"
      ></textarea>
      <span class="required" [hidden]="reservationForm.controls.reason.valid"
        >{{'error_required' | messagesPipe}}</span
      >
    </div>
    <div class="col-md-12" id="periodicity">
      <label>{{'periodicity' | messagesPipe}}</label>
      <div class="periodicity-type mui-select">
        <select
          #selectReservationTypes
          class="form-control"
          id="reservationTypes"
          name="reservationTypes"
          (change)="onChangeReservationType($event)"
        >
          <option
            *ngFor="let reservationType of reservationTypes"
            [value]="reservationType.id"
          >
            {{reservationType.name}}
          </option>
        </select>
      </div>
      <div
        class="periodicity-max-date"
        [hidden]="reservationTypeSelected.id == defaultReservationTypeSelected"
      >
        <label for="fecha">{{'periodicity_max_date' | messagesPipe}}</label>
        <datepicker
          class="custom-datepicker"
          #datePickerMaxDate
          id="fechaMax"
          [options]="datepickerOptions"
          (dateChanged)="onDateMaxPeriodicity($event)"
        ></datepicker>
        <span class="required" [hidden]="reservationMaxDate"
          >{{'error_required' | messagesPipe}}</span
        >
      </div>
    </div>
    <button
      type="submit"
      class="check-availability"
      [disabled]="!reservationForm.valid || !reservationDate || (reservationTypeSelected.id != defaultReservationTypeSelected && !reservationMaxDate)"
    >
      {{'btn_view_rooms' | messagesPipe}}
    </button>
  </form>
</div>
