import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MyDatePicker } from 'src/app/shared/components/date_picker/datepicker.component';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Utils } from 'src/app/shared/utils/utils';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';

@Component({
  selector: 'app-search-user-position',
  templateUrl: './search-user-position.component.html',
  styleUrls: ['./search-user-position.component.scss'],
})
export class SearchUserPositionComponent implements OnInit, AfterViewInit {
  searchUserPositionForm: FormGroup;

  messagesPipe = new MessagesPipe();

  @ViewChild('datePickerElement', null) datePickerElement: MyDatePicker;
  @ViewChild('defaultOpen', null) defaultTab: ElementRef;

  searchType = 'basic';
  selectedPeople: any[] = [];

  datepickerOptions: any = {};
  date: Date;
  locale: string;

  user: UserLdapWs;

  disabledSearchPeople = false;
  label = 'reservation_search_user';
  placeHolder = 'search_user';

  url: string;

  constructor(
    private formBuilder: FormBuilder,
    private utils: Utils,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.user = this.authenticationService.getCurrentUser();
    this.createForm();
    this.url = this.route.snapshot['_routerState'].url as string;
    this.locale = localStorage.getItem('language_app')
      ? localStorage.getItem('language_app')
      : navigator.language;
    this.datepickerOptions = this.utils.getDatepickerOptions(
      this.authenticationService.getMaxHotDeskAdvanceDays()
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.utils.setDatePickerDate(new Date(), this.datePickerElement);
    }, 0);
  }

  createForm() {
    this.searchUserPositionForm = this.formBuilder.group({});
  }

  isFormValid(): boolean {
    return (
      this.searchUserPositionForm.valid &&
      this.date != null &&
      this.selectedPeople.length === 1
    );
  }

  getSelectedPeople($event): void {
    this.selectedPeople = $event;
    if (this.selectedPeople.length > 0) {
      this.disabledSearchPeople = true;
    } else {
      this.disabledSearchPeople = false;
    }
  }

  onDateChanged($event) {
    this.date = $event.jsdate;
  }

  seePositions(): void {
    const userId = this.selectedPeople[0].id;
    const date = this.date;
    this.router.navigate([
      'menu',
      this.url.includes('/dinning-position')
        ? 'dinning-position'
        : 'job-position',
      'user-positions',
      date.getTime(),
      userId,
    ]);
  }
}
