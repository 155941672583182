<div class="roomDetail differentBuilding" [@slideDetailsBooking]="getRoomDetailAnimationOpacity()"
     >
  <div class="roomDetailCard" [@slideDetailsBooking]="getRoomDetailAnimation()" >
    <div class="image" [ngClass]="{'bloqued': !roomDetail.enabled}">
      <div class="image-wrapper">
        <button type="button" (click)="closeDetail()"><i class="vdp-close-lateral-foto"></i></button>
        <img [src]="roomDetail.imageUrl" *ngIf="roomDetail.imageUrl.length > 0" />
      </div>
    </div>
    <div class="image-info">
      <div class="name">
        <span>Sala</span>
        <span class="meeting-room">{{roomDetail.name}}</span>
        <span class="code" *ngIf="roomDetail.conferenceCode != null">{{'room_conference_code_title' | messagesPipe}} {{roomDetail.conferenceCode}}</span>
        <span class="building">{{buildingName}}</span>
        <div class="assistants">
          <i class="vdp-label-asistentes"></i><span>{{getAssistants()}}</span>
        </div>
      </div>
      <i *ngIf="roomDetail.hasIncidence" class="vdp-salas-incidencia"></i>
    </div>
    <div class="info">
      <div class="equipment clearfix">
        <span class="title">{{'equipment' | messagesPipe}}</span>
        <div class="col-xs-3" *ngFor="let equipment of roomDetail.equipment">
          <div class="equipment-item">
            <img [src]="equipment.imageUrl" height="50px" width="50px" />
            <span>{{equipment.name}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="calendar">
      <ul>
        <li *ngFor="let hour of hours">{{hour}}</li>
      </ul>
      <div class="busy" *ngFor="let reservation of roomDetail.reservations" [ngClass]="getClassScheduleDiv(reservation.dateFrom, reservation.dateTo)">
        <div class="message">
          <span *ngIf="reservation.userTo != null">{{'room_busy_user' | messagesPipe: [reservation.userTo.userName]}}</span>
          <span *ngIf="reservation.userTo == null">{{'room_busy' | messagesPipe}}</span>
        </div>
      </div>
      <div [ngClass]="getClassScheduleDiv(dateFrom, dateTo)">
        <i class="controls up left" (click)="addMinimunHour(dateFrom)" *ngIf="canChangeDateFrom()"></i>
        <i class="controls up right" (click)="addMaximunHour(dateFrom)" *ngIf="canChangeDates(dateFrom, dateTo)"></i>
        <i class="controls down right" (click)="addMinimunHour(dateTo)" *ngIf="canChangeDates(dateFrom, dateTo)"></i>
        <i class="controls down left" (click)="addMaximunHour(dateTo)" *ngIf="canChangeDateTo()"></i>

        <div class="message">
          <span>{{'room_available' | messagesPipe}}</span>
          <button class="btn" (click)="reserveRoom()" [disabled]="reservationBtnDisabled || !roomDetail.enabled">{{'room_available_btn' | messagesPipe}}</button>
        </div>
      </div>
      <div class="card" [ngClass]="getClassLaunchTime()"><i class="vdp-salas-lunch"></i></div>
    </div>
  </div>
</div>
