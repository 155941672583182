import { DetailWs } from './detail.ws';

export class RoomDetailWs extends DetailWs {
  private date: Date;

  constructor(id: number, date: Date) {
    super(id);
    this.date = date;
  }
}
