<div [ngClass]="showForm ? 'edit-assistance' : ''">
  <div class="right-drawer" [@slideDetailsBooking]="getAnimations()">
    <!-- Close button -->
    <button
      type="button"
      class="vdp-icon-cancelar"
      (click)="closeForm()"
    ></button>

    <span>
      {{
        mode === 'ASSISTANCE-USER'
          ? ('menu_assistance_user' | messagesPipe)
          : assistanceConfirm
          ? ('assistance_text_confirm' | messagesPipe)
          : ('assistance_text_not_confirm' | messagesPipe)
      }}</span
    >

    <div *ngIf="mode === 'ASSISTANCE'">
      <!-- ASISTANCE CONFIRM -->
      <div *ngIf="assistanceConfirm" class="assistance">
        <div>
          <div class="row">
            <span>{{ assistanceSelected.date }}</span>
          </div>
          <div class="row detailAssistance">
            <div
              class="col-sm-12 iconAssistance"
              [ngClass]="activeIconParking()"
            >
              <i class="vdp-parking_big"></i>
            </div>
            <div class="col-sm-12">
              <label>{{
                assistanceInfo?.parking?.id && assistanceInfo?.parking?.size
                  ? ('assistance_text_assign_parking'
                    | messagesPipe: [assistanceInfo?.parking?.size])
                  : !assistanceInfo?.parking?.id &&
                    assistanceInfo?.parking?.size
                  ? ('assistance_text_not_assign_vehicle_parking'
                    | messagesPipe: [assistanceInfo?.parking?.size])
                  : assistanceInfo?.parking?.id &&
                    !assistanceInfo?.parking?.size
                  ? ('assistance_text_assign_parking_space' | messagesPipe)
                  : !assistanceInfo?.parking?.id &&
                    !assistanceInfo?.parking?.size
                  ? ('assistance_text_not_assign_parking'
                    | messagesPipe: [assistanceInfo?.parking?.size])
                  : ''
              }}</label>
            </div>
            <div class="col-sm-12">
              <!-- Button -->
              <div
                class="placed-button"
                *ngIf="
                  (assistanceInfo?.parking.code &&
                    !assistanceSelected.disabledWhitAssistance) ||
                  (!assistanceInfo?.parking?.id &&
                    !assistanceInfo?.parking?.size)
                "
              >
                <button
                  (click)="liberateOrSolictParking(assistanceInfo?.parking.id)"
                  class="check-availability"
                  [disabled]="
                    buttonDisabledRequestParking ||
                    (!assistanceInfo?.parking?.id &&
                      !assistanceInfo?.parking?.size)
                  "
                >
                  {{
                    assistanceInfo?.parking.id
                      ? ('assistance_bottom_cancel' | messagesPipe)
                      : ('assistance_bottom_solict' | messagesPipe)
                  }}
                </button>
                <br />
                <span
                  *ngIf="
                    !assistanceInfo?.parking?.id &&
                    !assistanceInfo?.parking?.size
                  "
                  >{{
                    'assistance_text_not_assign_parking_text_alert'
                      | messagesPipe
                  }}</span
                >
              </div>
            </div>
          </div>
          <hr />
          <div class="row detailAssistance">
            <div
              class="col-sm-12 iconAssistance"
              [ngClass]="activeIconDinner()"
            >
              <i class="vdp-comedor_big"></i>
            </div>
            <div class="col-sm-12">
              <label
                >{{
                  assistanceSelected.indexWeek === 5
                    ? ('assistance_text_not_dining_room_service' | messagesPipe)
                    : assistanceInfo?.dinner?.id &&
                      assistanceInfo?.dinner?.availableDesk?.id
                    ? ('assistance_text_assign_dinner' | messagesPipe)
                    : ('assistance_text_not_assign_dinner' | messagesPipe)
                }}<span
                  *ngIf="
                    assistanceSelected.indexWeek != 5 &&
                    assistanceInfo?.dinner?.id &&
                    assistanceInfo?.dinner?.availableDesk?.id
                  "
                  >{{ assistanceInfo?.dinner.inningFromHourString }}:{{
                    assistanceInfo?.dinner.inningFromMinuteString
                  }}</span
                ></label
              >
            </div>
            <div
              class="col-sm-6"
              *ngIf="
                assistanceSelected.indexWeek != 5 &&
                !assistanceInfo?.dinner?.id &&
                !assistanceInfo?.dinner?.availableDesk &&
                assistanceInfo?.dinner?.availableTurns.length > 0 &&
                !assistanceSelected.disabledWhitAssistance
              "
            >
              <form
                class="row mui-form"
                [formGroup]="assistanceForm"
                novalidate
              >
                <!-- Hours -->
                <div class="select-by form-group select-group">
                  <div class="mui-select">
                    <label for="turn">{{
                      'assistance_text_available_shifts' | messagesPipe
                    }}</label>
                    <select
                      id="turn"
                      name="turn"
                      class="form-control"
                      formControlName="turn"
                      (change)="changeTurn($event.target.value)"
                    >
                      <option
                        [value]="0"
                        [selected]="
                          checkShiftPriority(
                            assistanceInfo.dinner.availableTurns
                          )
                        "
                      >
                        {{ 'select' | messagesPipe }}
                      </option>
                      <option
                        *ngFor="
                          let turn of assistanceInfo.dinner.availableTurns
                        "
                        [value]="turn.id"
                        [selected]="
                          assistanceForm.controls['turn'].value === turn.id
                        "
                      >
                        {{ turn.shiftFromAux }} - {{ turn.shiftToAux }}
                      </option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
            <div
              *ngIf="
                assistanceSelected.indexWeek != 5 &&
                !assistanceInfo?.dinner?.id &&
                !assistanceInfo?.dinner?.availableDesk &&
                assistanceInfo?.dinner?.availableTurns.length > 0 &&
                !assistanceSelected.disabledWhitAssistance
              "
            >
              <div class="col-sm-12">
                <div class="placed-button">
                  <button
                    (click)="solictTurn()"
                    class="check-availability"
                    [disabled]="buttonDisabledRequestDinner || !turnOK"
                  >
                    {{ 'assistance_bottom_solict' | messagesPipe }}
                  </button>
                </div>
              </div>
            </div>
            <div
              class="col-sm-12"
              *ngIf="
                assistanceSelected.indexWeek != 5 &&
                assistanceInfo?.dinner?.id &&
                assistanceInfo?.dinner?.availableDesk?.id &&
                !assistanceSelected.disabledWhitAssistance
              "
            >
              <div class="placed-button">
                <button (click)="cancelTurn()" class="check-availability">
                  {{ 'assistance_bottom_cancel' | messagesPipe }}
                </button>
              </div>
            </div>
            <div
              class="col-sm-12"
              *ngIf="!assistanceSelected.disabledWhitAssistance"
            >
              <div class="cancelAssistence">
                <a (click)="cancelAssistance()">
                  <i class="vdp-user-x">
                    {{
                      'assistance_bottom_cancel_assistance' | messagesPipe
                    }}</i
                  >
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ASSISTANCE NO CONFIRM -->
      <div *ngIf="!assistanceConfirm" class="assistance">
        <div *ngFor="let assistance of assistancesList; let index = index">
          <div class="row">
            <span>{{ assistance.date }}</span>
          </div>
          <div class="row detailAssistance">
            <div
              class="col-sm-2"
              [ngClass]="activeIconParkingNoConf(assistance)"
            >
              <i class="vdp-parking_big"></i>
            </div>
            <div class="col-sm-10 text">
              <label>{{
                !assistance?.info?.parking?.hasVehicle
                  ? ('assistance_text_not_vehicle_assign' | messagesPipe)
                  : !assistance?.info?.parking?.hasRequestParking &&
                    assistance?.info?.parking?.hasVehicle
                  ? ('assistance_text_not_request_parking' | messagesPipe)
                  : assistance?.info?.parking?.id ||
                    assistance?.info?.parking?.code
                  ? ('assistance_text_assign_parking'
                    | messagesPipe: [assistance?.info?.parking?.size])
                  : ('assistance_text_not_assign_parking_space' | messagesPipe)
              }}</label>
            </div>
          </div>
          <div class="row detailAssistance">
            <div
              class="col-sm-2"
              [ngClass]="activeIconDinnerNoConf(assistance)"
            >
              <i class="vdp-comedor_big"></i>
            </div>
            <div class="col-sm-10 text">
              <label
                >{{
                  assistance.indexWeek === 5
                    ? ('assistance_text_not_dining_room_service' | messagesPipe)
                    : !assistance.info.hasRequestDinnerDesk
                    ? ('assistance_text_not_request_dinner_space'
                      | messagesPipe)
                    : !assistance.info.availableDesk ||
                      !assistance.info.availableDesk.id
                    ? ('assistance_text_not_assign_dinner_space' | messagesPipe)
                    : ('assistance_text_assign_dinner_space' | messagesPipe)
                }}<span
                  *ngIf="
                    assistance.indexWeek != 5 &&
                    assistance.info.hasRequestDinnerDesk &&
                    assistance.info.availableDesk &&
                    assistance.info.availableDesk.id
                  "
                  >{{ dinnerShift }}</span
                ></label
              >
            </div>
            <div
              class="col-sm-12"
              *ngIf="
                assistance?.info?.availableTurns?.length > 0 &&
                !assistance?.info?.availableDesk?.id
              "
            >
              <form
                class="row mui-form"
                [formGroup]="assistanceForm"
                novalidate
              >
                <!-- Hours -->
                <div class="select-by form-group select-group">
                  <div class="mui-select">
                    <label for="turn">{{
                      'assistance_text_available_shifts' | messagesPipe
                    }}</label>
                    <select
                      id="turn"
                      name="turn"
                      class="form-control"
                      formControlName="turn"
                      (change)="changeTurn($event.target.value)"
                    >
                      <option
                        [value]="0"
                        [selected]="
                          checkShiftPriority(assistance.info.availableTurns)
                        "
                      >
                        {{ 'select' | messagesPipe }}
                      </option>
                      <option
                        *ngFor="let turn of assistance.info.availableTurns"
                        [value]="turn.id"
                        [selected]="
                          assistanceForm.controls['turn'].value === turn.id
                        "
                      >
                        {{ turn.shiftFromAux }} - {{ turn.shiftToAux }}
                      </option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <hr *ngIf="index + 1 != assistancesList.length" />
        </div>
        <div class="btn-flotante">
          <a (click)="closeForm()">
            {{ 'cancel_reservation_btn' | messagesPipe }}
          </a>
          <a
            (click)="confirmAssistance()"
            class="reserve"
            [ngClass]="[
              buttonDisabledConfirm ? 'a-not-active' : getActiveConfirm()
            ]"
          >
            {{ 'assistance_text_not_confirm' | messagesPipe }}
          </a>
        </div>
      </div>
    </div>
    <!-- ASSISTANCE USER -->
    <div *ngIf="mode === 'ASSISTANCE-USER'">
      <div class="assistance-user">
        <div class="row">
          <div class="col-sm-10 col-9">
            <span>{{ assistanceSelected.date }}</span>
          </div>
          <div class="col-sm-1 col-1 spanUser">
            <span>{{ assistanceSelected.numberAssist }}</span>
          </div>
          <div class="col-sm-1 col-1 iconUser">
            <i class="vdp-buscar-usuario"></i>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-sm-12" *ngFor="let user of listUsersForDay">
            <p>{{ user.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
