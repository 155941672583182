<div *ngIf="availableRooms.length > 0">
    <div class="main-container-tab">
        <div class="fadeInLayer">
            <ul class="tabBuildings">
                <li *ngFor="let roomListRes of availableRooms" (click)="changeBuilding(roomListRes)" [ngClass]="{'selected':roomListRes == roomListResponseSelect}"><span>{{roomListRes.buildingName}}</span></li>
            </ul>
            <div class="listRooms row">
                <div class="col-xs-12 col-sm-6 col-md-4" *ngFor="let room of roomListResponseSelect.rooms">
                    <roomCard [room]="room" [reservationBtnDisabled]="reservationBtnDisabled" (roomReserveEmitter)="showModalReservationCard($event)" (roomDetailEmitter)="showRoomDetail($event)"></roomCard>
                </div>
            </div>
        </div>
        <div>
            <roomWithoutTimeDetail [roomId]="roomDetail.id" [reservationDate]="reservationDateDetail" [showCloseDetail]="true" (showModalEmitter)="showModalReservation($event)" (closeDetailEmitter)="closeRoomDetail()" ></roomWithoutTimeDetail>
        </div>
        <div>
            <reservationDialog [reservation]="modalReservation" [hidden]="showModal == false" (cancelEmitter)="closeDialog()" (saveEmitter)="saveReservation($event)"></reservationDialog>
        </div>
    </div>
    <div *ngIf="showServerLunchMsg" class="modal fade" role="dialog">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-info">
                <span class="message">{{reservationResponse}}</span>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-default" (click)="backToReservationList()">{{'ok' | messagesPipe}}</button>
              </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="showServerLunchMsg" class="modal fade" role="dialog">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-info">
                    <span class="message">{{reservationResponse}}</span>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" (click)="backToReservationList()">{{'ok' | messagesPipe}}</button>
                </div>
            </div>
        </div>
    </div> -->
</div>
