import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalReservationGenericService } from '../../../shared/components/modal-reservation-generic/modal-reservation-generic.service';
import { CarWashService } from '../../../shared/services/car-wash.service';

@Component({
  selector: 'app-car-wash-reserve-mobile',
  templateUrl: './car-wash-reserve-mobile.component.html',
  styleUrls: ['./car-wash-reserve-mobile.component.scss'],
})
export class CarWashReserveMobileComponent implements OnInit {
  license: string;
  date: number;
  openingTime: number;
  endingTime: number;
  idEdit: number;
  carwashId: number;
  carwashSizeId: number;
  timeInterval: number;
  token: string;
  language: string;

  constructor(
    private carWashService: CarWashService,
    private modalReservationGenericService: ModalReservationGenericService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      if (
        param?.carwashSizeId &&
        param?.license &&
        param?.date &&
        param?.openingTime &&
        param?.endingTime &&
        param?.token
      ) {
        this.license = param.license;
        this.carwashSizeId = Number(param.carwashSizeId);
        this.date = Number(param.date);
        this.openingTime = Number(param.openingTime);
        this.endingTime = Number(param.endingTime);
        this.token = param.token;
        this.language = param.language;

        if (param?.carwashId) {
          this.carwashId = Number(param.carwashId);

          this.getCarWashReservation(this.date, this.carwashId);
        } else {
          this.timeInterval = Number(param.timeInterval);
          this.openModalReservation(
            'reserve_carwash_title',
            this.date,
            this.openingTime,
            this.endingTime,
            this.timeInterval,
            [],
            true,
            null,
            false
          );
        }
      }
    });
  }

  getCarWashReservation(date, carwashId) {
    this.carWashService
      .getCarWashReservation(date, carwashId, this.token)
      .subscribe(
        (res) => {
          this.openModalReservation(
            'reserve_carwash_edit_title',
            date,
            this.openingTime,
            this.endingTime,
            res.carwashReservationConfigureTimeInterval,
            res.reservations,
            true,
            this.idEdit,
            true
          );
        },
        (error) => {
          if (error && error.code) {
            this.dipastchEventError(error.code);
          }
        }
      );
  }

  openModalReservation(
    titleKey: string,
    date: number,
    openingTime: number,
    endingTime: number,
    timeInterval: number,
    listOccupation: any,
    fullPage: boolean,
    idEdit: number,
    edit: boolean
  ) {
    setTimeout(() => {
      this.modalReservationGenericService.openModal(
        date,
        openingTime,
        endingTime,
        timeInterval,
        listOccupation,
        fullPage,
        idEdit,
        edit
      );
      this.modalReservationGenericService.setLanguage(this.language, titleKey);
    }, 300);
  }

  addCarwashReservation(data: any) {
    this.carWashService
      .addCarwashReservation(
        data.dateFrom,
        data.dateTo,
        this.carwashSizeId,
        this.license,
        this.token
      )
      .subscribe(
        (res) => {
          if (res) {
            this.dipastchEventReservation(true);
          } else {
            this.dipastchEventError(204);
          }
        },
        (error) => {
          if (error && error.code) {
            this.dipastchEventError(error.code);
          }
        }
      );
  }

  editCarwashReservation(data: any) {
    this.carWashService
      .editCarwashReservation(
        data.dateFrom,
        data.dateTo,
        this.idEdit,
        this.token
      )
      .subscribe(
        (res) => {
          if (res) {
            this.dipastchEventReservation(true);
          } else {
            this.dipastchEventError(204);
          }
        },
        (error) => {
          if (error && error.code) {
            this.dipastchEventError(error.code);
          }
        }
      );
  }

  // METODO PARA AVISAR A MOVIL DE QUE SE HA REALIZADO LA RESERVA
  dipastchEventReservation(status: boolean) {
    var ua = navigator.userAgent;
    var checker = {
      iphone: ua.match(/(iPhone|iPod|iPad)/),
      android: ua.match(/Android/),
    };
    if (checker.android) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
      deskReserve.statusReserve(${status});
      `);
      x.appendChild(t);
      document.body.appendChild(x);
    } else if (checker.iphone) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
      window.webkit.messageHandlers.statusReserve.postMessage(${status});
      `);
      x.appendChild(t);
      document.body.appendChild(x);
    }
  }

  // METODO PARA AVISAR A MOVIL DE QUE SE HA PRODUCIDO UN ERROR
  dipastchEventError(codeError: number) {
    var ua = navigator.userAgent;
    var checker = {
      iphone: ua.match(/(iPhone|iPod|iPad)/),
      android: ua.match(/Android/),
    };
    if (checker.android) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
      deskReserve.errorReserve(${codeError});
      `);
      x.appendChild(t);
      document.body.appendChild(x);
    } else if (checker.iphone) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
      window.webkit.messageHandlers.errorReserve.postMessage(${codeError});
      `);
      x.appendChild(t);
      document.body.appendChild(x);
    }
  }
}
