<div [ngClass]="{'listViewClass': !viewCard}" class="waiting-list-card clearfix">
  <div class="info">
    <span class="reason">{{waiting.reason}}</span>
    <span class="date">{{'waiting_msg' | messagesPipe:[waiting.dateFrom | dateStringPipe: 'DD/MM', waiting.dateFrom | dateStringPipe: 'HH:mm', waiting.dateTo | dateStringPipe: 'HH:mm']}}</span>
    <span class="assistans" *ngIf="waiting.assistants != null"><i class="vdp-label-asistentes" ></i><span>{{waiting.assistants}}</span></span>
    <div  class="equipment clearfix" *ngIf="waiting.equipment != null && waiting.equipment.length > 0">
      <div *ngFor="let eq of waiting.equipment ">
        <div class="equipment-item">
          <img [src]="eq.imageUrl" height="50px" width="50px" />
        </div>
      </div>
    </div>
  </div>
  <div class="cancel">
    <button (click)="cancelWaiting()">{{'cancel_reservation_btn' | messagesPipe}} <i class="vdp-icon-cancelar"></i></button>
  </div>
</div>
