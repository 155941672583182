import { FormControl } from '@angular/forms';
import { Injectable } from "@angular/core";

interface typeValidationResult {
 [ typeValidator : string ] : boolean;
}

@Injectable()
export class TypeValidator {
  static typeValidator( control : FormControl ): typeValidationResult {
    const input = control.value;
    if(input != undefined && input != null && input != 0) {
      return null;
    } else {
      return { 'typeValidator' : true };
    }
  };

}