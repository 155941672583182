<div class="parking-occupation">
  <div class="map">
    <div class="scroll">
      <div class="table">
        <div class="row"></div>
        <div class="row">
          <div class="col sub-title">
            <span>{{ plazasLibres }}</span>
          </div>
        </div>
        <div class="row cells">
          <div class="col-3 cell"></div>
          <div
            class="col-4 cell cellColor"
            [style.border-top-left-radius]="'5px'"
            [style.border-top-right-radius]="'5px'"
          >
            <span>{{ edificioA }}</span>
          </div>
          <div
            class="col-4 cell cellColor"
            [style.border-top-right-radius]="'5px'"
            [style.border-top-left-radius]="'5px'"
          >
            <span>{{ edificioB }}</span>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row cells">
          <div class="col-3 cell">
            <i class="vdp-coche_normal"></i>
          </div>
          <div class="col-4 cell cellColor">
            <span>{{ nNormalBuildingA }}</span>
          </div>
          <div class="col-4 cell cellColor">
            <span>{{ nNormalBuildingB }}</span>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row cells cellLine">
          <div class="col-3 cell">
            <i class="vdp-coche_electrico"></i>
          </div>
          <div
            class="col-4 cell cellColor"
            [style.border-bottom-left-radius]="'5px'"
            [style.border-bottom-right-radius]="'5px'"
          >
            <span>{{ nElectricBuildingA }}</span>
          </div>
          <div
            class="col-4 cell cellColor"
            [style.border-bottom-right-radius]="'5px'"
            [style.border-bottom-left-radius]="'5px'"
          >
            <span>{{ nElectricBuildingB }}</span>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row">
          <div class="col sub-title" [style.margin-top]="'35px'">
            <span>{{ perfilHorario }}</span>
          </div>
        </div>
        <div class="row">
          <!-- Tabs -->
          <!-- <div class="tab"> -->
          <div class="col-6">
            <div class="tab">
              <button
                #defaultOpen
                class="tablinks"
                (click)="manageTabs($event, 1)"
              >
                {{ edificioA }}
              </button>
            </div>
          </div>
          <div class="col-6">
            <div class="tab">
              <button
                #defaultOpen
                class="tablinks"
                (click)="manageTabs($event, 2)"
              >
                {{ edificioB }}
              </button>
            </div>
          </div>
          <!-- </div> -->
        </div>
        <div class="row">
          <div>
            <div>
              <canvas
                baseChart
                width="350"
                height="350"
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="lineChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
              >
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
