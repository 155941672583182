<div
  [ngClass]="[
    getClassSelected(day),
    day.festive
      ? 'dayCalendarFestive'
      : day.disabled
      ? 'dayCalendarDisabled'
      : 'dayCalendar'
  ]"
  [style.background-color]="day.color"
  [style.border-width]="day.currentDay ? 'thick' : ''"
  [style.border-style]="day.currentDay ? 'solid' : ''"
  [style.border-color]="day.currentDay ? day.borderColor : ''"
>
  <div class="contentIcon">
    <span [style.color]="day.textColor">{{ day?.value }}</span>
    <div class="assistanceUserClass" *ngIf="day.assignments?.day">
      <div class="row">
        <div class="col columStyle">
          <i
            [style.visibility]="!day.alertAssist ? 'hidden' : 'visible'"
            class="float-right vdp-salas-incidencia"
          ></i>
        </div>
      </div>
      <div class="row">
        <div class="col columStyle">
          <span class="numb float-left">{{ day?.numberAssist }}</span>
          <i class="float-right vdp-buscar-usuario"></i>
        </div>
      </div>
    </div>
  </div>
</div>
